const idForAggregated = "32767";
export const TagsFormatPrefix = {
  INVERTER_ERRORS: "Inverter:ErrorType:",
  WEATHER_ERRORS: "Weather:ErrorType:",
  POWER_METER_ERRORS: "PowerMeter:ErrorType:",
  TRACKERS_ERRORS: "TrackerStatus:ErrorType:",
  SITE_AC_CURRENT_POWER: "Inverter:AcPower:",
  SITE_DC_CURRENT_POWER: "Inverter:DcPower:",
  WIND: "Weather:WindSpeed:",
  WIND_DIR: "Weather:WindDirection:",
  GHI: "Weather:GlobalIrradiance:",
  TEMP: "Weather:AmbientAirTemp:",
  GTI: "Weather:PlaneOfArrayIrradiance:",
  UPS_EMERGENCY_STATUS: "McsState:UpsEmergencyStatus:",
  WEATHER_HUMIDITY: "Weather:RelativeHumidity:",
  BAROMETRIC_PRESSURE: "Weather:BarometricPressure:",
  TRACKERS_SHADING_RATIO: "Trackers:ShadingRatio:",
  FAUCET_OPENING_RATIO: "CleaningFaucetOpeningRatio:OpeningRatio:",
};

const TagsNames = {
  SITE_DAILY_AC_POWER: "Calculated:TotalDaily:AcPower",
  SITE_DAILY_DC_POWER: "Calculated:TotalDaily:DcPower",
  SITE_AC_CURRENT_POWER: "Inverter:AcPower:" + idForAggregated,
  SITE_DC_CURRENT_POWER: "Inverter:DcPower:" + idForAggregated,

  DAILY_SP_PRODUCTION: "Calculated:TotalDaily:Sp",
  DAILY_SP_EFFICIENCY: "Calculated:Current:PerformanceIndex",

  POWER_METER_TOTAL: "PowerMeter:ProductionTotal:1",
  POWER_METER_DELTA: "PowerMeter:ProductionDelta:1",

  MOMENT_POTENTIAL_POWER: "Calculated:PotentialPowerProduction",
  MOMENT_CONTRACT_REQUIRED_POWER: "Calculated:ContractPowerProduction",
  DAILY_GTI: "Calculated:TotalDaily:PlaneOfArrayIrradiance" /* No DailyPlaneOfArrayIrradiance for now*/,

  AGGR_INVERTERS_STATUS: "Inverter:AggrStatus",
  AGGR_WEATHER_STATUS: "Weather:AggrStatus",
  AGGR_TRACKERS_STATUS: "TrackerStatus:AggrStatus",
  AGGR_POWER_METER_STATUS: "PowerMeter:AggrStatus",

  INVERTER_ERROR_COUNT: "Inverter:ErrorType:ErrorCount",
  INVERTER_WARN_COUNT: "Inverter:ErrorType:WarnCount",
  WEATHER_ERROR_COUNT: "Weather:ErrorType:ErrorCount",
  WEATHER_WARN_COUNT: "Weather:ErrorType:WarnCount",

  WEATHER_HUMIDITY: TagsFormatPrefix.WEATHER_HUMIDITY + idForAggregated,
  BAROMETRIC_PRESSURE: TagsFormatPrefix.BAROMETRIC_PRESSURE + idForAggregated,
  WIND: TagsFormatPrefix.WIND + idForAggregated,
  WIND_DIR: TagsFormatPrefix.WIND_DIR + idForAggregated,
  GHI: TagsFormatPrefix.GHI + idForAggregated,
  TEMP: TagsFormatPrefix.TEMP + idForAggregated,
  GTI: TagsFormatPrefix.GTI + idForAggregated,
  DNI: "Weather:EstimatedDni:0",

  MCS_STATE: "McsState:State:0",
  UPS_EMERGENCY_STATUS: "McsState:UpsEmergencyStatus:0",
  MCS_FIELD_STATE: "Calculated:McsState:FieldState",
  MCS_SAFETY_REASON: "Calculated:McsState:SafetyReason",

  TRACKING_STATE: "TrackerStatus:CurrentState:1",
  TRACKERS_CURRENT_ELEVATION: "TrackerStatus:CurrentElevation:1",
  TRACKERS_RSSI: "TrackerStatus:Rssi:1",
  TRACKERS_TARGET_ELEVATION: "TrackerStatus:TargetElevation:1",

  TRACKERS_TRACKING_COUNT: "Trackers:StateCount:Tracking",
  TRACKERS_MANUAL_COUNT: "Trackers:StateCount:Manual",

  SOIL_TEMP: "Agri:SoilTemperature:32767",
  SOIL_HUMIDITY: "Agri:SoilHumidity:32767",
  SOIL_PH: "Agri:SoilPH:32767",
  SOIL_STEM_GROWTH: "Agri:StemGrowth:32767",
  SOIL_PHOTOSYN: "Agri:PlantPhotosyntheticallyActiveRadiationPpfd:32767",
  SOIL_RAIN_FALL: "Agri:RainFall:32767",
  SOIL_NITROGEN: "Agri:SoilNitrogen:32767",
  SOIL_PHOSPHORUS: "Agri:SoilPhosphorus:32767",
  SOIL_POTASSIUM: "Agri:SoilPotassium:32767",
};

export const gaugeTagNames = [
  TagsNames.DAILY_SP_EFFICIENCY,
  TagsNames.SITE_AC_CURRENT_POWER,
  TagsNames.WIND,
  TagsNames.MCS_FIELD_STATE,
  TagsNames.MCS_SAFETY_REASON,
  TagsNames.AGGR_INVERTERS_STATUS,
  TagsNames.AGGR_TRACKERS_STATUS,
  TagsNames.SITE_DC_CURRENT_POWER,
  TagsNames.SITE_DAILY_AC_POWER,
  TagsNames.DAILY_SP_PRODUCTION,
  TagsNames.MOMENT_POTENTIAL_POWER,
  TagsNames.GHI,
  TagsNames.TEMP,
  TagsNames.TRACKERS_TRACKING_COUNT,
  TagsNames.TRACKERS_MANUAL_COUNT,
  TagsNames.SOIL_STEM_GROWTH,
  TagsNames.SOIL_PHOTOSYN,
  TagsNames.BAROMETRIC_PRESSURE,
  TagsNames.SOIL_RAIN_FALL,
];

export default TagsNames;
