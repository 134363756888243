import {
  ISolarSightProvider,
  TrackerData,
  TrackerErrorType,
  TrackerStateEnum as PixelsTrackerState,
  WebUserAppLifeCycle,
  SimpleLogEvent,
} from "@solargik/solar-sight-webgl";
import { ITrackerStatus, TrackersStateEnum } from "../TrackersModels";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { AppDispatch } from "../../app/Store";
import { setSelectedTrackers } from "../../app/store/TrackersStore";

export class SolarSightProvider implements ISolarSightProvider {
  private readonly dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLog(simpleLogEntry: SimpleLogEvent): void {
    return;
  }

  getCanvas(): HTMLCanvasElement {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return document.querySelector<HTMLCanvasElement>("#solar-sight-canvas")!;
  }

  onSelectedTrackers = (trackerIds: number[]): void => {
    this.dispatch(setSelectedTrackers({ selectedTrackersIds: trackerIds }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSiteListChanged(sites: string[]): void {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSiteChanged(siteId: string): void {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAppPhaseChanged(phase: WebUserAppLifeCycle): void {
    return;
  }

  getToken = () => this.userToken;

  getServerUrl = (): string => APP_CONFIG.serviceUrls.pixelsUrlPrefix;

  isStandaloneApp = (): boolean => false;

  getTrackersData = () => {
    return this.trackersStatus.map((trackerStatus) => trackerStatusToTrackerDataMapper(trackerStatus));
  };

  public trackersStatus: ITrackerStatus[] = [];

  public userToken = "";
}

const trackerStateToPixelsTrackerStateMapper = (trackerState: TrackersStateEnum): PixelsTrackerState =>
  PixelsTrackerState[TrackersStateEnum[trackerState] as keyof typeof PixelsTrackerState];

const trackerStatusToTrackerDataMapper = (trackerStatus: ITrackerStatus): TrackerData =>
  new TrackerData(
    trackerStatus.id,
    trackerStateToPixelsTrackerStateMapper(trackerStatus.currentState ?? TrackersStateEnum.Undefined),
    trackerStatus.currentElevation ?? null,
    trackerStatus.rssi ?? null,
    new Date(trackerStatus.updateTime),
    TrackerErrorType.NoError, // TODO - in later versions
    trackerStateToPixelsTrackerStateMapper(trackerStatus.targetState ?? TrackersStateEnum.Undefined)
  );

let solarSightProviderInstance: SolarSightProvider | null = null;

export const getSolarSightProvider = (dispatch: AppDispatch) => {
  if (!solarSightProviderInstance) {
    solarSightProviderInstance = new SolarSightProvider(dispatch);
  }
  return solarSightProviderInstance;
};
