import getIcons from "../../SolarGikLib/icons/Icons";
import { ErrorIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { UrgencyLevel } from "./DTOs";

const SeverityHighIcon = getIcons(ErrorIcons.MultisiteHighSeverityError, IconCategory.Error);
const SeverityMildIcon = getIcons(ErrorIcons.MultisiteMildSeverityError, IconCategory.Error);
const SeverityLowIcon = getIcons(ErrorIcons.MultisiteLowSeverityError, IconCategory.Error);
const SeverityUndefinedIcon = getIcons(ErrorIcons.MultisiteUndefinedError, IconCategory.Error);

export const mapSverityToIcons = (value: UrgencyLevel) => {
  switch (value) {
    case UrgencyLevel.High:
      return SeverityHighIcon;
    case UrgencyLevel.Medium:
      return SeverityMildIcon;
    case UrgencyLevel.Low:
      return SeverityLowIcon;
    default:
      return SeverityUndefinedIcon;
  }
};

export const convertEnumToIconName = (enumValue: UrgencyLevel): string => {
  switch (enumValue) {
    case UrgencyLevel.High:
      return ErrorIcons.HighSeverityError;
    case UrgencyLevel.Medium:
      return ErrorIcons.MildSeverityError;
    case UrgencyLevel.Low:
      return ErrorIcons.LowSeverityError;
    default:
      return ErrorIcons.UndefinedSeverityError;
  }
};
