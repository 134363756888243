import axios from "axios";

import { IUserData } from "./UserStore";
import APP_CONFIG from "../app/configuration/AppConfig";

export async function getUserData(signal: AbortSignal): Promise<IUserData> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/User/getUserInfo`;
  const httpResponse = await axios.get<IUserData>(url, { signal });
  return httpResponse.data;
}
