import { FC, useState } from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";

import { IButtonVariant } from "../../../../common/Mui/StyledButton";
import { AlertMessage } from "../../../../SolarGikLib/alerts/AlertModels";
import getIcons from "../../../../SolarGikLib/icons/Icons";
import { FileCommandsIcons, IconCategory } from "../../../../SolarGikLib/icons/IconsModels";
import { downloadOverallPlan, downloadTemplate, downloadTodaysPlan } from "./AgriCommandsUtils";
import { UnknownError } from "../../../../common/UnknownError";

interface DownloadOverallPlanProps {
  setErrorFunc: (message?: AlertMessage) => void;
  siteId: string;
  buttonVariant: IButtonVariant;
  isPlanExist: boolean;
}

const DownloadPlan: FC<DownloadOverallPlanProps> = ({ setErrorFunc, siteId, isPlanExist }) => {
  const [agriTrackingSelect, setAgriTrackingSelect] = useState<null | HTMLElement>(null);

  const isPlanOpen = Boolean(agriTrackingSelect);

  const handleAgriTrackingPlanHovered = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAgriTrackingSelect(event.currentTarget);
  };

  const handlePlanClose = () => {
    setAgriTrackingSelect(null);
  };

  const handleUnsuccessfulRequest = (error: UnknownError) => {
    if (error.response && error.response?.status === 404) {
      setErrorFunc({
        text: "No Shading plan",
        severity: "error",
      });
    } else {
      setErrorFunc({ text: error.message, severity: "error" });
    }
  };

  const handleDownloadOverallPlan = async () => {
    setErrorFunc(undefined);
    handlePlanClose();
    try {
      await downloadOverallPlan(siteId);
    } catch (error: UnknownError) {
      handleUnsuccessfulRequest(error);
    }
  };

  const handleDownloadTodaysPlan = async () => {
    setErrorFunc(undefined);
    handlePlanClose();
    try {
      await downloadTodaysPlan(siteId);
    } catch (error: UnknownError) {
      handleUnsuccessfulRequest(error);
    }
  };

  const handleDownloadPlanTemplate = async () => {
    setErrorFunc(undefined);
    handlePlanClose();
    try {
      await downloadTemplate();
    } catch (error: UnknownError) {
      handleUnsuccessfulRequest(error);
    }
  };

  const DownloadIcon = getIcons(FileCommandsIcons.Download, IconCategory.FileCommands);
  const DownloadIconDisabled = getIcons(FileCommandsIcons.DownloadDisabled, IconCategory.FileCommands);
  return (
    <>
      <IconButton onMouseEnter={handleAgriTrackingPlanHovered} size="small">
        {!agriTrackingSelect ? <DownloadIcon /> : <DownloadIconDisabled />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={agriTrackingSelect}
        open={isPlanOpen}
        onClose={handlePlanClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div onMouseLeave={handlePlanClose}>
          {isPlanExist && <MenuItem onClick={handleDownloadOverallPlan}>Download Overall plan</MenuItem>}
          {isPlanExist && <MenuItem onClick={handleDownloadTodaysPlan}>Download Todays plan</MenuItem>}
          <MenuItem onClick={handleDownloadPlanTemplate}>Download Csv Template</MenuItem>
        </div>
      </Menu>
    </>
  );
};

export default DownloadPlan;
