import { FC, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import classes from "./MultiSiteSiteNameHeader.module.scss";
import useDebounce from "../../common/Hooks/useDebounce";

export interface IMultiSiteSiteNameHeaderProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isSearchMode: boolean;
  setIsSearchMode: (isSearchMode: boolean) => void;
}

const MultiSiteSiteNameHeader: FC<IMultiSiteSiteNameHeaderProps> = ({
  setSearchTerm: setParentSearchTerm,
  searchTerm: parentSearchTerm,
  isSearchMode,
  setIsSearchMode,
}) => {
  const [searchTerm, setSearchTerm] = useState(parentSearchTerm);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => setParentSearchTerm(searchTerm), [debouncedSearchTerm]);
  return (
    <div className={classes["container"]}>
      {!isSearchMode && (
        <>
          {"Name"}
          <GridSearchIcon
            className={classes["search-icon"]}
            onClick={(e) => {
              setIsSearchMode(true);
              e.stopPropagation();
            }}
          />
        </>
      )}
      {isSearchMode && (
        <TextField
          onClick={(e) => e.stopPropagation()}
          onChange={(e: { target: { value: string } }) => setSearchTerm(e.target.value)}
          autoFocus={isSearchMode}
          placeholder="Search..."
          sx={{ m: 1 }}
          InputProps={{
            classes: { root: classes["searchbox-input-root"], input: classes["searchbox-input"] },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ClearIcon
                  className={`${classes["searchbox-icon"]} ${classes["clear-icon"]}`}
                  onClick={() => {
                    setSearchTerm("");
                    setIsSearchMode(false);
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          variant="outlined"
          size="small"
          hiddenLabel
        />
      )}
    </div>
  );
};

export default MultiSiteSiteNameHeader;
