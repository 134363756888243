import { FC, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import classes from "./SafeOverrideTooltip.module.css";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchSafeOverride } from "../../app/store/SafeOverrideStore";

interface OverrideTooltipProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  siteId: string;
}
const intervalTimeInMilliseconds = 10000;

const OverrideTooltip: FC<OverrideTooltipProps> = ({ isOpen, setIsOpen, siteId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { inOverrideSafeMode, expiredAt: remainingTime } = useSelector(
    (state: RootState) => state.safeOverride
  );

  useEffect(() => {
    setIsOpen(inOverrideSafeMode);
  }, [inOverrideSafeMode]);

  useEffect(() => {
    const getOverrideSafeMode = async () => {
      dispatch(fetchSafeOverride(siteId));
    };
    getOverrideSafeMode();
    const intervalId = setInterval(getOverrideSafeMode, intervalTimeInMilliseconds);
    return () => {
      clearInterval(intervalId);
    };
  }, [siteId]);

  const content = `you are in override mode,
    and not safe in state of high wind or snow
    Ending by \n ${format(remainingTime, "yy-MM-dd HH:mm")}`;

  return (
    <>
      {inOverrideSafeMode && (
        <DarkTooltip
          open={isOpen}
          sx={{
            backgroundColor: "var(--error-color)",
            "& .MuiTooltip-arrow": {
              color: "var(--error-color)",
            },
          }}
          title={
            <>
              <IconButton
                onClick={() => setIsOpen(false)}
                sx={{ position: "absolute", right: "0", top: "0" }}
              >
                <Close sx={{ color: "var(--white)" }} />
              </IconButton>
              {content}
            </>
          }
        >
          <button className={classes["in-override"]} onClick={() => setIsOpen(true)} />
        </DarkTooltip>
      )}
    </>
  );
};

export default OverrideTooltip;
