import { CSSObject } from "@mui/material/styles";

export const emergencyPopoverButtonStyle = {
  border: 1,
  color: "white",
  p: "8px 20px 8px 20px",
  gap: "10px",
  backgroundColor: "#F17A7A",
  letteSpacing: "0.05em",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "#FFFFFF36",
  },
};

export const emergencyPopoverListButtonStyle: CSSObject = {
  ...emergencyPopoverButtonStyle,
  backgroundColor: "var(--blue-button-background-color)",
  "&:hover": {
    backgroundColor: "var(--blue-color-primary)",
  },
};

export const emergencyPopoverStyle = {
  "& .MuiPopover-paper": {
    backgroundColor: "#F17A7A",
    color: "white",
    borderRadius: "25px",
    fontFamily: "Poppins",
    letterSpacing: "0.05em",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
    textAlign: "center",
    gap: "24px",
    p: "5px 10px",
    maxWidth: "450px",
    position: "relative",
    marginTop: "10px",
    overflow: "visible",
    "@media screen and (max-width: 1600px)": {
      maxWidth: "400px",
    },
  },
};

export const emergencyPopoverReasonListStyle: CSSObject = {
  ...emergencyPopoverStyle,
  "& .MuiPopover-paper": {
    ...emergencyPopoverStyle["& .MuiPopover-paper"],
    backgroundColor: "white",
    color: "var(--blue-color-primary)",
  },
};

export const emergencyPopoverArrowStyle: CSSObject = {
  position: "absolute",
  width: 0,
  height: 0,
  borderLeft: "8px solid transparent",
  borderRight: "8px solid transparent",
  borderBottom: "8px solid #F17A7A",
  top: -8,
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1000,
};

export const emergencyToastButtonStyle = {
  "border-radius": "40px 0px 0px 0px",
  border: "1px 0px 0px 0px",
  color: "#1F3171",
  "background-color": "#FFFFFF",
  "text-alignment": "center",
  width: "123px",
  height: "31px",
  "letter-spacing": "0.05em",
  "font-size": "16px",
  "font-weight": "400",
  "line-height": "17px",
  "&:hover": {
    opacity: "0.7",
    "background-color": "#FFFFFF",
  },
};

export const emergencyToastStyle = {
  "& .MuiPaper-root": {
    width: "400px",
    backgroundColor: "#595959",
    borderRadius: "30px",
    justifyContent: "center",
    letterSpacing: "0.05em",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "23px",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  "& .MuiSnackbarContent-action ": {
    margin: "0px",
    borderRadius: "30px 0px 0px 0px",
  },
};
