import { FC, useState } from "react";

import classes from "./SiteAppConfigUploadPopup.module.css"; //ToDO change to local css
import SolarGikButton from "../../SolarGikLib/Button";
import SolarGikInput from "../../SolarGikLib/fields/Input";
import { filledBlueButton } from "../../SolarGikLib/styles/ButtonsStyle";
import Popup from "../html_elements/Popup";
interface ISiteAppUploadPopupProps {
  loadedFileName: React.MutableRefObject<string | undefined>;
  onSubmitHandler: (version: string, description: string) => Promise<void>;
  setShowDropZonePopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const SiteAppConfigUploadPopup: FC<ISiteAppUploadPopupProps> = ({
  loadedFileName,
  onSubmitHandler,
  setShowDropZonePopup,
}) => {
  const [version, setVersion] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  return (
    <Popup
      headerItems={"Upload Config Version: " + loadedFileName?.current || ""}
      content={
        <div className={classes.column}>
          <div className={classes.row}>
            <SolarGikInput
              label={"version"}
              value={version}
              onChange={(e: { target: { value: string } }) => setVersion(e.target.value)}
              type={"text"}
              placeholder={"version"}
            />
            <SolarGikInput
              label={"description"}
              value={description}
              onChange={(e: { target: { value: string } }) => setDescription(e.target.value)}
              type={"text"}
              placeholder={"description"}
            />
          </div>
          <SolarGikButton
            style={filledBlueButton}
            text={"Submit"}
            onClickFunc={() => onSubmitHandler(version, description)}
          />
        </div>
      }
      exitFunction={() => {
        setShowDropZonePopup(false);
      }}
    />
  );
};
export default SiteAppConfigUploadPopup;
