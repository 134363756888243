import React from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { pickerStyles } from "./pickerStyles";

interface StyledTimePickerProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  error?: boolean;
}

const SgTimePicker: React.FC<StyledTimePickerProps> = ({ label, value, onChange, error = false }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        ampm={false}
        format="HH:mm"
        label={label}
        value={dayjs(value)}
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.format("HH:mm:ss"));
          }
        }}
        slotProps={{
          textField: (params) => ({
            ...params,
            sx: pickerStyles,
            error: error,
          }),
        }}
      />
    </LocalizationProvider>
  );
};

export default SgTimePicker;
