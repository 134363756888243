import {} from "../../global"
import {ISolarSightProvider} from "./iSolarSightProvider";
import {WebUserAppLifeCycle} from "./solarSightModels";
import {iSolarSightStateProvider, UnityResponseDelegate, UnityResponse} from "./iSolarSightStateProvider";

const AppStateFuncName = "onAppPhaseChanged";
const ResponseFuncName = "response"

class ValidateApi
{
    //Casing according to C#
    constructor(public ApiMethodNames:string[],public  AppVersion:string){}
}

export class WrapperSolarSightProvider implements iSolarSightStateProvider {
    readonly #iSolarSightApi: ISolarSightProvider;
    readonly #isDebug: boolean;

    #appState: WebUserAppLifeCycle = WebUserAppLifeCycle.NotReady;

    #lastResponse:UnityResponse | null = null;
    #responseListeners: UnityResponseDelegate[] = [];

    constructor(iTrackerAPI : ISolarSightProvider,
                debug: boolean = false) {
        console.debug('WrapperSolarSightProvider constructor');
        this.#iSolarSightApi = iTrackerAPI;
        this.#isDebug = debug;
        if(window.solarSightProvider !== this) { // another init might happen
            console.warn('New wrapper created before the last one was deleted');
        }
        window.solarSightProvider = this;
    }
    public cleanup = (): void => {
       console.debug('WrapperSolarSightProvider Cleanup ss provider');

        if(window.solarSightProvider === this) { // another init might have happend
            window.solarSightProvider = null;
        }
    }

    public getLastResponse = () : UnityResponse | null => {
        return this.#lastResponse;
    }

    public getAppState = () : WebUserAppLifeCycle => {
        return this.#appState;
    }

    public addListener = (listener: UnityResponseDelegate) : void => {
        this.#responseListeners.push(listener);
    }

    public removeListener = (listener: UnityResponseDelegate): void => {
        this.#responseListeners = this.#responseListeners.filter(l => l !== listener);
    }

    //Unity API functions

    /*
    * Generic function to simplify adding API for unity.
    * This function is called from unity and then calls the actual function in the provider.
    * */
    public callJsFunction(funcName: string, args:string|undefined) : string | undefined{
        console.debug(`JS. callJsFunction ${funcName} >> ${this.#isDebug? args : ""}`);
        let argParsed = undefined;
        try {
            if (args !== undefined && args !== null && args !== "") {
                argParsed = JSON.parse(args);
            } else {
                argParsed = undefined;
            }
            const jsFuncName:string = this.fromPascalCaseToCamelCase(funcName);
            if(this.tryRecievedResponse(jsFuncName, argParsed)) {
                console.debug(`areApiFunctionsDefined.JS. Function ${jsFuncName} is response`);
                return undefined;
            }
            // @ts-ignore //Check if the function is defined by name
            const func:any = this.#iSolarSightApi[jsFuncName];
            if (typeof func !== 'function') {
                // @ts-ignore
                if(window.isValidtingApi) {
                    console.error(`callJsFunction.JS. Function ${jsFuncName} not defined - returns undefined`);
                }
                return undefined;
            } else {
                const ret = argParsed !== undefined ? func(argParsed) : func();
                console.debug(`JS. Function ${jsFuncName} ret`, ret);
                this.tryUpdateState(jsFuncName, argParsed);
                return ret;
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    /*
    * Validate all API functions are defined - this runs on start and writes error to log.
    * This is log and continue action. Mostly used for manual release testing.
    * TBD, consider to throw exception and stop the app.
    * */
    public areApiFunctionsDefined(validattionJson: string) : boolean{
        console.debug(`JS. areApiFunctionsDefined ${validattionJson}`);
        var validateApi:ValidateApi = JSON.parse(validattionJson);
        let valid = true;

        for (const funcName of validateApi.ApiMethodNames) {
            const camelFuncName = this.fromPascalCaseToCamelCase(funcName);
            if(camelFuncName === ResponseFuncName){
                continue;
            }
            // @ts-ignore
            if (typeof this.#iSolarSightApi[camelFuncName] !== 'function') {
                // @ts-ignore
                if(window.isValidtingApi) {
                    console.error(`areApiFunctionsDefined.JS. ${funcName} not defined : ${this.#iSolarSightApi}`);
                    valid = false;
                } else {
                    console.warn(`areApiFunctionsDefined.JS. ${funcName} not defined`);
                }
            }
        }
        if(validateApi.AppVersion !== AppVersion) {
            valid = false;
            console.error(`JS. Function appVersion incorrect. Expected ${AppVersion} but got ${validateApi.AppVersion}`);
        }
        if(valid) {
            console.debug(`JS. areApiFunctionsDefined success`);
        }
        return valid;
    }

    //Private functions
    private onSolarSightEvent = (funcName: string, args: any) => {
        console.debug(`SolarSightApi.onSolarSightEvent.${funcName} >> ${args}`);
        if(funcName === AppStateFuncName) {
            this.#appState = args;
        }
    }

    private tryRecievedResponse = (funcName: string, response: any|undefined) : boolean => {
        if(funcName == ResponseFuncName) {
            this.#lastResponse = response;
            console.log(`onSolarSightEvent ${response}`);

            for (var listener of this.#responseListeners) {
                try {
                    listener(response);
                }catch (e) {
                    console.error(`failed event raise ${funcName}(${response}) ` , e);
                }
            }
            return true;
        }
        return false;
    }

    private tryUpdateState = (funcName: string, args: any|undefined) : boolean => {
        if(funcName === AppStateFuncName) {
            this.#appState = args;
            return true;
        }
        return false;
    }

    private fromPascalCaseToCamelCase = (str: string) : string => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
}