import { FC } from "react";
import { formatDistanceToNow, subMinutes } from "date-fns";
import { humanizeEnumValue } from "../../../../common/EnumUtils";
import { TrackerNamesMap } from "../../../sites/SiteModels";
import { TrackerNameById, tryGetTrackerName } from "../../../trackers/TrackerName";
import {
  TokenTransformersMap,
  transformDescription,
  ValueTransformer,
} from "../../FaultDescriptionUtils";
import {
  AlertTransition,
  IAlertMetadata,
  UrgencyLevel,
  ComponentType,
  ISingleDeviceAlertTransition,
  SafeReason,
} from "../../DTOs";
import { AlertUnion } from "../../Models";
import { NormalizedAggregatedAlertTransition } from "./Models";
import LightTooltipOnEllipsis from "../../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import classes from "./AlertTransitionDescription.module.css";

interface IAlertTransitionDescriptionProps {
  transition: AlertTransition;
  alert: AlertUnion;
  metadata: IAlertMetadata;
}

export const AlertTransitionDescription: FC<IAlertTransitionDescriptionProps> = ({
  transition,
  alert,
  metadata,
}) => {
  const rawDescription =
    transition.newUrgencyLevel === UrgencyLevel.Closed
      ? metadata.closedStateDescription
      : metadata.openStateDescription;
  const transformers = createAlertTransitionDescriptionTokenTransformers(
    alert,
    transition,
    (index, token, value) => {
      return token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker ? (
        <TrackerNameById id={value as number} />
      ) : (
        <span key={index}>{value}</span>
      );
    }
  );
  const elements = transformDescription(rawDescription, transformers);
  return (
    <LightTooltipOnEllipsis title={elements} customTooltipSx={{ padding: "1.2em", fontSize: 14 }}>
      <div className={classes.description}>{elements}</div>
    </LightTooltipOnEllipsis>
  );
};

export function formatAlertTransitionDescription(
  transition: AlertTransition,
  alert: AlertUnion,
  metadata: IAlertMetadata,
  trackerNamesMap: TrackerNamesMap
): string {
  const rawDescription =
    transition.newUrgencyLevel === UrgencyLevel.Closed
      ? metadata.closedStateDescription
      : metadata.openStateDescription;
  const transformers = createAlertTransitionDescriptionTokenTransformers(
    alert,
    transition,
    (_index, token, value) =>
      token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker
        ? tryGetTrackerName(value as number, trackerNamesMap)
        : (value?.toString() ?? "")
  );
  return transformDescription(rawDescription, transformers).join("");
}

function createAlertTransitionDescriptionTokenTransformers<T>(
  alert: AlertUnion,
  transition: AlertTransition,
  transformValue: ValueTransformer<T>
): TokenTransformersMap<T> {
  if (alert.kind === "aggregated") {
    const normalizedAggregatedAlertTransition = transition as NormalizedAggregatedAlertTransition;
    return {
      Total: (index) =>
        transformValue(index, "Total", normalizedAggregatedAlertTransition.allDevices.length),
      Percent: (index) =>
        transformValue(
          index,
          "Percent",
          fractionToPercentage(normalizedAggregatedAlertTransition.errorFraction)
        ),
      ValidPercentage: (index) =>
        transformValue(
          index,
          "ValidPercentage",
          fractionToPercentage(1 - normalizedAggregatedAlertTransition.errorFraction)
        ),
      RawText: (index, text) => transformValue(index, "RawText", text),
    };
  }
  if (alert.kind === "single-device") {
    const singleDeviceAlertTransition = transition as ISingleDeviceAlertTransition;
    return {
      ComponentNumber: (index) => transformValue(index, "ComponentNumber", alert.deviceId),
      AdditionalData_Minutes: (index) =>
        transformValue(
          index,
          "AdditionalData_Minutes",
          singleDeviceAlertTransition.additionalInfo &&
            formatMinutesAgo(singleDeviceAlertTransition.additionalInfo)
        ),
      AdditionalData_Percent: (index) =>
        transformValue(
          index,
          "AdditionalData_Percent",
          singleDeviceAlertTransition.additionalInfo &&
            fractionToPercentage(singleDeviceAlertTransition.additionalInfo)
        ),
      AdditionalData_SafeSource: (index) =>
        transformValue(
          index,
          "AdditionalData_SafeSource",
          singleDeviceAlertTransition.additionalInfo &&
            humanizeEnumValue(SafeReason[singleDeviceAlertTransition.additionalInfo])
        ),
      RawText: (index, text) => transformValue(index, "RawText", text),
    };
  }
  return {
    RawText: (index, text) => transformValue(index, "RawText", text),
  };
}

const formatMinutesAgo = (minutes: number) =>
  formatDistanceToNow(subMinutes(new Date(), minutes), { addSuffix: false });

const fractionToPercentage = (fraction: number) => Math.round(fraction * 100);
