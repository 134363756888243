import { FC } from "react";

import { Popover } from "@mui/material";

import PopoverHeader from "../popover/PopoverHeader";
import { PopoverHorizontalPlacement } from "../popover/PopoverModels";

interface IPopperProps {
  children: React.ReactElement;
  title?: string;
  transformHorizontalPlacement?: PopoverHorizontalPlacement;
  anchorHorizontalPlacement?: PopoverHorizontalPlacement;
  isOpen: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  displayHeader?: boolean;
}

const Dialog: FC<IPopperProps> = ({
  children,
  title,
  isOpen,
  handleClose,
  anchorEl,
  transformHorizontalPlacement = "center",
  anchorHorizontalPlacement = "right",
  displayHeader = true,
}) => {
  const id = isOpen ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: anchorHorizontalPlacement,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: transformHorizontalPlacement,
      }}
      sx={{
        "& .MuiPopover-paper": {
          fontFamily: "var(--font-family)",
          boxShadow: "var(--tooltip-box-shadow)",
          backgroundColor: "var(--card-background-color)",
          borderRadius: "var(--tooltip-border-radius)",
          color: "var(--blue-color-primary)",
          marginTop: "10px",
        },

        "& .MuiPopover-paper > div": {
          overflowY: "auto",
          maxHeight: "60vh",
          padding: "20px",
        },
      }}
    >
      {displayHeader && <PopoverHeader title={title} />}
      <div>{children}</div>
    </Popover>
  );
};

export default Dialog;
