import { FC } from "react";

import { useSelector } from "react-redux";

import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, AuxiliariesStatusIcons } from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import { selectIsTagValid } from "../app/store/MultisiteTagsStore";

interface StatusMarkProps {
  tagName: string;
  validIcon: AuxiliariesStatusIcons;
  invalidIcon: AuxiliariesStatusIcons;
  siteId: string;
}

const StatusIcon: FC<StatusMarkProps> = ({ tagName, validIcon, invalidIcon, siteId }) => {
  const isTagValid = useSelector((state: RootState) => selectIsTagValid(state, siteId, tagName));
  const MyIcon = getIcons(isTagValid ? validIcon : invalidIcon, IconCategory.Status);
  return <MyIcon />;
};
export default StatusIcon;
