import { FC, ReactNode } from "react";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";

// see https://mui.com/joy-ui/integrations/material-ui/

const materialTheme = materialExtendTheme({
  typography: {
    fontFamily: "var(--font-family)",
    fontWeightBold: "var(--font-bold-weight)",
    fontWeightRegular: "var(--font-weight)",
  },
});

export const SgStylingProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    <JoyCssVarsProvider>{children}</JoyCssVarsProvider>
  </MaterialCssVarsProvider>
);
