import React, { useEffect, useState } from "react";

import { Skeleton, TextField } from "@mui/material";

import { useSelector } from "react-redux";
import classes from "./DasDeploy.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import * as api from "../SiteControlAppsDeployApi";
import { ModuleRuntimeStatus, SiteAppModule } from "../AppConfigModels";
import { selectSiteId } from "../../sites/SiteStore";
import useAsyncOperationWithErrorHandling from "../../../common/Hooks/useAsyncOperationWithErrorHandling";

const siteApp = SiteAppModule.Das;
const DEPLOYMENT_VERSION_TAG = "das_version";

const DasDeploy = () => {
  const [tagVersion, setTagVersion] = useState<string>("");
  const [status, setStatus] = useState<ModuleRuntimeStatus | "Click to check status" | null>(
    "Click to check status"
  );
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [deviceIdList, setDeviceIdList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [versionsList, setVersionsList] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<string>("");
  const siteId = useSelector(selectSiteId);
  const siteIdPrefix = siteId.split("-")[0];

  const handleError = (message: string) => {
    setAlertMessage({
      text: message,
      severity: "error",
    });
  };
  const runAsyncFuncWithErrorHandling = useAsyncOperationWithErrorHandling(handleError);

  useEffect(() => {
    async function fetchData() {
      await Promise.all([getIotDeviceIds(), getDeploymentVersions()]);
      setIsLoading(false);
    }
    fetchData();
  }, [siteId]);

  const isReady = status !== ModuleRuntimeStatus.NotExist && status !== "Click to check status";

  const handleSelectDeviceId = (value: string | null) => {
    if (value === null) {
      return;
    }
    if (value.startsWith(siteIdPrefix)) {
      setDeviceId(value);
    } else if (value.length > 0) {
      if (
        window.confirm(`The device id is ${value} and the site is ${siteId}, are you sure to continue?`)
      ) {
        setDeviceId(value);
      }
    }
  };

  const handleCheckDeployStatus = async () => {
    try {
      const status = await api.getModuleStatusOnDevice(siteId, deviceId, siteApp);
      setStatus(status);
    } catch (error) {
      setAlertMessage({
        text: `Failed to get module status on device ${deviceId}`,
        severity: "error",
      });
    }
  };

  const handleStartDeploy = async () => {
    await runAsyncFuncWithErrorHandling(
      () => api.ensureSiteModuleTypeExists(siteId, siteApp, deviceId),
      "Failed to ensure site module type DAS exist"
    );
    await runAsyncFuncWithErrorHandling(
      () => api.addOrUpdateTagInIotDevice(deviceId, DEPLOYMENT_VERSION_TAG, tagVersion),
      "Failed to add or update tag In IoT device"
    );
  };

  const getIotDeviceIds = async () => {
    const deviceList = await runAsyncFuncWithErrorHandling(
      api.getIotDevicesIdList,
      "Failed to get Iot Device Id"
    );
    if (deviceList.length === 0) {
      setAlertMessage({
        text: "No Iot Device Id found",
        severity: "error",
      });
    } else {
      setDeviceIdList(deviceList);
    }
  };

  const getDeploymentVersions = async () => {
    const versions = await runAsyncFuncWithErrorHandling(
      () => api.getDeploymentVersions(DEPLOYMENT_VERSION_TAG),
      "Failed to get versions"
    );
    if (versions.length === 0) {
      setAlertMessage({
        text: "No IoT versions found",
        severity: "error",
      });
    } else {
      setVersionsList(versions);
    }
  };

  const handleUseDas = async () => {
    try {
      await api.updateMcsToUseDas(siteId);
    } catch (error) {
      setAlertMessage({
        text: "Failed to update Mcs to use Das",
        severity: "error",
      });
      throw new Error("Failed to updateMcsToUseDas");
    }
    setAlertMessage({
      text: "Mcs configuration applied successfully",
      severity: "success",
    });
  };

  const filterDeviceIdList: string[] = deviceIdList.filter((x) => x.startsWith(siteIdPrefix));

  const isDeviceIdAndSiteIsNotMatch = filterDeviceIdList.length === 0 && deviceIdList.length > 0;
  if (isLoading) {
    return (
      <div className={classes["root"]}>
        <Skeleton variant="rounded" width="100%" height="300px" />
      </div>
    );
  }

  return (
    <div className={classes["root"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes["row"]}>
        {isDeviceIdAndSiteIsNotMatch ? (
          <SolarGikInput
            type="text"
            label="Device Id"
            placeholder="Device Id"
            value={deviceId}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
              setDeviceId(e.target.value)
            }
          />
        ) : (
          <SolarGikAutocomplete
            size="small"
            options={filterDeviceIdList}
            renderInputFunc={(params) => <TextField {...params} label="Device Id" sx={inputStyle} />}
            onChange={(_event, value) => handleSelectDeviceId(value)}
            value={deviceId}
            key={"device Id"}
            id={"device Id"}
          />
        )}
      </div>

      <div className={classes["row"]}>
        <SolarGikAutocomplete
          size="small"
          width="300px"
          options={versionsList}
          renderInputFunc={(params) => <TextField {...params} label="Version" sx={inputStyle} />}
          onChange={(_event, value) => setTagVersion(value ?? "")}
          disabled={deviceId === ""}
          value={tagVersion}
          key={"version"}
          id={"version"}
        />
        <SolarGikButton
          text="start deploy"
          style={outlinedWhiteButton}
          onClickFunc={handleStartDeploy}
          isDisabled={tagVersion === ""}
        />
      </div>

      <div className={classes["row"]}>
        <SolarGikButton
          text={`Check deploy status - ${status}`}
          onClickFunc={handleCheckDeployStatus}
          style={{
            ...outlinedWhiteButton,
            backgroundColor: isReady ? "var(--ok-color)" : "",
          }}
        />
      </div>

      <div className={classes["row"]}>
        <SolarGikButton
          text="Use das"
          style={outlinedWhiteButton}
          isDisabled={!isReady}
          onClickFunc={handleUseDas}
        />
      </div>
    </div>
  );
};

export default DasDeploy;
