import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import classes from "./Emergency.module.css";
import { getDesiredEmergencyStatusAsync, setDesiredEmergencyStatusAsync } from "./EmergencyApi";
import {
  UserSafeModeState,
  IEmergencyStatus,
  SafetyReason,
  IEmergencyToastState,
  EmergencyCommand,
} from "./EmergencyModel";
import { getEmergencyOptionListConfig } from "./EmergencyOptionListConfig";
import { repetitivePolling } from "../../../common/AsyncUtils";
import SolarGikButton from "../../../SolarGikLib/Button";
import { List } from "../../../SolarGikLib/Lists/List";
import { IListItem } from "../../../SolarGikLib/Lists/ListModels";
import SgPopover from "../../../SolarGikLib/popover/SgPopover";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { RootState } from "../../app/Store";
import { UserAccessType } from "../../user/UserStore";
import { addNewUserRecord } from "../../user_records/UserRecordsAPI";
import { selectSiteId } from "../../sites/SiteStore";
import { selectSafetyReason } from "../../app/store/MultisiteTagsStore";
import LightTooltip from "../../../SolarGikLib/tooltip/LightTooltip";
import {
  emergencyPopoverArrowStyle,
  emergencyPopoverButtonStyle,
  emergencyPopoverListButtonStyle,
  emergencyPopoverReasonListStyle,
  emergencyPopoverStyle,
} from "./EmergencyStyle";
import EmergencyToast from "./EmergencyToast";

const Emergency = () => {
  const siteId = useSelector(selectSiteId);
  const user = useSelector((state: RootState) => state.user);
  const showSelectReason = user.userType >= UserAccessType.Engineer;
  const safetyReason = useSelector((state: RootState) => selectSafetyReason(state, siteId));

  const initialToastState: IEmergencyToastState = {
    open: false,
    isSuccessful: false,
    command: EmergencyCommand.RequestUserSafeMode,
  };
  const [open, setOpen] = useState<boolean>(false);
  const [isInEmergency, setIsInEmergency] = useState<boolean>(false); //default state for UI
  const [emergencyChanged, setEmergencyChanged] = useState<boolean>(false); //change between of sent and on
  const [activeItem, setActiveItem] = useState<IListItem>();
  const [emergencyStateText, setEmergencyStateText] = useState<string>("");
  const [popoverTitleText, setPopoverTitleText] = useState<string>("");
  const [emergencyTooltipText, setEmergencyTooltipText] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<IEmergencyToastState>(initialToastState);
  const emergencyStatusRef = useRef<IEmergencyStatus>({
    DesiredState: false,
    ReportedState: false,
  });

  useEffect(() => {
    if (user.userType < UserAccessType.ViewDashboard) {
      return;
    }
    const cntrlr = new AbortController();
    repetitivePolling(
      "getEmergencyStatusAsync",
      getEmergencyStatusAsync,
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, []);

  const handleEmergencyOpen = () => {
    setIsError(false);
    togglePopover();
  };

  const setOpenToast = (isOpen: boolean): void => {
    setToastStatus((prevState) => ({ ...prevState, open: isOpen }));
  };

  const handleTurnOnEmergency = async () => {
    if (await handleChangeEmergencyStatus()) {
      let description = "Site sent to safe mode by the user";
      if (activeItem?.title) {
        description += ` (${activeItem.title})`;
      }
      createUserSafeActionUserRecords("Safe mode activated", description);
      setOpenToast(true);
      setToastStatus((prevState) => ({
        ...prevState,
        command: EmergencyCommand.RequestUserSafeMode,
      }));
    }
  };

  const handleTurnOffEmergency = async () => {
    if (await handleChangeEmergencyStatus()) {
      createUserSafeActionUserRecords(
        "Safe mode deactivated",
        "Site released from safe mode by the user"
      );
      setOpenToast(true);
      setToastStatus((prevState) => ({
        ...prevState,
        command: EmergencyCommand.ReleaseUserSafeMode,
      }));
    }
  };

  const handleGetEmergencyError = () => {
    if (!open) {
      setIsError(true);
      setEmergencyTooltipText(
        `Failed to get safety state.
        Contact support for help`
      );
    }
  };

  const createUserSafeActionUserRecords = async (reason: string, description: string) => {
    const userRecordDto = {
      EventDate: new Date().getTime(),
      Title: reason,
      Reporter: user.userName,
      ComponentType: "System",
      ComponentName: "-",
      RecordType: "Other",
      Description: description,
      IsEngineerOnly: false,
    };
    await addNewUserRecord(siteId, userRecordDto);
  };

  const handleChangeEmergencyStatus = async () => {
    handleClose();
    setIsError(false);
    try {
      await setDesiredEmergencyStatusAsync(
        siteId,
        !isInEmergency,
        !isInEmergency ? activeItem?.title : undefined
      );
      emergencyStatusRef.current.DesiredState = !isInEmergency;
      await getEmergencyStatusAsync();
      setToastStatus((prevState) => ({ ...prevState, isSuccessful: true }));
      return true;
    } catch (error) {
      console.error(error);
      setIsError(true);
      setToastStatus((prevState) => ({ ...prevState, isSuccessful: false }));
      setEmergencyTooltipText(
        `Failed to change safety state.
         contact support for help`
      );
      return false;
    }
  };

  const getEmergencyStatusAsync = async () => {
    try {
      const emergencyStatus = await getDesiredEmergencyStatusAsync(siteId);
      emergencyStatusRef.current = emergencyStatus;
      if (!emergencyStatus || emergencyStatus.ReportedState === null) {
        handleGetEmergencyError();
        return;
      }
      //sent state
      if (
        emergencyStatus.DesiredState !== null &&
        emergencyStatus.DesiredState !== emergencyStatus.ReportedState
      ) {
        setIsInEmergency(true);
        setEmergencyChanged(true);
        //on state
      } else if (
        emergencyStatus.DesiredState === emergencyStatus.ReportedState &&
        emergencyStatus.DesiredState === true
      ) {
        setIsInEmergency(true);
        setEmergencyChanged(false);
        //off state
      } else {
        setIsInEmergency(false);
        setEmergencyChanged(false);
      }
    } catch (error) {
      handleGetEmergencyError();
    }
  };

  const emergencyState = (): void => {
    setPopoverTitleText("Are You Sure You Want To Release User Safe Mode?");
    if (
      isInEmergency &&
      emergencyStatusRef.current.DesiredState === emergencyStatusRef.current.ReportedState
    ) {
      setEmergencyStateText(UserSafeModeState.UserSafeModeOn);
    } else if (emergencyStatusRef.current.DesiredState !== emergencyStatusRef.current.ReportedState) {
      setEmergencyStateText(UserSafeModeState.UserSafeModeRequested);
    } else {
      setEmergencyStateText(UserSafeModeState.UserSafeModeOff);
      setPopoverTitleText(
        showSelectReason
          ? "What is the reason for enabling User Safe Mode?"
          : "Are You Sure You Want To Send To User Safe Mode?"
      );
    }
  };

  const setTooltipForStateChange = (): void => {
    let tooltipMessage = "";
    if (user.userType < UserAccessType.Operator) {
      tooltipMessage = "Button Not Available\nYou don't have required access";
    } else if (safetyReason == SafetyReason.Unknown) {
      tooltipMessage = "The Site Is Disconnected\nButton Not Available";
    } else if (emergencyStatusRef.current.ReportedState) {
      tooltipMessage = "User Safe Mode Is On.\nClick To Release User Safe Mode";
    } else {
      tooltipMessage = "User Safe Mode Is Off.\nClick To Send To User Safe Mode";
    }

    setEmergencyTooltipText(tooltipMessage);
  };

  const setTooltipForStateOnOff = (): void => {
    if (emergencyStatusRef.current.ReportedState) {
      setEmergencyTooltipText("User Safe is Pending\nClick to Send To User Safe Mode");
    } else {
      setEmergencyTooltipText("User Safe is Pending\nClick To Release User Safe Mode");
    }
  };

  const emergencyTooltip = (): void => {
    if (emergencyStatusRef.current.DesiredState === emergencyStatusRef.current.ReportedState) {
      setTooltipForStateChange();
    } else {
      setTooltipForStateOnOff();
    }
  };

  useEffect(() => {
    emergencyState();
    emergencyTooltip();
  }, [emergencyChanged, isInEmergency, safetyReason]);

  function isDisableSafeModeButton(usertype: UserAccessType, reason: number): boolean {
    if (isError) {
      return true;
    }
    if (usertype < UserAccessType.Operator) {
      return true;
    }
    if (reason === SafetyReason.Unknown) {
      return true;
    }

    return false;
  }

  const isDisable = isDisableSafeModeButton(user.userType, safetyReason);
  const optionListConfig = getEmergencyOptionListConfig(user.userType);
  const togglePopover = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const EmergencyButton = () => {
    return (
      <span>
        <LightTooltip
          title={emergencyTooltipText}
          customTooltipSx={{
            fontSize: 14,
            fontWeight: 400,
            whiteSpace: "pre-line",
            textAlign: "center",
            letterSpacing: "0.05em",
          }}
        >
          <div className={classes["emergency-btn-div"]}>
            <Button
              className={`${classes["emergency-btn"]} ${TextEnum.h6} ${
                classes[`emergency-${isInEmergency}`]
              } ${isDisable ? classes.disabled : ""}`}
              variant="outlined"
              onClick={handleEmergencyOpen}
              disabled={isDisable}
            >
              <span className={TextEnum.h6}>{emergencyStateText}</span>
            </Button>
          </div>
        </LightTooltip>
        <EmergencyToast
          open={toastStatus.open}
          setOpen={setOpenToast}
          isSuccessful={toastStatus.isSuccessful}
          command={toastStatus.command}
        />
      </span>
    );
  };

  const popoverButtonStyle = showSelectReason
    ? emergencyPopoverListButtonStyle
    : emergencyPopoverButtonStyle;
  return (
    <SgPopover
      open={open}
      onClose={handleClose}
      TriggerButton={EmergencyButton()}
      title={popoverTitleText}
      sx={showSelectReason ? emergencyPopoverReasonListStyle : emergencyPopoverStyle}
      tooltipArrowStyle={emergencyPopoverArrowStyle}
    >
      {emergencyStateText === UserSafeModeState.UserSafeModeOff ? (
        <>
          {showSelectReason && (
            <List list={optionListConfig} selectedItem={activeItem} setSelectedItem={setActiveItem} />
          )}
          <div className={classes["button-container"]}>
            <SolarGikButton
              style={popoverButtonStyle}
              text={showSelectReason ? "Send" : "Yes, Send To Safe Mode"}
              isDisabled={showSelectReason && activeItem === undefined}
              onClickFunc={handleTurnOnEmergency}
            />
          </div>
        </>
      ) : (
        <div
          className={`${classes["button-container"]} ${classes["button-container-cancel-emergency"]}`}
        >
          <SolarGikButton
            style={popoverButtonStyle}
            text="Yes, Release Safe Mode"
            onClickFunc={handleTurnOffEmergency}
          />
        </div>
      )}
    </SgPopover>
  );
};

export default Emergency;
