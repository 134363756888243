import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFaultsSubscriber } from "../../faults/DTOs";
import { LoadingState } from "../LoadingState";
import {
  deleteSubscriber as deleteFaultsSubscriber,
  getFaultsSubscribers,
  upsertFaultsSubscriber,
} from "../../faults/API";

interface IFaultStoreState {
  loadingState: LoadingState;
  subscribers: IFaultsSubscriber[];
}

export const initState: IFaultStoreState = {
  loadingState: LoadingState.None,
  subscribers: [],
};

export const faultsSubscribersSlice = createSlice({
  name: "faultsSubscribers",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchFaultsSubscribers.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(fetchFaultsSubscribers.fulfilled, (state, action) => {
        const { subscribers } = action.payload;
        state.loadingState = LoadingState.Complete;
        state.subscribers = subscribers;
      })
      .addCase(fetchFaultsSubscribers.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      })
      .addCase(removeFaultsSubscriber.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(removeFaultsSubscriber.fulfilled, (state) => {
        state.loadingState = LoadingState.Complete;
      })
      .addCase(removeFaultsSubscriber.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      })
      .addCase(updateFaultsSubscriber.pending, (state) => {
        state.loadingState = LoadingState.Pending;
      })
      .addCase(updateFaultsSubscriber.fulfilled, (state) => {
        state.loadingState = LoadingState.Complete;
      })
      .addCase(updateFaultsSubscriber.rejected, (state) => {
        state.loadingState = LoadingState.Error;
      }),
});

export const fetchFaultsSubscribers = createAsyncThunk(
  "faults/fetchFaultsSubscribers",
  async (_, { signal }) => {
    const subscribers = await getFaultsSubscribers(signal);
    return {
      subscribers: subscribers,
    };
  }
);

export const removeFaultsSubscriber = createAsyncThunk(
  "faults/removeFaultsSubscriber",
  async (subscriberId: number, { dispatch, signal }) => {
    await deleteFaultsSubscriber(subscriberId, signal);
    dispatch(fetchFaultsSubscribers());
    return {
      subscriberId,
    };
  }
);

export const updateFaultsSubscriber = createAsyncThunk(
  "faults/upsertFaultsSubscriber",
  async (subscriber: IFaultsSubscriber, { dispatch, signal }) => {
    const updatedSubscriber = await upsertFaultsSubscriber(subscriber, signal);
    dispatch(fetchFaultsSubscribers());
    return {
      updatedSubscriber,
    };
  }
);

export const faultsSubscribersReducer = faultsSubscribersSlice.reducer;
