import { createSlice } from "@reduxjs/toolkit";

import { PagesState } from "./PagesModel";
import { resetSiteSpecificData } from "../features/app/store/GlobalActions";

const defaultPagesState: PagesState = {
  showTrackerViewPage: false,
};
export const pagesSlice = createSlice({
  name: "pages",
  initialState: defaultPagesState,
  reducers: {
    setViewPage: (state, action) => {
      return {
        ...state,
        showTrackerViewPage: action.payload,
      };
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetSiteSpecificData, () => {
      return defaultPagesState;
    }),
});
export const pagesReducer = pagesSlice.reducer;
