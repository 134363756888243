import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, LoadersIcons } from "../../SolarGikLib/icons/IconsModels";

const ChartLoader = getIcons(LoadersIcons.ChartLoader, IconCategory.Loaders);
const Loader = () => {
  return (
    <div
      style={{
        height: "100px",
        width: "100px",
        margin: "auto",
        display: "flex",
        justifyContent: "flex",
        alignItems: "center",
      }}
    >
      <ChartLoader />
      Loading data...
    </div>
  );
};
export default Loader;
