import { FC } from "react";

import classes from "./Card.module.css";
import { TextEnum } from "../TextStyles";
interface CardProp {
  children?: React.ReactNode;
  title?: string;
  contentStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  actions?: React.ReactNode; //TODO verify type.
}

const Card: FC<CardProp> = ({
  title,
  children,
  contentStyle,
  wrapperStyle,
  containerStyle,
  actions,
}) => {
  return (
    <div className={classes["wrapper"]} style={wrapperStyle}>
      <div className={classes["container"]} style={containerStyle}>
        {actions != null && <div className={classes["actions-container"]}>{actions}</div>}
        {title && (
          <span className={`${TextEnum.h4} ${classes["title"]} ${actions && classes["has-actions"]}`}>
            {title}
          </span>
        )}
        <div className={`${classes["content"]} ${title && classes["has-title"]}`} style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
