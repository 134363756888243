import { FC } from "react";
import { TrackerNamesMap } from "../../sites/SiteModels";
import { TrackerNameById, tryGetTrackerName } from "../../trackers/TrackerName";
import { transformDescription, TokenTransformersMap, ValueTransformer } from "../FaultDescriptionUtils";
import { ComponentType, IIssueMetadata, ISingleDeviceIssue, UrgencyLevel } from "../DTOs";
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import classes from "./IssueDescription.module.css";

interface IIssueDescriptionProps {
  issue: ISingleDeviceIssue;
  metadata: IIssueMetadata;
}

export const IssueDescription: FC<IIssueDescriptionProps> = ({ issue, metadata }) => {
  const transformers = createIssueDescriptionTokenTransformers(
    issue,
    metadata,
    (index, token, value) => {
      return token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker ? (
        <TrackerNameById id={value as number} />
      ) : (
        <span key={index}>{value}</span>
      );
    }
  );
  const elements = transformDescription(metadata.openStateDescription, transformers);
  return (
    <LightTooltipOnEllipsis title={elements} customTooltipSx={{ padding: "1.2em", fontSize: 14 }}>
      <div className={classes.description}>{elements}</div>
    </LightTooltipOnEllipsis>
  );
};

export function formatIssueDescription(
  issue: ISingleDeviceIssue,
  metadata: IIssueMetadata,
  trackerNamesMap: TrackerNamesMap
): string {
  const transformers = createIssueDescriptionTokenTransformers(
    issue,
    metadata,
    (_index, token, value) =>
      token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker
        ? tryGetTrackerName(value as number, trackerNamesMap)
        : (value?.toString() ?? "")
  );
  return transformDescription(metadata.openStateDescription, transformers).join("");
}

function createIssueDescriptionTokenTransformers<T>(
  issue: ISingleDeviceIssue,
  issueMetadata: IIssueMetadata,
  transformValue: ValueTransformer<T>
): TokenTransformersMap<T> {
  return {
    ComponentNumber: (index) => transformValue(index, "ComponentNumber", issue.deviceId),
    ScreeningTimeMinutes: (index) =>
      transformValue(index, "ScreeningTimeMinutes", formatScreeningTimes(issue, issueMetadata)),
    RawText: (index, rawText) => transformValue(index, "RawText", rawText),
  };
}

function formatScreeningTimes(issue: ISingleDeviceIssue, issueMetadata: IIssueMetadata) {
  switch (issue.urgencyLevel) {
    case UrgencyLevel.High:
      return issueMetadata.screeningTimesMinutes?.high?.toString() ?? "";
    case UrgencyLevel.Medium:
      return issueMetadata.screeningTimesMinutes?.medium?.toString() ?? "";
    case UrgencyLevel.Low:
      return issueMetadata.screeningTimesMinutes?.low?.toString() ?? "";
    default:
      return "";
  }
}
