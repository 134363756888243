import { ISolarSightApi, SolarSightLoader } from "@solargik/solar-sight-webgl";
import { SolarSightProvider } from "./solarSightProvider";

const solarSightLoader = new SolarSightLoader();
let solarsightBinariesPromise: Promise<void> | null = null;

export const loadSolarSightBinaries = async () => {
  if (!solarsightBinariesPromise) {
    solarsightBinariesPromise = solarSightLoader.loadSolarSightBinariesAsync();
  }
};

export async function initializePixelsCanvas(
  siteId: string,
  signal: AbortSignal,
  solarSightProvider: SolarSightProvider,
  onLoadProgress: (progress: number) => void
): Promise<ISolarSightApi | null> {
  if (!solarsightBinariesPromise) {
    loadSolarSightBinaries();
  }

  await solarsightBinariesPromise;
  const pixelsClient = await createPixelsClient(siteId, signal, solarSightProvider, onLoadProgress);
  if (!pixelsClient || signal.aborted) {
    return null;
  }
  const intervalId = setInterval(() => {
    pixelsClient.refreshAsync().catch((error) => {
      // we can ignore errors that occur when the signal is aborted (canvas possibly unmounted)
      if (!signal.aborted) {
        console.error(error);
      }
    });
  }, 5000);
  signal.addEventListener("abort", () => clearInterval(intervalId));
  return pixelsClient;
}

async function createPixelsClient(
  siteId: string,
  signal: AbortSignal,
  solarSightProvider: SolarSightProvider,
  onLoadProgress: (progress: number) => void
) {
  // debounce for 100ms to avoid React's strict-mode initialization issues
  // (specifically, we call web assembly code that relies on the exitence of canvas
  // element that might be unmounted by the time the initialization completes)
  await new Promise((resolve) => setTimeout(resolve, 100));
  try {
    signal.throwIfAborted();
    const solarSightApi = await solarSightLoader.initSolarSightAsync(solarSightProvider, onLoadProgress);
    signal.throwIfAborted();
    await solarSightApi.setSiteAsync(siteId);
    signal.throwIfAborted();
    await solarSightApi.refreshAsync();
    return solarSightApi;
  } catch (error) {
    if (!signal.aborted) {
      throw error;
    }
  }
}
