import axios from "axios";

import { IEmergencyStatus } from "./EmergencyModel";
import APP_CONFIG from "../../app/configuration/AppConfig";

export async function setDesiredEmergencyStatusAsync(siteId: string, active: boolean, reason?: string) {
  const url = `${APP_CONFIG.serviceUrls.siteGatewayUrlPrefix}/site/${siteId}/emergency`;
  const apiResponse = await axios.post<void>(url, { active, reason });
  return apiResponse;
}

export async function getDesiredEmergencyStatusAsync(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.siteGatewayUrlPrefix}/site/${siteId}/emergency`;
  const apiResponse = await axios.request<IEmergencyStatus>({ url });
  return apiResponse.data;
}
