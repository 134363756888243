import "./App.css";
import "./SolarGikLib/TextStyles.css";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { PersistGate } from "reduxjs-toolkit-persist/lib/integration/react";
import { persistor } from "./features/app/Store";
import useDetectIncorrectScreenSize from "./common/Hooks/useDetectIncorrectScreenSize";
import { loginRequest } from "./features/auth/authConfig";
import SudoModeListener from "./features/user/SudoModeListener";
import InitApp from "./pages/InitApp";
import PageRoutes from "./pages/PageRoutes";
import TagsPoller from "./pages/TagsPoller";
import { ClarityInit } from "./features/analytics/ClarityInit";
import getIcons from "./SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "./SolarGikLib/icons/IconsModels";
import { SgStylingProvider } from "./common/Mui/SgStylingThemeProvider";
import IssuesPoller from "./pages/IssuesPoller";
import { BackdropPortalAnchorElementId } from "./SolarGikLib/backdrop/SgBackdrop";
import { PixelInit } from "./features/trackers/pixelsView/PixelInit";

const App = () => {
  const isSmallScreen: boolean = useDetectIncorrectScreenSize();
  const SolargikIcon = getIcons(GeneralIcons.SolarGikLogo, IconCategory.General);

  return (
    <SgStylingProvider>
      <div className={`App ${isSmallScreen ? "small-screen" : ""}`}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
          <PersistGate loading={null} persistor={persistor}>
            <SudoModeListener />
            <InitApp
              loadingIcon={
                <div className="third-height">
                  <SolargikIcon fontSize={"large"} />
                </div>
              }
            >
              <PageRoutes />
              <TagsPoller />
              <IssuesPoller />
              <ClarityInit />
              <PixelInit />
              <div id={BackdropPortalAnchorElementId}></div>
            </InitApp>
          </PersistGate>
        </MsalAuthenticationTemplate>
      </div>
      {isSmallScreen && (
        <div className="App small-screen-show">
          Screen size is too small - responsiveness is under development
        </div>
      )}
    </SgStylingProvider>
  );
};

export default App;
