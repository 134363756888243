import React from "react";
import classes from "./HeaderUi.module.css";
import SolarGikDropdown, { MenuItem } from "../../../SolarGikLib/fields/Dropdown";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";

interface HeaderUiProps {
  multisiteDashboard: string;
  userMenuItems: MenuItem[];
}

const HeaderUi: React.FC<HeaderUiProps> = ({ multisiteDashboard, userMenuItems }) => {
  const SolarGikLogoIcon = getIcons(GeneralIcons.SolarGikLogo, IconCategory.General);
  const UserIcon = getIcons(GeneralIcons.UserIcon, IconCategory.General);
  return (
    <div className={classes.header}>
      <div className={classes.leftItems}>
        <SolarGikLogoIcon />
        <span className={classes["page-name"]}>{multisiteDashboard}</span>
      </div>
      <div className={classes.rightItems}>
        <SolarGikDropdown icon={UserIcon} menuItems={userMenuItems}></SolarGikDropdown>
      </div>
    </div>
  );
};
export default HeaderUi;
