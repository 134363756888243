import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import classes from "./CellItem.module.css";
import { TableCellSkeletonWrapper } from "../../../common/Mui/TableCellTextSkeletonWrapper";
import { TagTimeValidity } from "../../data_point/models/TagsModels";
import DataPointTooltip from "../../data_point/wrapper/DataPointTooltip";
import InvalidIcon from "../../html_elements/InvalidIcon";
import { ITagDataWithMetaUnit } from "../MultiSiteModel";

const CellItem: React.FC<GridRenderCellParams> = (params) => {
  const value: ITagDataWithMetaUnit = params.row[params.field] as ITagDataWithMetaUnit;

  return (
    <TableCellSkeletonWrapper isDataExists={params.row[params.field] != null}>
      <DataPointTooltip
        tagName={params.field}
        siteId={params.row.siteId}
        isShowDateOnly={true}
        isShowTagMetadataOnly={false}
      >
        <div className={classes.value}>
          {value && <InvalidIcon showInvalid={value.timeValidity == TagTimeValidity.ValidButOld} />}
          {params.formattedValue}
          {value && value.timeValidity !== TagTimeValidity.Invalid && <span>{value.unit}</span>}
        </div>
      </DataPointTooltip>
    </TableCellSkeletonWrapper>
  );
};

export default CellItem;
