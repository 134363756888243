import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Download, Pause, PlayArrow } from "@mui/icons-material";
import dayjs from "dayjs";
import { AppDispatch, RootState } from "../app/Store";
import { LoadingState } from "../app/LoadingState";
import { ICameraWithAgentMetadata } from "../sites/SiteModels";
import classes from "./CameraPanel.module.scss";
import SolarGikAlert from "../../SolarGikLib/alerts/Alert";
import getIcons from "../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT } from "../app/DayjsUtils";
import APP_CONFIG from "../app/configuration/AppConfig";
import { CameraMovementControls } from "./CameraMovementControls";
import { fetchSnapshot, getKey, healthCheck } from "./camerasStore";

interface ICameraPanelProps {
  siteId: string;
  cameraMetadata: ICameraWithAgentMetadata;
}

const autoPauseAfterMs = 5 * 60 * 1000;

const CamerasPanel: FC<ICameraPanelProps> = ({ siteId, cameraMetadata }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLiveStreamOn, setIsLiveStreamOn] = useState(true);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const SolarGikLogoIcon = getIcons(GeneralIcons.SolarGikLogo, IconCategory.General);
  const healthState = usePeriodicHealthCheck(dispatch, siteId, cameraMetadata);
  const snapshotState = usePeriodicSnapshot(
    dispatch,
    !!healthState && !healthState.error && isLiveStreamOn,
    siteId,
    cameraMetadata
  );
  useAutoPause(isLiveStreamOn, setIsLiveStreamOn);
  const toggleLiveStream = () => setIsLiveStreamOn(!isLiveStreamOn);
  const alertComponent = healthState?.error && (
    <div className={classes["error-banner"]}>
      <SolarGikAlert
        message={{
          text: healthState.error.toString(),
          severity: "error",
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setMessage={() => {}}
        isCloseIconVisible={false}
      />
    </div>
  );

  return (
    <div
      className={classes["container"]}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      {!snapshotState?.image && (
        <div className={classes["image-container-placeholder"]}>
          {alertComponent}
          {healthState?.error ? (
            <div className={classes["offline-banner"]}>OFFLINE</div>
          ) : (
            <SolarGikLogoIcon className={classes["logo"]} />
          )}
          <div className={classes["camera-name"]}>{cameraMetadata.name}</div>
        </div>
      )}
      {snapshotState?.image && (
        <div className={classes["image-container"]}>
          {alertComponent}
          {healthState?.error && <div className={classes["offline-banner"]}>OFFLINE</div>}
          <div className={classes["camera-name"]}>{cameraMetadata.name}</div>
          <img className={classes["image"]} src={snapshotState?.image} alt="Camera's image" />
          {isLiveStreamOn && (
            <CameraMovementControls
              siteId={siteId}
              cameraMetadata={cameraMetadata}
              isMouseHover={isMouseHover}
              disabled={!healthState || !!healthState.error}
            />
          )}
          <div
            className={
              classes["download-button-container"] +
              " " +
              (isMouseHover ? classes["panel-mouse-hover"] : "")
            }
          >
            <IconButton
              onClick={() => downloadImage(cameraMetadata.name, snapshotState?.image)}
              size="small"
            >
              <Download />
            </IconButton>
          </div>
          <div
            className={
              classes["center-play-button"] +
              " " +
              (isMouseHover ? classes["panel-mouse-hover"] : "") +
              " " +
              (!isLiveStreamOn ? classes["paused"] : "")
            }
          >
            <IconButton onClick={toggleLiveStream} size="large">
              {isLiveStreamOn && <Pause />}
              {!isLiveStreamOn && <PlayArrow />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

function usePeriodicSnapshot(
  dispatch: AppDispatch,
  active: boolean,
  siteId: string,
  cameraMetadata: ICameraWithAgentMetadata
) {
  const cameraId = getKey(cameraMetadata);
  const snapshotState = useSelector((state: RootState) => state.cameras.snapshots[cameraId]);
  const [isInitialSnapshot, setIsInitialSnapshot] = useState(true);
  const savedSnapshotState = useRef<typeof snapshotState>();
  savedSnapshotState.current = snapshotState;
  useEffect(() => {
    if (!active) {
      return;
    }
    let dispatchOp = dispatch(
      fetchSnapshot({
        siteId,
        cameraId: cameraMetadata,
        initial: isInitialSnapshot,
      })
    );
    setIsInitialSnapshot(false);
    const interval = setInterval(() => {
      if (savedSnapshotState.current?.commandState === LoadingState.Pending) {
        return;
      }
      dispatchOp.abort();
      dispatchOp = dispatch(fetchSnapshot({ siteId, cameraId: cameraMetadata, initial: false }));
    }, APP_CONFIG.milliSecBetweenCamerasSnapshot);
    return () => {
      clearInterval(interval);
      dispatchOp.abort();
    };
  }, [active]);
  return snapshotState;
}

function usePeriodicHealthCheck(
  dispatch: AppDispatch,
  siteId: string,
  cameraMetadata: ICameraWithAgentMetadata
) {
  const cameraId = getKey(cameraMetadata);
  const healthState = useSelector((state: RootState) => state.cameras.healthCheck[cameraId]);
  const savedHealthState = useRef<typeof healthState>();
  savedHealthState.current = healthState;
  useEffect(() => {
    let dispatchOp = dispatch(healthCheck({ siteId, cameraId: cameraMetadata, isInitial: true }));
    const interval = setInterval(() => {
      if (savedHealthState.current?.commandState === LoadingState.Pending) {
        return;
      }
      dispatchOp.abort();
      dispatchOp = dispatch(healthCheck({ siteId, cameraId: cameraMetadata, isInitial: false }));
    }, APP_CONFIG.milliSecBetweenCamerasHealthCheck);
    return () => {
      clearInterval(interval);
      dispatchOp.abort();
    };
  }, []);
  return healthState;
}

function useAutoPause(isLiveStreamOn: boolean, setIsLiveStreamOn: (newState: boolean) => void) {
  useEffect(() => {
    const timeout = setTimeout(() => setIsLiveStreamOn(false), autoPauseAfterMs);
    return () => clearTimeout(timeout);
  }, [isLiveStreamOn]);
}

function downloadImage(cameraName: string, image?: string) {
  if (!image) {
    return;
  }
  const url = `data:${image}`;
  const link = document.createElement("a");
  link.href = url;
  const fileNameSafeCameraName = cameraName.replace(/[^a-z0-9]/gi, "_").toLowerCase();
  const fileNameTime = dayjs().format(DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT);
  const fileName = `snapshot_${fileNameSafeCameraName}_${fileNameTime}.jpg`;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

export default CamerasPanel;
