import { FC, useMemo } from "react";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import SgDateRangePicker, { IRangePreset } from "../../SolarGikLib/datetimepicker/SgAntdDateRangePicker";

interface IDateTimeRange {
  updateRange: (start: Dayjs, end: Dayjs) => void;
}
const DateTimeRange: FC<IDateTimeRange> = ({ updateRange }) => {
  const isEnableQueryHistoryForMoreThanOneWeek = useSelector(
    (state: RootState) =>
      state.multiSitesMetadata.sites[state.site.siteId].visualInfo.isEnableQueryHistoryForMoreThanOneWeek
  );
  const maxRange: { value: number; unit: ManipulateType } = useMemo(
    () =>
      isEnableQueryHistoryForMoreThanOneWeek ? { value: 3, unit: "months" } : { value: 7, unit: "d" },
    [isEnableQueryHistoryForMoreThanOneWeek]
  );
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const startOfDay = dayjs().tz(siteTimezone).startOf("day");
  const rangePresets: IRangePreset[] = useMemo(
    () => [
      { label: "Last 3 Days", value: [startOfDay.add(-3, "d"), startOfDay] },
      { label: "Last 7 Days", value: [startOfDay.add(-7, "d"), startOfDay] },
      { label: "Last 14 Days", value: [startOfDay.add(-14, "d"), startOfDay] },
      { label: "Last 30 Days", value: [startOfDay.add(-30, "d"), startOfDay] },
    ],
    [startOfDay.day()]
  );
  const initialValues = useMemo(
    () => [startOfDay.add(4, "h"), startOfDay.add(20, "h")],
    [startOfDay.day()]
  );
  const now = dayjs();
  return (
    <SgDateRangePicker
      initalStart={initialValues[0]}
      initialEnd={initialValues[1]}
      updateRange={updateRange}
      rangePresets={rangePresets}
      maxRange={maxRange}
      disabledDate={(date) => date.isAfter(now)}
    />
  );
};

export default DateTimeRange;
