import { FC } from "react";
import classes from "./TrackerTooltipContent.module.css";
import { TextEnum } from "../../SolarGikLib/TextStyles";

interface ITrackerTooltipContentProps {
  header: string;
  info: string[] | undefined;
  customClass?: string;
}

const TrackerTooltipContent: FC<ITrackerTooltipContentProps> = ({ customClass, header, info }) => {
  return (
    <div className={classes["content-container"]}>
      <div className={classes["header-container"]}>
        <div className={`${classes["exclamation-mark"]} ${customClass}`}>{"!"}</div>
        <div className={TextEnum.h4}>{header}</div>
      </div>
      {info && info.length > 0 && (
        <ol
          className={`${classes[info.length == 1 ? "single-list-item" : "multiple-list-items"]} ${TextEnum.h5}`}
        >
          {info.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default TrackerTooltipContent;
