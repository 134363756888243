import { FC } from "react";

import MultisiteContent from "../multi_sites/MultiSiteContent";

interface IPageContent {
  content: React.ReactNode;
}
//The responsibility of this component is to render the content of the page
const MultisiteContentPageRouter: FC<IPageContent> = ({ content }) => {
  return <MultisiteContent>{content}</MultisiteContent>;
};

export default MultisiteContentPageRouter;
