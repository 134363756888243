import { useState } from "react";

import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import classes from "./CloudConfiguration.module.css";
import { RootState } from "../features/app/Store";
import { setDesiredEmergencyStatusAsync } from "../features/banner/emergency/EmergencyApi";
import { initTimeScaleSchemeForNewSiteAsync } from "../features/setup/setupAPI";
import SolarGikDropdown from "../SolarGikLib/fields/Dropdown";
const CloudConfiguration = () => {
  const [siteId, setSiteId] = useState("Site Id");

  const isSiteIdValid = (siteId: string) => {
    const re = /^(\d+(-[A-Za-z0-9]+)+)$/;
    return re.test(siteId);
  };

  const handleStartEmergency = async () => {
    await setDesiredEmergencyStatusAsync(siteId, true);
  };
  const handleEndEmergency = async () => {
    await setDesiredEmergencyStatusAsync(siteId, false);
  };

  const handleTimeScaleClick = async () => {
    await initTimeScaleSchemeForNewSiteAsync(siteId);
  };
  const sitesIds: string[] = useSelector((state: RootState) => state.user.siteIds);
  const menuItems = sitesIds.map((siteId) => {
    return {
      label: siteId,
      onClick: () => {
        setSiteId(siteId);
      },
    };
  });

  return (
    <div className={classes.content}>
      <div className={classes["data-wrapper"]}>
        <h1> Setup </h1>
        <SolarGikDropdown title={siteId} menuItems={menuItems} />
        <div className={classes.row}>
          <h4>Set Emergency: </h4>
          <Button className={classes.button} variant="contained" onClick={handleStartEmergency}>
            Start
          </Button>
          <Button className={classes.button} variant="outlined" onClick={handleEndEmergency}>
            End
          </Button>
        </div>
        {!isSiteIdValid(siteId) && <span className={classes.error}>valid site id is required</span>}
        <div className={classes.row}>
          <h4>Set Time Scale: </h4>
          <Button className={classes.button} variant="contained" onClick={handleTimeScaleClick}>
            Create
          </Button>
        </div>
        {!isSiteIdValid(siteId) && <span className={classes.error}>valid site id is required</span>}
      </div>
    </div>
  );
};
export default CloudConfiguration;
