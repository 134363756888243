import { TextEnum } from "../TextStyles";

const ContactSupportErrorMessage = () => {
  return (
    <span className={TextEnum.h5} style={{ lineHeight: "14px" }}>
      please contact us at:
      <a style={{ color: "var(--primary-color)" }} href="mailto:support@solargik.com">
        {" "}
        support@solargik.com
      </a>
    </span>
  );
};
export default ContactSupportErrorMessage;
