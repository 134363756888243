import axios from "axios";
import APP_CONFIG from "../app/configuration/AppConfig";
import { ISitesFeatureFlagsDictionary } from "./FeatureFlagsModels";

export async function getMultiSiteFeatureFlagsAsync(siteIds: string[]) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/featureFlags/getMultisite`;
  const httpResponse = await axios.post<ISitesFeatureFlagsDictionary>(url, siteIds);
  return httpResponse.data;
}

export async function updateSiteFeatureFlagsAsync(
  siteId: string,
  featureFlags: { [featureFlag: string]: boolean }
) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/featureFlags/site/${siteId}`;
  await axios.post(url, featureFlags);
}
