import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISitesFeatureFlagsDictionary, normalizeFeatureFlags } from "./FeatureFlagsModels";
import { getMultiSiteFeatureFlagsAsync } from "./FeatureFlagsApi";
import { UnknownError } from "../../common/UnknownError";

interface IFeatureFlagsState {
  sitesFeatureFlags: ISitesFeatureFlagsDictionary;
  isLoading: boolean;
  error?: UnknownError;
}

const initialState: IFeatureFlagsState = {
  sitesFeatureFlags: {},
  isLoading: false,
};

const featuresSlice = createSlice({
  name: "sitesFeatureFlags",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchFeatureFlags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
        state.sitesFeatureFlags = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFeatureFlags.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      }),
});

export const fetchFeatureFlags = createAsyncThunk(
  "sitesFeatureFlags/fetch",
  async (siteIds: string[]) => {
    const enabledFeaturesPerSiteResponse = await getMultiSiteFeatureFlagsAsync(siteIds);
    return normalizeFeatureFlags(enabledFeaturesPerSiteResponse);
  }
);

export const featureFlagsReducer = featuresSlice.reducer;
