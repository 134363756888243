import { FC } from "react";

import AddingNewUserRecord from "./AddingNewUserRecord";
import Dialog from "../../SolarGikLib/dialogs/Dialog";

interface AddingNewUserRecordPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}
const AddingNewUserRecordPopUp: FC<AddingNewUserRecordPopUpProps> = ({ isOpen, onClose, anchorEl }) => {
  return (
    <Dialog isOpen={isOpen} anchorEl={anchorEl} handleClose={onClose} title="New user record">
      <AddingNewUserRecord onAddedNewRecord={onClose} />
    </Dialog>
    // <Dialog open={isOpen} onClose={onClose} sx={dialogStyling}>
    //   <DialogTitle>
    //     <div className={classes.header}>
    //       <IconButton onClick={onClose}>
    //         <Close />
    //       </IconButton>
    //     </div>
    //   </DialogTitle>
    //   <DialogContent className={classes.content}>
    //     <AddingNewUserRecord onAddedNewRecord={onClose} />
    //   </DialogContent>
    // </Dialog>
  );
};

export default AddingNewUserRecordPopUp;
