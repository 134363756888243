import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./ScheduleCommand.module.css";
import SgTabs from "../../../SolarGikLib/tabs/SgTabs";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { filledBlueButton, outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import {
  addScheduleMaintenance,
  clearConflicts,
  fetchScheduledMaintenance,
  validateOneTimeScheduleData,
  validateRecurringScheduleData,
} from "./scheduleCommandStore";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import scheduleCommandTabs from "./ScheduleCommandTabs";
import {
  IAddScheduledMaintenanceDto,
  IScheduledMaintenanceOneTimeInput,
  IScheduledMaintenanceRecurringInput,
  ScheduledMaintenanceType,
} from "./Models";
import { TrackersCommandType } from "../TrackersModels";
import { AppDispatch, RootState } from "../../app/Store";
import { selectSiteId } from "../../sites/SiteStore";
import ConflictDialog from "./ConflictDialog";

interface IPropsScheduleCommand {
  onClose: () => void;
  isOpen: boolean;
  command: TrackersCommandType;
  reason: string;
  trackerIds: number[];
  elevationZenith: number;
}

const ScheduleCommand = ({
  onClose,
  isOpen,
  reason,
  trackerIds,
  elevationZenith,
}: Readonly<IPropsScheduleCommand>) => {
  const dispatch = useDispatch<AppDispatch>();
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);

  const {
    oneTimeError,
    recurringError,
    recurringScheduleData,
    oneTimeScheduleData,
    addScheduledMaintenanceError,
  } = useSelector((state: RootState) => state.scheduleCommand);

  const siteId = useSelector(selectSiteId);

  const [isLoading, setIsLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const [activeTab, setActiveTab] = useState(ScheduledMaintenanceType.OneTime);

  const OnCloseConflicts = () => {
    dispatch(clearConflicts());
  };

  useEffect(() => {
    if (addScheduledMaintenanceError) {
      setAlertMessage({
        severity: "error",
        text: addScheduledMaintenanceError,
      });
    }
  }, [addScheduledMaintenanceError]);

  useEffect(() => {
    dispatch(fetchScheduledMaintenance(siteId));
  }, [siteId]);

  const onSave = async () => {
    if (!isScheduledDataValid()) {
      return;
    }

    setIsLoading(true);
    try {
      const scheduleData =
        activeTab === ScheduledMaintenanceType.OneTime
          ? mapOneTimeInputToScheduledMaintenance(oneTimeScheduleData)
          : mapRecurringInputToScheduledMaintenance(recurringScheduleData);

      await dispatch(
        addScheduleMaintenance({
          ianaTimeZone: siteTimezone,
          scheduledData: scheduleData,
        })
      ).unwrap();

      setAlertMessage({
        severity: "success",
        text: "Scheduled maintenance added successfully.",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const mapOneTimeInputToScheduledMaintenance = (
    oneTimeData: IScheduledMaintenanceOneTimeInput
  ): IAddScheduledMaintenanceDto => {
    return {
      siteId: siteId,
      trackerIds: trackerIds,
      targetElevation: elevationZenith,
      startTimeUtc: oneTimeData.startDateTimeUtc,
      endTimeUtc: oneTimeData.endDateTimeUtc,
      reason: reason,
      recurrence: null,
    };
  };

  const mapRecurringInputToScheduledMaintenance = (
    recurringData: IScheduledMaintenanceRecurringInput
  ): IAddScheduledMaintenanceDto => {
    return {
      siteId: siteId,
      trackerIds: trackerIds,
      targetElevation: elevationZenith,
      startTimeUtc: recurringData.fromDateUtc,
      endTimeUtc: recurringData.untilDateUtc,
      reason: reason,
      recurrence: {
        repeatEveryWeek: recurringData.repeatEveryWeek,
        daysInWeek: recurringData.selectedDays.reduce((acc, day: number) => acc | (1 << day), 0),
        startTime: recurringData.startTime,
        endTime: recurringData.endTime,
      },
    };
  };

  const isScheduledDataValid = () => {
    if (activeTab === ScheduledMaintenanceType.OneTime) {
      dispatch(validateOneTimeScheduleData(oneTimeScheduleData));
      return !oneTimeError;
    } else {
      dispatch(validateRecurringScheduleData(recurringScheduleData));
      return !recurringError;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <DialogTitle>
        <span className={`${TextEnum.h4} ${classes["dialog-title"]}`}>Scheduled</span>
      </DialogTitle>
      <DialogContent className={classes["dialog-content"]}>
        <div className={classes["tabs-container"]}>
          <SgTabs
            tabs={scheduleCommandTabs}
            activeTab={activeTab}
            setActiveTab={(key) => setActiveTab(key as ScheduledMaintenanceType)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <SolarGikButton
          onClickFunc={onClose}
          text="Cancel"
          style={{
            ...outlinedWhiteButton,
            width: "110px",
            height: "47px",
          }}
          isDisabled={isLoading}
        />
        <div style={{ position: "relative" }}>
          <SolarGikButton
            text={isLoading ? "" : "Save"}
            type="button"
            onClickFunc={onSave}
            isDisabled={isLoading || !oneTimeScheduleData || !recurringScheduleData}
            style={{
              ...filledBlueButton,
              width: "110px",
              height: "47px",
            }}
          />
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: 30,
                left: 65,
              }}
            />
          )}
        </div>
      </DialogActions>
      <ConflictDialog onClose={OnCloseConflicts} />
    </Dialog>
  );
};

export default ScheduleCommand;
