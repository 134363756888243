import { InstallDesktop, Settings } from "@mui/icons-material";
import { SimpleSgTabs } from "../../../SolarGikLib/tabs/SgTabs";
import { ITab } from "../../../SolarGikLib/tabs/TabsModels";
import CamerasControlDeploy from "./CamerasControlDeploy";
import CamerasParameters from "./CamerasParameters";

const CamerasConfigTab = () => {
  const tabs: ITab[] = [
    {
      label: "Deploy",
      key: "CamerasControlDeploy",
      icon: <InstallDesktop />,
      iconPosition: "start",
      content: <CamerasControlDeploy />,
    },
    {
      label: "Cameras Parameters",
      key: "CamerasParameters",
      icon: <Settings />,
      iconPosition: "start",
      content: <CamerasParameters />,
    },
  ];

  return <SimpleSgTabs tabs={tabs} />;
};

export default CamerasConfigTab;
