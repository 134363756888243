import { useState } from "react";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import SolarGikDropdown, { MenuItem } from "../../../SolarGikLib/fields/Dropdown";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { RootState } from "../../app/Store";
import { activateWeatherOverride, deactivateWeatherOverride } from "./SafeOverrideApi";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import Alert from "../../../SolarGikLib/alerts/Alert";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { WeatherOverrideResultsCodeDto } from "./SafeOverrideModels";
import classes from "./SafeOverride.module.css";

const SafeOverride = () => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { inOverrideSafeMode } = useSelector((state: RootState) => state.safeOverride);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const activateSafeMode = async (safeModeEndDate: Date) => {
    const confirmActivateSafeMode = confirm("Are you sure you want to activate the weather override?");
    if (!confirmActivateSafeMode) {
      return;
    }

    const result = await activateWeatherOverride(siteId, safeModeEndDate.getTime());
    if (result == WeatherOverrideResultsCodeDto.Success) {
      setAlertMessage({
        text: "Weather Override activated, can take up to 2 minutes",
        severity: "success",
      });
    } else {
      setAlertMessage({
        text: "Weather Override could not be activated",
        severity: "error",
      });
    }
  };

  const deactivateSafeMode = async () => {
    const result = await deactivateWeatherOverride(siteId);
    if (result) {
      setAlertMessage({
        text: "Weather Override deactivated, can take up to 2 minutes",
        severity: "success",
      });
    } else {
      setAlertMessage({
        text: "Weather Override could not be deactivated",
        severity: "error",
      });
    }
  };

  const today = new Date();
  const dropDownMenuItems: MenuItem[] = [
    {
      label: "1 Day",
      onClick: () => {
        activateSafeMode(addDays(today, 1));
      },
    },
    {
      label: "2 Days",
      onClick: () => {
        activateSafeMode(addDays(today, 2));
      },
    },
    {
      label: "3 Days",
      onClick: () => {
        activateSafeMode(addDays(today, 3));
      },
    },
  ];

  const ArrowDown = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);
  return (
    <div className={`${classes.column} ${TextEnum.h3}`}>
      <Alert message={alertMessage} setMessage={setAlertMessage}></Alert>
      {inOverrideSafeMode && (
        <SolarGikButton
          onClickFunc={deactivateSafeMode}
          text="Turn off override safe mode"
          style={narrowBlueButton}
        />
      )}
      {!inOverrideSafeMode && (
        <div className={classes.dropdown}>
          {"Override safe mode"}
          <SolarGikDropdown icon={ArrowDown} title="Override on for" menuItems={dropDownMenuItems} />
        </div>
      )}
    </div>
  );
};

export default SafeOverride;
