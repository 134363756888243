import { FC } from "react";
import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, LoadersIcons } from "../../SolarGikLib/icons/IconsModels";
import classes from "./ChartCardSkeleton.module.css";
import { useLoaderTimeout } from "../../common/Hooks/useLoaderTimeout";

interface IChartCardSkeletonProps {
  children?: React.ReactNode;
  isDataExist: boolean;
}

const ChartCardSkeletonIcon = getIcons(LoadersIcons.ChartCardSkeleton, IconCategory.Loaders);

const ChartCardSkeleton: FC<IChartCardSkeletonProps> = ({ isDataExist, children }) => {
  const isSkeletonVisible = useLoaderTimeout(isDataExist);

  return <>{isSkeletonVisible ? <ChartCardSkeletonIcon className={classes["skeleton"]} /> : children}</>;
};

export default ChartCardSkeleton;
