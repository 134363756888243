type OnErrorCallback = (message: string) => void;

const useAsyncOperationWithErrorHandling = (onError: OnErrorCallback) => {
  return async <T>(asyncFunc: () => Promise<T>, errorMessage: string): Promise<T> => {
    try {
      return await asyncFunc();
    } catch (error) {
      onError(errorMessage);
      throw new Error(errorMessage);
    }
  };
};

export default useAsyncOperationWithErrorHandling;
