import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { ModuleRuntimeStatus, SiteAppModule } from "./AppConfigModels";

export async function getIotDevicesIdList() {
  const url = `${APP_CONFIG.serviceUrls.siteGatewayUrlPrefix}/site/GetIotHubDevices`;
  const httpResponse = await axios.get<string[]>(url);
  return httpResponse.data;
}

export async function ensureSiteModuleTypeExists(
  siteId: string,
  siteApp: SiteAppModule,
  deviceId: string
) {
  const url = `${APP_CONFIG.serviceUrls.siteGatewayUrlPrefix}/site/${siteId}/${deviceId}/module/${siteApp}`;
  await axios.post(url);
}

export async function updateMcsToUseDas(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix}/UpdateSiteControlConfigToUseDas/${siteId}`;
  await axios.put(url);
}

export async function updateMcsToUseTagsHarvester(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix}/UpdateSiteControlConfigToUseTagsHarvester/${siteId}`;
  await axios.put(url);
}

export async function generateTagsHarvesterConfig(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.siteAppsConfigUrlPrefix}/GenerateTagsHarvesterConfig/${siteId}`;
  await axios.post(url);
}

export async function addOrUpdateTagInIotDevice(deviceId: string, tagName: string, tagValue: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/iotHubDeployments/devices/${deviceId}/tags/${tagName}?tagValue=${encodeURI(tagValue)}`;
  await axios.post(url);
}

export async function getModuleStatusOnDevice(siteId: string, deviceId: string, siteApp: SiteAppModule) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/iotHubDeployments/sites/${siteId}/${deviceId}/${siteApp}/runtimeStatus`;
  const httpResponse = await axios.get<ModuleRuntimeStatus>(url);
  return httpResponse.data;
}

export async function getDeploymentVersions(tagName: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/iotHubDeployments/versions/${tagName}`;
  return (await axios.get<string[]>(url)).data;
}
