import { GridRenderCellParams } from "@mui/x-data-grid";

import { useDispatch } from "react-redux";
import { convertStatusValueToIcon, getStatusEnum } from "./SystemStatusCellIconUtils";
import DataPointTooltip from "../../data_point/wrapper/DataPointTooltip";
import { AppDispatch } from "../../app/Store";
import { fetchActiveIssues } from "../../app/store/FaultsStore";
import { useSystemStatusDescription } from "../../system_status/useSystemStatusDescription";
import TagsNames from "../../data_point/TagsNames";

const SystemStatusCellItem: React.FC<GridRenderCellParams> = (params) => {
  const dispatch = useDispatch<AppDispatch>();
  const status = getStatusEnum(params.value);
  const Icon = convertStatusValueToIcon(status);
  const siteId = params.row.siteId;
  const descriptions = useSystemStatusDescription(siteId);
  const descriptionOverride =
    params.field == TagsNames.AGGR_INVERTERS_STATUS
      ? descriptions.invertersIssusDescription
      : descriptions.trackersIssuesDescription;
  return (
    <DataPointTooltip
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={params.field}
      siteId={siteId}
      onOpen={() => dispatch(fetchActiveIssues(siteId))}
      descriptionOverride={descriptionOverride}
    >
      <Icon />
    </DataPointTooltip>
  );
};
export default SystemStatusCellItem;
