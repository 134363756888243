import { useDispatch, useSelector } from "react-redux";
import SgTimePicker from "../../../SolarGikLib/datePicker/SgTimePicker";
import Selector from "../../../SolarGikLib/fields/Selector";
import { AppDispatch, RootState } from "../../app/Store";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { DayOfWeek, dayOfWeekToStringMap, dayOfWeekValues } from "./Models";
import SgDatePicker from "../../../SolarGikLib/datetimepicker/SgDatePicker";
import classes from "./RecurringSchedule.module.css";
import { setRecurringScheduleData } from "./scheduleCommandStore";

const RecurringSchedule = () => {
  const recurringScheduleData = useSelector(
    (state: RootState) => state.scheduleCommand.recurringScheduleData
  );

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: RootState) => state.scheduleCommand.recurringError);

  return (
    <div className={classes["form-container"]}>
      <div className={classes.row}>
        <div className={classes.column}>
          <SgDatePicker
            disableFuture={false}
            disablePast={true}
            label="From"
            utcValue={new Date(recurringScheduleData.fromDateUtc)}
            setUtcValue={(value) => {
              if (value) {
                dispatch(
                  setRecurringScheduleData({
                    ...recurringScheduleData,
                    fromDateUtc: value.getTime(),
                  })
                );
              }
            }}
          />
        </div>
        <div className={classes.column}>
          <SgDatePicker
            disablePast={true}
            disableFuture={false}
            label="Until"
            utcValue={new Date(recurringScheduleData.untilDateUtc)}
            setUtcValue={(value) => {
              if (value) {
                dispatch(
                  setRecurringScheduleData({
                    ...recurringScheduleData,
                    untilDateUtc: value.getTime(),
                  })
                );
              }
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={`${classes.column} ${classes.center} ${TextEnum.h5}`}>
          <Selector<number>
            textStyle={TextEnum.h5}
            title="Repeat every (weeks)"
            values={[1, 2, 3, 4]}
            multiSelect={false}
            value={[recurringScheduleData.repeatEveryWeek]}
            onChange={(value) =>
              dispatch(
                setRecurringScheduleData({
                  ...recurringScheduleData,
                  repeatEveryWeek: value[0],
                })
              )
            }
          />
        </div>
        <div className={classes.column}>
          <Selector<number>
            textStyle={TextEnum.h5}
            title="On Days"
            values={dayOfWeekValues}
            multiSelect={true}
            value={recurringScheduleData.selectedDays}
            onChange={(value) =>
              dispatch(
                setRecurringScheduleData({
                  ...recurringScheduleData,
                  selectedDays: value,
                })
              )
            }
            convertValueToString={(value) => dayOfWeekToStringMap[value as DayOfWeek]}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.column}>
          <SgTimePicker
            label="Between (start)"
            value={recurringScheduleData.startTime}
            onChange={(value) => {
              if (value) {
                dispatch(
                  setRecurringScheduleData({
                    ...recurringScheduleData,
                    startTime: value,
                  })
                );
              }
            }}
          />
        </div>
        <div className={classes.column}>
          <SgTimePicker
            label="To (end)"
            value={recurringScheduleData.endTime}
            onChange={(value) => {
              if (value) {
                dispatch(
                  setRecurringScheduleData({
                    ...recurringScheduleData,
                    endTime: value,
                  })
                );
              }
            }}
          />
        </div>
      </div>
      {error && <div className={classes["error-message"]}>{error}</div>}
    </div>
  );
};

export default RecurringSchedule;
