import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { resetSiteSpecificData } from "../app/store/GlobalActions";
import * as Api from "./camerasApi";
import { ICameraId } from "./camerasModels";
import { LoadingState } from "../app/LoadingState";

export const getKey = (cameraId: ICameraId) => `${cameraId.agentId}-${cameraId.id}`;

interface ICamerasStoreState {
  healthCheck: {
    [key: string]: {
      commandState: LoadingState;
      error?: string;
    };
  };
  snapshots: {
    [key: string]: {
      image?: string;
      sentAt?: Date;
      commandState: LoadingState;
    };
  };
  movements: { [key: string]: { CommandState: LoadingState } };
}

export const defaultProperties: ICamerasStoreState = {
  healthCheck: {},
  snapshots: {},
  movements: {},
};

export const camerasSlice = createSlice({
  name: "cameras",
  initialState: defaultProperties,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetSiteSpecificData, () => {
        return defaultProperties;
      })
      .addCase(healthCheck.pending, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        state.healthCheck[cameraId] = {
          commandState: LoadingState.Pending,
          error: action.meta.arg.isInitial ? undefined : state.healthCheck[cameraId]?.error,
        };
      })
      .addCase(healthCheck.fulfilled, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        state.healthCheck[cameraId] = {
          commandState: action.payload.error == null ? LoadingState.Complete : LoadingState.Error,
          error: action.payload.error,
        };
      })
      .addCase(healthCheck.rejected, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        const errorMessage =
          action.error.name === "AbortError" ? "Health check timed-out" : "Server error";
        state.healthCheck[cameraId] = {
          commandState: LoadingState.Error,
          error: errorMessage,
        };
      })
      .addCase(fetchSnapshot.pending, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        let cameraSnapshot = state.snapshots[cameraId];
        if (!cameraSnapshot || action.meta.arg.initial) {
          cameraSnapshot = state.snapshots[cameraId] = {
            commandState: LoadingState.Pending,
          };
        }
        cameraSnapshot.commandState = LoadingState.Pending;
        cameraSnapshot.sentAt = new Date();
      })
      .addCase(fetchSnapshot.fulfilled, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        const cameraSnapshot = state.snapshots[cameraId];
        if (cameraSnapshot) {
          cameraSnapshot.image = action.payload;
          cameraSnapshot.commandState = LoadingState.Complete;
        }
      })
      .addCase(fetchSnapshot.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        const cameraId = getKey(action.meta.arg.cameraId);
        const cameraSnapshot = state.snapshots[cameraId];
        if (cameraSnapshot) {
          cameraSnapshot.commandState = LoadingState.Error;
        }
      })
      .addCase(moveCamera.pending, (state, action) => {
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Pending,
        };
      })
      .addCase(moveCamera.fulfilled, (state, action) => {
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Complete,
        };
      })
      .addCase(moveCamera.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Error,
        };
      });
  },
});

export const camerasReducer = camerasSlice.reducer;

export const fetchSnapshot = createAsyncThunk(
  `${camerasSlice.name}/fetchSnapshot`,
  (args: { siteId: string; cameraId: ICameraId; initial: boolean }, { signal }) =>
    Api.fetchSnapshots(args.siteId, args.cameraId, signal)
);

export const healthCheck = createAsyncThunk(
  `${camerasSlice.name}/healthCheck`,
  (args: { siteId: string; cameraId: ICameraId; isInitial: boolean }, { signal }) =>
    Api.healthCheck(args.siteId, args.cameraId, signal)
);

export const moveCamera = createAsyncThunk(
  `${camerasSlice.name}/move`,
  (
    args: { siteId: string; cameraId: ICameraId; x: number; y: number; milliseconds: number },
    { signal }
  ) => Api.moveCamera(args.siteId, args.cameraId, args.x, args.y, args.milliseconds, signal)
);
