import { FC } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import classes from "./EmergencyToast.module.css";
import SolarGikButton from "../../../SolarGikLib/Button";
import { emergencyToastButtonStyle, emergencyToastStyle } from "./EmergencyStyle";
import { EmergencyCommand } from "./EmergencyModel";
import SgBackdrop from "../../../SolarGikLib/backdrop/SgBackdrop";

interface EmergencyToastProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  isSuccessful: boolean;
  command: EmergencyCommand;
}

const EmergencyToast: FC<EmergencyToastProps> = ({ open, setOpen, isSuccessful, command }) => {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const title = isSuccessful ? `${command.toString()}\nSent Successfully!` : "Apologies,";
  const message = isSuccessful ? "This might take a moment" : "Failed Sending to Safe Mode";

  const emergencyToastAction = (
    <div className={classes["toast-container"]}>
      <div
        className={`${classes["toast-title"]} ${isSuccessful ? classes["success-toast"] : classes["fail-toast"]}`}
      >
        {title}
      </div>
      <div className={classes["toast-time-message"]}>{message}</div>
      <div className={classes["toast-btn"]}>
        <SolarGikButton text="OK" onClickFunc={handleClose} style={emergencyToastButtonStyle} />
      </div>
    </div>
  );

  const snackbar = (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={null}
      onClose={handleClose}
      message=""
      action={emergencyToastAction}
      sx={emergencyToastStyle}
    />
  );

  return <SgBackdrop open={open} component={snackbar} />;
};

export default EmergencyToast;
