import React from "react";

import Skeleton from "@mui/material/Skeleton";

interface ITableCellLoadingSkeletonWrapperProps {
  children: React.ReactNode;
  variant?: "text" | "circular";
  loading: boolean;
}

export const TableCellLoadingSkeletonWrapper: React.FC<ITableCellLoadingSkeletonWrapperProps> = ({
  children,
  variant = "text",
  loading,
}) => {
  if (loading) {
    const width = variant === "circular" ? 15 : "40%";
    return <Skeleton variant={variant} width={width} height={15} />;
  }

  return <>{children}</>;
};
