import { FC } from "react";
import { IconButton, SxProps } from "@mui/material";
import { AxiosResponse } from "axios";
import { COMMUNICATION_CSV_HEADER, CSV_FILE_SUFFIX } from "../../../common/ConstantValues";
import { IButtonVariant } from "../../../common/Mui/StyledButton";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import getIcons from "../../../SolarGikLib/icons/Icons";

import { FileCommandsIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { useDropZone } from "../../html_elements/DropZone";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import { UnknownError } from "../../../common/UnknownError";

interface UploadNewPlanProps {
  setErrorMessageFunc: (message: AlertMessage) => void;
  preUploadFunc?: () => void;
  postUploadFunc?: () => void;
  onFailFunc?: (error: UnknownError) => void;
  siteId: string;
  uploadApiFuncAsync: (siteId: string, file: Uint8Array) => Promise<AxiosResponse>;
  buttonVariant: IButtonVariant;
  isDisabled: boolean;
  isTextLessButton?: boolean;
  overrideStyle?: SxProps;
}

const Upload: FC<UploadNewPlanProps> = ({
  preUploadFunc = null,
  setErrorMessageFunc,
  postUploadFunc = null,
  onFailFunc,
  siteId,
  uploadApiFuncAsync,
  isDisabled,
  isTextLessButton = false,
  overrideStyle,
}) => {
  const { getRootProps, getInputProps, fileContent } = useDropZone(
    () => uploadHandler(),
    COMMUNICATION_CSV_HEADER,
    CSV_FILE_SUFFIX,
    isDisabled
  );
  const uploadHandler = async () => {
    if (fileContent.current) {
      try {
        if (preUploadFunc != null) {
          preUploadFunc();
        }
        const res = await uploadApiFuncAsync(siteId, new Uint8Array(fileContent.current));
        if (res.status == 200) {
          if (postUploadFunc != null) {
            postUploadFunc();
          }
          setErrorMessageFunc({
            text: "Plan uploaded successfully",
            severity: "success",
          });
          fileContent.current = undefined;
        }
      } catch (error: UnknownError) {
        if (onFailFunc != null) {
          onFailFunc(error);
        }

        fileContent.current = undefined;
      }
    }
  };

  const UploadIcon = getIcons(FileCommandsIcons.WhiteUpload, IconCategory.FileCommands);

  const TextLessUploadIcon = getIcons(FileCommandsIcons.Upload, IconCategory.FileCommands);

  const TextLessUploadIconDisabled = getIcons(
    FileCommandsIcons.UploadDisabled,
    IconCategory.FileCommands
  );

  if (isTextLessButton) {
    return (
      <span {...getRootProps()}>
        <input {...getInputProps()} />
        <DarkTooltip title="Upload plan">
          <IconButton onClick={uploadHandler} disabled={isDisabled} size="small">
            {isDisabled ? <TextLessUploadIconDisabled /> : <TextLessUploadIcon />}
          </IconButton>
        </DarkTooltip>
      </span>
    );
  }

  return (
    <div style={{ width: "100%" }} {...getRootProps()}>
      <input {...getInputProps()} />
      <SolarGikButton
        icon={<UploadIcon />}
        onClickFunc={uploadHandler}
        isDisabled={isDisabled}
        style={overrideStyle ? overrideStyle : narrowBlueButton}
        text={"Upload Plan"}
      />
    </div>
  );
};
export default Upload;
