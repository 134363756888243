import axios from "axios";

import { ISitesMetadataDictionary } from "./SiteModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export async function getSitesMetadataAsync(siteIds: string[]) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/SiteMetadata/getSitesMetadata`;
  const httpResponse = await axios.post<ISitesMetadataDictionary>(url, siteIds);
  return httpResponse.data;
}
