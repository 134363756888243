import { FC } from "react";

import { useSelector } from "react-redux";

import getIcons from "../../SolarGikLib/icons/Icons";
import { AuxiliariesStatusIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import { selectSiteTag } from "../app/store/MultisiteTagsStore";

enum StatusMarkEnum {
  Undefined = 0,
  Ok = 1,
  Warning = 2,
  Error = 3,
}

interface StatusMarkIconProps {
  tagName: string;
  siteId: string;
}

const StatusMarkIcon: FC<StatusMarkIconProps> = ({ tagName, siteId }) => {
  const status = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName).value);
  const iconColor = getStatusMarkColor(status);
  const MarkIcon = getIcons(AuxiliariesStatusIcons.StatusMark, IconCategory.Status);
  return <MarkIcon stroke={iconColor} />;
};
export default StatusMarkIcon;

const getStatusMarkColor = (status: number) => {
  switch (status) {
    case StatusMarkEnum.Ok:
      return "var(--ok-color)";
    case StatusMarkEnum.Warning:
      return "var(--warning-color)";
    case StatusMarkEnum.Error:
      return "var(--error-color)";
    case StatusMarkEnum.Undefined:
      return "var(--undefined-color)";
    default:
      return "var(--undefined-color)";
  }
};
