import { FC } from "react";

import classes from "./List.module.css";
import { IListItem } from "./ListModels";
import getIcons from "../icons/Icons";
import { FieldIcons, IconCategory } from "../icons/IconsModels";
import { TextEnum } from "../TextStyles";

interface IListProps {
  list: IListItem[];
  selectedItem: IListItem | undefined;
  setSelectedItem: (item: IListItem) => void;
}

export const List: FC<IListProps> = ({ list, selectedItem, setSelectedItem }) => {
  const SelectedIcon = getIcons(FieldIcons.ListItemSelected, IconCategory.Fields);

  return (
    <ul className={classes.list}>
      {list.map((item) => (
        <li
          key={item.id}
          className={classes["list-item"]}
          onClick={() => setSelectedItem(item)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSelectedItem(item);
            }
          }}
          tabIndex={0}
        >
          {<item.icon />}
          <span className={TextEnum.h5}>{item.title}</span>
          <span className={classes["item-selected"]}>
            {item.id === selectedItem?.id && <SelectedIcon />}
          </span>
        </li>
      ))}
    </ul>
  );
};
