import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import getIcons from "../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { iconButtonStyle } from "../../SolarGikLib/styles/ButtonsStyle";
import { resetSiteSpecificData } from "../app/store/GlobalActions";

const InteractiveSolargikLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SolarGikLogoIcon = getIcons(GeneralIcons.SolarGikLogo, IconCategory.General);
  return (
    <IconButton
      onClick={() => {
        dispatch(resetSiteSpecificData());
        navigate(`/`);
      }}
      style={iconButtonStyle}
    >
      <SolarGikLogoIcon />
    </IconButton>
  );
};

export default InteractiveSolargikLogo;
