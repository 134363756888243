export const inputStyle = {
  "& .MuiInputLabel-root": {
    color: "var(--blue-color-primary)",
  },
  "& .MuiInputBase-root": {
    color: "var(--blue-color-primary)",
    border: "none",
    "&.Mui-focused .MuiInputAdornment-root": {
      color: "var(--input-adornment-focused-color)",
    },
  },
  "& .MuiInputBase-input": {
    color: "var(--blue-color-primary)",
  },
  " & .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--blue-color-primary)",
    borderRadius: "var(--border-radius-button)",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};
