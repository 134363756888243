import { AlertTransition, IAggregatedAlertTransition, ISingleDeviceAlertTransition } from "../../DTOs";
import { AlertUnion } from "../../Models";
import { NormalizedAggregatedAlertTransition, NormalizedTransition } from "./Models";

export function normalizeTransitions(
  alert: AlertUnion,
  transitions: AlertTransition[]
): NormalizedTransition[] {
  if (alert.kind === "single-device") {
    return (transitions as ISingleDeviceAlertTransition[]).map((transition, index) => ({
      ...transition,
      id: index,
    }));
  }
  return (transitions as IAggregatedAlertTransition[])
    .sort((a, b) => a.transitionTime - b.transitionTime)
    .reduce((accumulator, transition, index) => {
      // Populate allDevices field on each transition with all devices that were present in the alert at the time of the transition
      // by keeping track of all devices that were added or removed in previous transitions.
      const allDevicesSet = new Set<number>(index === 0 ? [] : accumulator[index - 1].allDevices);
      for (const deviceId of transition.removedDeviceIds) {
        allDevicesSet.delete(deviceId);
      }
      for (const deviceId of transition.addedDeviceIds) {
        allDevicesSet.add(deviceId);
      }
      accumulator.push({
        ...transition,
        id: index,
        allDevices: Array.from(allDevicesSet),
      });
      return accumulator;
    }, [] as NormalizedAggregatedAlertTransition[]);
}
