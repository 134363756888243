import axios, { AxiosResponse } from "axios";

import { IAgriDailyPlanDto } from "./AgriModels";
import APP_CONFIG from "../configuration/AppConfig";

export async function uploadNewPlanAsync(siteId: string, fileData: Uint8Array): Promise<AxiosResponse> {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix}/ShadingPlan/${siteId}`;
  const params = {
    file: fileData,
  };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await axios.post<AxiosResponse>(url, params, headers);
  return res;
}

export async function deleteAgriPlanAsync(siteId: string): Promise<boolean> {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix}"/ShadingPlan"/${siteId}`;
  const res = await axios.delete<void>(url);
  return res.status == 200;
}

export async function getDailyPlanAsync(siteId: string): Promise<IAgriDailyPlanDto[]> {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix}/ShadingPlan/CurrentDay/${siteId}`;
  const res = await axios.get<IAgriDailyPlanDto[]>(url);
  return res.data;
}

export async function getOverallPlanAsFileAsync(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix}/ShadingPlan/OverallPlan/${siteId}`;
  const res = await axios.get<string>(url);
  return res.data;
}

export async function getAgriPlanTemplateAsync() {
  const url = `${APP_CONFIG.serviceUrls.dualUsageUrlPrefix}/ShadingPlan/GetTemplateFile`;
  const res = await axios.get<string>(url);
  return res.data;
}
