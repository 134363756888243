import React from "react";

import Skeleton from "@mui/material/Skeleton";

import { useLoaderTimeout } from "../Hooks/useLoaderTimeout";

interface ITableCellSkeletonWrapperProps {
  isDataExists: boolean;
  children: React.ReactNode;
  variant?: "text" | "circular";
}

export const TableCellSkeletonWrapper: React.FC<ITableCellSkeletonWrapperProps> = ({
  isDataExists,
  children,
  variant = "text",
}) => {
  const isLoading = useLoaderTimeout(isDataExists);

  if (isLoading) {
    return <Skeleton variant={variant} width="40%" height={15} />;
  }

  return <>{children}</>;
};
