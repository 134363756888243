import { FC, useMemo } from "react";

import { Skeleton } from "@mui/material";
import { Collapse } from "antd";

import { useSelector } from "react-redux";
import classes from "./IssuesSection.module.css";
import { StyledPanel } from "./issuesStyle";
import { getIssusTableColumns } from "./IssuesTableColumns";
import Table from "../../../common/table/Table";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { ISingleDeviceIssue, UrgencyLevel } from "../DTOs";
import { ComponentCategory } from "../Models";

import { RootState } from "../../app/Store";
import { selectSiteDateTimeFormatter, selectSiteTrackersMap } from "../../sites/SiteStore";
import { useIs150PercentScreen } from "../../../common/WindowUtils";

interface IIssuesSectionProps {
  expanded: boolean;
  category: ComponentCategory;
  issues: ISingleDeviceIssue[];
  isLoading: boolean;
}

interface ISingleDeviceIssueWithId extends ISingleDeviceIssue {
  id: number;
}

const IssuesSection: FC<IIssuesSectionProps> = ({ expanded, category, issues, isLoading }) => {
  const title = ComponentCategory[category];
  type ValidUrgencyLevels = Exclude<UrgencyLevel, UrgencyLevel.Closed>;
  const counts = useMemo(
    () =>
      issues.reduce(
        (acc, issue) => {
          acc[issue.urgencyLevel as ValidUrgencyLevels] += 1;
          return acc;
        },
        {
          [UrgencyLevel.High]: 0,
          [UrgencyLevel.Medium]: 0,
          [UrgencyLevel.Low]: 0,
        }
      ),
    [issues]
  );
  const issuesMetadata = useSelector(
    (state: RootState) => state.faults.multiSitesFaults.metadata.issues
  );
  const trackerNamesMap = useSelector(selectSiteTrackersMap);
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);
  const isSmallScreen = useIs150PercentScreen();
  const issusTableColumns = useMemo(
    () => getIssusTableColumns(issuesMetadata, trackerNamesMap, dtFormatter, isSmallScreen),
    [issuesMetadata, trackerNamesMap, dtFormatter, isSmallScreen]
  );
  const issuesIndexed = useMemo(
    () => issues.map((issue, index) => ({ ...issue, id: index }) as ISingleDeviceIssueWithId),
    [issues]
  );

  const header =
    issues.length !== 0 ? (
      <div className={`${classes["panel-header"]} ${TextEnum.h4}`}>
        <div>
          {title}
          <div className={`${classes["title-info"]} ${TextEnum.h5}`}>{issues.length + " Issues"}</div>
        </div>
        <div className={classes.row}>
          <div className={classes["title-info"]}>{counts[UrgencyLevel.High] + " High"}</div>
          <div className={classes["title-info"]}>{counts[UrgencyLevel.Medium] + " Medium"}</div>
          <div className={classes["title-info"]}>{counts[UrgencyLevel.Low] + " Low"}</div>
        </div>
      </div>
    ) : (
      <div className={`${classes["panel-header"]} ${TextEnum.h4}`}>
        {isLoading ? (
          <Skeleton variant="rounded" width="100%" height={50} />
        ) : (
          <>
            <div>
              {title}
              <div className={`${classes["title-info"]} ${TextEnum.h5}`}>
                {issues.length + " Issues"}
              </div>
            </div>
            <div className={`${classes["no-issues-info"]} ${TextEnum.h2}`}>
              <div>
                <span className={TextEnum.h3}> Congratulations! </span>
                {category === ComponentCategory.System ? (
                  <span>{title} has no issues!</span>
                ) : (
                  <span>{title} have no issues!</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  return (
    <div className={classes["issues-section"]}>
      <Collapse className={classes.collapse} defaultActiveKey={expanded ? title : ""}>
        <StyledPanel className={classes.panel} key={title} header={header}>
          {issuesIndexed.length !== 0 && (
            <Table
              rows={issuesIndexed}
              columns={issusTableColumns}
              hideFooter
              hideToolbar
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "urgencyLevel",
                      sort: "desc",
                    },
                  ],
                },
              }}
            />
          )}
        </StyledPanel>
      </Collapse>
    </div>
  );
};
export default IssuesSection;
