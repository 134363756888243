import { FC } from "react";

import { LinearProgress, SxProps, Theme } from "@mui/material";
import {
  DataGridProps,
  GridColDef,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

import { TableCellLoadingSkeletonWrapper } from "../../SolarGikLib/loaders/TableCellLoadingSkeletonWrapper";
import { TABLE_NO_DATA_MESSAGE } from "../ConstantValues";
import { StyledDataGridTable, GridToolbarStyled } from "../Mui/StyledDataGridTable";
import { createSkeletonRows } from "./SkeletonUtils";

export interface ExternalRowsSelectionProps {
  selectedRows: GridInputRowSelectionModel;
  setSelectedRows: (selectedRows: GridRowSelectionModel) => void;
}

interface ITableProps extends Omit<DataGridProps, "onRowSelectionModelChange"> {
  loading?: boolean;
  numberOfSkeletonRows?: number;
  rows: GridValidRowModel[];
  columns: GridColDef[];
  hideToolbar?: boolean;
  hiddenColumns?: string[];
  hideHeaders?: boolean;
  hideBorder?: boolean;
  isHideGridColumns?: boolean;
  noRowsMessage?: string;
  rowSelection?: boolean;
  externalRowsSelectionProps?: ExternalRowsSelectionProps;
  customUI?: SxProps<Theme>;
  customTableComponent?: typeof StyledDataGridTable;
}

const Table: FC<ITableProps> = ({
  loading = false,
  numberOfSkeletonRows = 6,
  experimentalFeatures,
  rows,
  columns,
  paginationMode,
  paginationModel,
  onCellClick,
  initialState,
  hiddenColumns,
  hideFooter = false,
  hideToolbar = false,
  hideBorder = false,
  hideHeaders = false,
  isHideGridColumns = false,
  hideFooterPagination = true,
  pageSizeOptions = [10],
  noRowsMessage = TABLE_NO_DATA_MESSAGE,
  rowSelection = true,
  customUI,
  externalRowsSelectionProps,
  getRowClassName = (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"),
  customTableComponent: StyledTable = StyledDataGridTable,
  ...otherProps
}) => {
  const isExternallyControlled = externalRowsSelectionProps != undefined;
  const filteredColumns = hiddenColumns
    ? columns.filter((column) => !hiddenColumns.includes(column.field))
    : columns;
  const rowsToShow = loading ? createSkeletonRows(numberOfSkeletonRows, columns) : rows;
  const tableKey = loading ? "loading" : `data-${rowsToShow.length}`;
  const columnsWithLoading = filteredColumns.map(
    (column) =>
      ({
        ...column,
        renderHeader: loading
          ? () => (
              <TableCellLoadingSkeletonWrapper loading={loading}>
                <span>{column.headerName}</span>
              </TableCellLoadingSkeletonWrapper>
            )
          : column.renderHeader, //This very very important line for multisite table will not work without it!
        renderCell: (params) => (
          <TableCellLoadingSkeletonWrapper loading={loading}>
            {column.renderCell ? column.renderCell(params) : params.value}
          </TableCellLoadingSkeletonWrapper>
        ),
      }) as GridColDef
  );

  return (
    <StyledTable
      key={tableKey}
      onCellClick={onCellClick}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: hideToolbar ? undefined : GridToolbarStyled,
      }}
      experimentalFeatures={experimentalFeatures}
      rows={rowsToShow}
      columns={columnsWithLoading}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      hideFooterPagination={hideFooterPagination}
      pageSizeOptions={pageSizeOptions}
      hideFooter={hideFooter}
      getRowClassName={getRowClassName}
      {...(isExternallyControlled && {
        onRowSelectionModelChange: (newRowSelectionModel: GridRowSelectionModel) => {
          if (!rowSelection) {
            return;
          }
          externalRowsSelectionProps.setSelectedRows(newRowSelectionModel);
        },
        rowSelectionModel: externalRowsSelectionProps.selectedRows,
      })}
      keepNonExistentRowsSelected
      initialState={initialState}
      localeText={{
        toolbarColumns: "",
        toolbarFilters: "",
        toolbarDensity: "",
        toolbarExport: "",
        noRowsLabel: noRowsMessage,
      }}
      sx={{
        ...(onCellClick
          ? {
              "& .MuiDataGrid-cell": {
                cursor: "pointer",
              },
            }
          : {}),
        ...(hideHeaders && {
          "& .MuiDataGrid-columnHeaders": { display: "none" },
        }),
        ...(hideBorder && { "& .MuiDataGrid-main": { border: "none" } }),
        ...(isHideGridColumns && {
          "& .MuiDataGrid-cell": { borderRight: "0px", border: "0px" },
        }),
        ...(customUI && { ...customUI }),
      }}
      rowSelection={rowSelection}
      {...otherProps}
    />
  );
};
export default Table;
