import * as React from "react";
import { useEffect } from "react";

import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import { CSSObject } from "@mui/material";
import { PopoverHorizontalPlacement } from "./PopoverModels";
import classes from "./SgPopover.module.css";
import { popoverStyle } from "./SgPopoverStyle";
import { TextEnum } from "../TextStyles";

interface PopoverPopupStateProps {
  TriggerButton: React.ReactElement;
  children?: React.ReactNode;
  title: string;
  placementHorizontal?: PopoverHorizontalPlacement;
  open: boolean;
  onClose: () => void;
  fullScreen?: boolean;
  sx?: CSSObject;
  tooltipArrowStyle?: CSSObject;
}

const SgPopover: React.FC<PopoverPopupStateProps> = ({
  TriggerButton,
  children,
  title,
  placementHorizontal = "center",
  open,
  onClose,
  fullScreen = false,
  sx,
  tooltipArrowStyle,
}) => {
  return (
    <PopupState variant="popover" popupId="generic-popup-popover">
      {(popupState) => {
        useEffect(() => {
          if (open && !popupState.isOpen) {
            popupState.open();
          } else if (!open && popupState.isOpen) {
            popupState.close();
          }
        }, [open, popupState]);
        const handleOnClose = () => {
          if (popupState.isOpen) {
            popupState.close();
          }
          if (onClose) {
            onClose();
          }
        };
        return (
          <div>
            {React.cloneElement(TriggerButton, bindTrigger(popupState))}
            <Popover
              {...bindPopover(popupState)}
              open={popupState.isOpen}
              onClose={handleOnClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: placementHorizontal,
              }}
              sx={{ popoverStyle, ...sx }}
              slotProps={{
                paper: {
                  ...(fullScreen && {
                    sx: {
                      width: "80vw",
                      height: "70vh",
                      marginTop: "30px",
                      "& .MuiPopover-paper": {
                        width: "100%",
                        height: "100%",
                      },
                    },
                  }),
                },
              }}
            >
              {tooltipArrowStyle && <div style={tooltipArrowStyle as React.CSSProperties}></div>}
              <div
                className={`${fullScreen ? classes["full-screen-title"] : classes["title"]} ${TextEnum.h4}`}
              >
                {title}
              </div>
              <div className={classes[`children-container`]}>{children}</div>
            </Popover>
          </div>
        );
      }}
    </PopupState>
  );
};

export default SgPopover;
