import React, { useState } from "react";
import classes from "./Selector.module.css";
import { TextEnum } from "../TextStyles";

interface ISelectorProps<T> {
  multiSelect?: boolean;
  title: string;
  values: T[];
  value: T[];
  onChange?: (selectedValues: T[]) => void;
  textStyle?: TextEnum;
  convertValueToString?: (value: T) => string;
}

const Selector = <T extends number | string>({
  multiSelect,
  title,
  values,
  value,
  onChange,
  textStyle = TextEnum.h4,
  convertValueToString,
}: ISelectorProps<T>) => {
  const [selectedValues, setSelectedValues] = useState<T[]>(value);

  const handleChange = (value: T) => {
    if (!onChange) {
      return;
    }

    let newSelectedValues: T[];
    if (multiSelect) {
      newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
    } else {
      newSelectedValues = [value];
    }
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  const inputType = multiSelect ? "checkbox" : "radio";

  return (
    <div className={classes.container}>
      <span className={textStyle}>{title}</span>
      <div className={classes.optionsContainer}>
        {values?.map((value, index) => (
          <label
            key={`${value}-${index}`}
            className={`${classes.label} ${!onChange && classes.readonly}`}
          >
            <input
              type={inputType}
              name="repeat-every"
              checked={selectedValues.includes(value)}
              onChange={() => handleChange(value)}
              className={classes.input}
              disabled={!onChange}
            />
            <span
              className={`${classes.optionSpan} ${selectedValues.includes(value) ? classes.selected : classes.unselected}`}
            >
              {convertValueToString ? convertValueToString(value) : value}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Selector;
