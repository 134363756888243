import { FC } from "react";
import { Backdrop } from "@mui/material";
import ReactDOM from "react-dom";

interface SgBackdropProps {
  open: boolean;
  component: JSX.Element;
}

export const BackdropPortalAnchorElementId = "backdrop-portal-anchor";

const SgBackdrop: FC<SgBackdropProps> = ({ open, component }) => {
  const BackdropPortalAnchorElement = document.getElementById(BackdropPortalAnchorElementId);
  if (!BackdropPortalAnchorElement) {
    console.error(`#${BackdropPortalAnchorElementId} div not found.`);
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Backdrop
        open={open}
        style={{
          zIndex: 1200,
          backgroundColor: "transparent",
        }}
      />
      {component}
    </>,
    BackdropPortalAnchorElement
  );
};

export default SgBackdrop;
