import { useSelector } from "react-redux";

import { NavItemsConfig } from "./DrawerConfiguration";
import { RootState } from "../../app/Store";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import { selectSiteId } from "../../sites/SiteStore";

export default function SiteNavigationDrawer() {
  const siteId = useSelector(selectSiteId);
  const currentSiteMetadata = useSelector((state: RootState) => state.multiSitesMetadata.sites[siteId]);
  const currentSiteFeatureFlags = useSelector(
    (state: RootState) => state.featureFlags.sitesFeatureFlags[siteId]
  );
  const userLevel = useSelector((state: RootState) => state.user.userType);
  const filterArgs = {
    siteFeatureFlags: currentSiteFeatureFlags,
    siteMetadata: currentSiteMetadata,
    userAccessType: userLevel,
  };
  const filteredDrawerItems = NavItemsConfig.filter((item) => item.visiblityFilter(filterArgs));
  const editedSiteName = "/" + encodeURI(siteId);
  return <NavigationDrawer navigationItems={filteredDrawerItems} navigationPrefx={editedSiteName} />;
}
