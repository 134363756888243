export enum FeatureFlag {
  SendTrackersToFreeze = "SendTrackersToFreeze",
  SendTrackersToHoming = "SendTrackersToHoming",
  ResetTrackers = "ResetTrackers",
  ForceTrackersElevation = "ForceTrackersElevation",
  TrackersPixelsView = "TrackersPixelsView",
  ScheduledMaintenance = "ScheduledMaintenance",
  CamerasPage = "CamerasPage",
}

export interface ISitesFeatureFlagsDictionary {
  [siteId: string]: FeatureFlag[];
}

export const normalizeFeatureFlags = (
  apiResponse: ISitesFeatureFlagsDictionary
): ISitesFeatureFlagsDictionary => {
  return Object.entries(apiResponse).reduce((normalized, [siteId, flags]) => {
    const validFlags = flags.filter((flag) => Object.values(FeatureFlag).includes(flag));
    normalized[siteId] = validFlags;
    return normalized;
  }, {} as ISitesFeatureFlagsDictionary);
};
