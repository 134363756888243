import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";
import { selectSiteId } from "../../sites/SiteStore";
import { loadSolarSightBinaries } from "./pixelsClientWrapper";

export const PixelInit = () => {
  const siteId = useSelector(selectSiteId);
  const featureFlags = useSelector((state: RootState) => state.featureFlags.sitesFeatureFlags[siteId]);
  if (featureFlags?.includes(FeatureFlag.TrackersPixelsView)) {
    loadSolarSightBinaries();
  }
  return null;
};
