import { FC, MouseEventHandler } from "react";

import Button from "@mui/material/Button";

import { SxProps, Theme } from "@mui/material";
import classes from "./Button.module.css";

interface SolarGikButtonProps {
  icon?: React.ReactNode;
  text?: string;
  style: SxProps<Theme>;
  onClickFunc?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  type?: "button" | "submit" | "reset";
  rootClassName?: string;
}
//This is the button component for the app
//Do not override its css! use the style prop instead
const SolarGikButton: FC<SolarGikButtonProps> = ({
  icon,
  text,
  style,
  onClickFunc,
  isDisabled,
  type,
  rootClassName,
}) => {
  return (
    <Button
      className={classes["rounded-button"]}
      sx={style}
      onClick={onClickFunc}
      endIcon={icon}
      disabled={isDisabled}
      type={type}
      classes={{ root: rootClassName }}
    >
      {text}
    </Button>
  );
};
export default SolarGikButton;
