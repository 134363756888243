import { FC, useState } from "react";
import dayjs from "dayjs";
import classes from "./MultiSitesChartWrapper.module.scss";
import SolarGikStyledChart from "../../SolarGikLib/SolarGikStyledChart";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { DAYJS_YEAR_TO_SECOND_FORMAT } from "../app/DayjsUtils";
import ExportMultiSiteTagsToCsvFile from "../data_point/ExportMultiSiteTagsToCsvFile";
import { IStyledMultiChartProps } from "../data_point/models/TagChartModel";

const MultiSitesChartWrapper: FC<IStyledMultiChartProps> = ({ chartConfig, linesConfig }) => {
  const [chartTitle, setChartTitle] = useState("");
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartTitle(event.target.value);
  };
  const exportParams = {
    linesConfig,
    title: chartTitle || dayjs(new Date()).format(DAYJS_YEAR_TO_SECOND_FORMAT),
  };
  return (
    <div className={classes["chart-box"]}>
      <div className={classes["chart-wrapper"]}>
        <input
          className={`${classes["chart-title"]} ${TextEnum.h5}`}
          type="text"
          id="chartTitle"
          placeholder="Enter title"
          value={chartTitle}
          onChange={handleTitleChange}
        />
        <div className={classes["export-button"]}>
          <ExportMultiSiteTagsToCsvFile trendData={exportParams} />
        </div>
      </div>
      <div className={classes["chart"]}>
        <SolarGikStyledChart chartConfig={chartConfig} linesConfig={linesConfig} />
      </div>
    </div>
  );
};

export default MultiSitesChartWrapper;
