import { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import { ITrendLine, TrendsUserEvents } from "../TrendsModel";
import classes from "./MinMax.module.css";
import { is150PercentScreen } from "../../../common/WindowUtils";
import { trendInputFieldStyling } from "../../../common/Mui/MuiStyling";

const USER_INPUT_TTL = 1000;

interface MinMaxProps {
  line: ITrendLine;
}

const MinMax: FC<MinMaxProps> = ({ line }) => {
  const defaultMin = line.yAxisRangeMin;
  const defaultMax = line.yAxisRangeMax;
  const defaultValue = `${defaultMin}-${defaultMax}`;

  const [oldValue, setOldValue] = useState(defaultValue);
  const [tempValue, setTempValue] = useState(defaultValue);
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const is150Percent = is150PercentScreen();

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (timer) {
      clearTimeout(timer);
    }
    setTempValue(event.target.value);
    setTimer(setTimeout(() => updateValue(tempValue), USER_INPUT_TTL));
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" || event.key === "Tab") {
      updateValue((event.target as HTMLInputElement).value);
      clearTimeout(timer);
    }
    if (event.key === "Escape") {
      clearTimeout(timer);
      setTempValue(oldValue);
      updateValue(oldValue);
    }
  }

  function updateValue(minMaxAsStringInput: string) {
    const inputValues = minMaxAsStringInput.split("-");
    const [newMin, newMax] = inputValues.map(Number);

    if (inputValues.length !== 2 || isNaN(newMin) || isNaN(newMax) || newMin >= newMax) {
      setTempValue(oldValue); //revert the current valkue
      return;
    }

    setOldValue(minMaxAsStringInput);
    const minChangeEvent = new CustomEvent(TrendsUserEvents[TrendsUserEvents.MinChange], {
      detail: { lineId: line.id, value: newMin },
    });
    document.dispatchEvent(minChangeEvent);

    const maxChangeEvent = new CustomEvent(TrendsUserEvents[TrendsUserEvents.MaxChange], {
      detail: { lineId: line.id, value: newMax },
    });
    document.dispatchEvent(maxChangeEvent);
  }

  function outOfFocus(): void {
    clearTimeout(timer);
    updateValue(tempValue);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const inputElement = (
    <TextField
      sx={trendInputFieldStyling}
      id={line.id + "_minmax"}
      label="Set min-max y axis"
      value={tempValue}
      onBlur={outOfFocus}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      inputProps={{
        style: { textAlign: "center" },
      }}
    />
  );

  return (
    <div className={classes["min-max-container"]}>
      {is150Percent ? (
        <DarkTooltip title="min,max for y axis." placement="top">
          {inputElement}
        </DarkTooltip>
      ) : (
        inputElement
      )}
    </div>
  );
};

export default MinMax;
