import axios, { AxiosError } from "axios";

import {
  ITrackerStatus,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackerElevation,
  SendTrackersToFreezeResultCodeEnum,
  ResetTrackersResultCodeEnum,
  HomingDirection,
  SendTrackersToHomingResultCodeEnum,
  ITrackersCommandResultsMap,
  ITrackersCommandResponse,
  ForceTrackersElevationResultCodeEnum,
  McsCommandNotImplementedStatusCode,
} from "./TrackersModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export async function getAllLatestTrackersAsync(siteId: string): Promise<ITrackerStatus[]> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/TrackerData/getTrackersData`;
  const params = { siteId: siteId };
  const httpResponse = await axios.request<ITrackerStatus[]>({
    url: url,
    params: params,
  });
  return httpResponse.data;
}

export async function sendTrackersToMaintenance(
  siteId: string,
  trackersIds: number[],
  targetElevation: TrackerElevation,
  reason: string
) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/maintenance`;
  const params = {
    TargetElevationDegrees: targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
    Reason: reason,
  };
  return await sendCommand(
    trackersIds,
    url,
    params,
    SendTrackersToMaintenanceResultCodeEnum.UnexpectedError
  );
}

export async function releaseTrackersFromMaintenance(siteId: string, trackersIds: number[]) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/release`;
  const params = { TrackersList: trackersIds };
  return await sendCommand(
    trackersIds,
    url,
    params,
    ReleaseTrackersFromMaintenanceResultCodeEnum.UnexpectedError
  );
}

export async function sendTrackersToFreeze(siteId: string, trackersIds: number[], reason: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/freeze`;
  const params = { TrackersList: trackersIds, Reason: reason };
  return await sendCommand(trackersIds, url, params, SendTrackersToFreezeResultCodeEnum.UnexpectedError);
}

export const resetTrackers = async (siteId: string, trackersIds: number[]) => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/reset`;
  const params = { TrackersList: trackersIds };
  return await sendCommand(trackersIds, url, params, ResetTrackersResultCodeEnum.UnexpectedError);
};

export async function sendTrackersToHoming(
  siteId: string,
  trackersIds: number[],
  homingDirection: HomingDirection
) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/homing`;
  const params = {
    TrackersList: trackersIds,
    HomingDirection: homingDirection,
  };
  return await sendCommand(trackersIds, url, params, SendTrackersToHomingResultCodeEnum.UnexpectedError);
}

export async function forceTrackersElevation(
  siteId: string,
  trackersIds: number[],
  targetElevation: TrackerElevation
) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/site/${siteId}/trackers/commands/forceElevation`;
  const params = {
    TargetElevationDegrees: targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
  };
  return await sendCommand(
    trackersIds,
    url,
    params,
    ForceTrackersElevationResultCodeEnum.UnexpectedError
  );
}

async function sendCommand(
  trackersIds: number[],
  url: string,
  params: unknown,
  unexpectedErrorValue: number
): Promise<ITrackersCommandResultsMap> {
  try {
    const httpResponse = await axios.post<ITrackersCommandResponse>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    const errorValue =
      (error as AxiosError).response?.status === 501
        ? McsCommandNotImplementedStatusCode
        : unexpectedErrorValue;
    return Object.fromEntries(trackersIds.map((id) => [id, errorValue]));
  }
}
