import { FC } from "react";
import { IconButton } from "@mui/material";
import { ArrowDownward, ArrowUpward, TurnLeft, TurnRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/Store";
import { getKey, moveCamera } from "./camerasStore";
import { ICameraWithAgentMetadata } from "../sites/SiteModels";
import { LoadingState } from "../app/LoadingState";
import classes from "./CameraMovementControls.module.scss";

interface ICameraMovementControlsProps {
  siteId: string;
  cameraMetadata: ICameraWithAgentMetadata;
  isMouseHover: boolean;
  disabled: boolean;
}

export const CameraMovementControls: FC<ICameraMovementControlsProps> = ({
  siteId,
  cameraMetadata,
  isMouseHover,
  disabled,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const move = (x: number, y: number) =>
    dispatch(moveCamera({ siteId, cameraId: cameraMetadata, x, y, milliseconds: 1000 }));
  const cameraId = getKey(cameraMetadata);
  const movementState = useSelector((state: RootState) => state.cameras.movements[cameraId]);
  const movementAllowed = !disabled && movementState?.CommandState !== LoadingState.Pending;
  return (
    <div className={classes["container"] + " " + (isMouseHover ? classes["panel-mouse-hover"] : "")}>
      <div>
        <IconButton disabled={!movementAllowed} onClick={() => move(0, 1)} size="small">
          <ArrowUpward />
        </IconButton>
      </div>
      <div>
        <IconButton disabled={!movementAllowed} onClick={() => move(1, 0)} size="small">
          <TurnLeft />
        </IconButton>
        <div className={classes["spacer"]}></div>
        <IconButton disabled={!movementAllowed} onClick={() => move(-1, 0)} size="small">
          <TurnRight />
        </IconButton>
      </div>
      <div>
        <IconButton disabled={!movementAllowed} onClick={() => move(0, -1)} size="small">
          <ArrowDownward />
        </IconButton>
      </div>
    </div>
  );
};
