import { FC } from "react";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { TagTimeValidity } from "./models/TagsModels";
import classes from "./TableDataPoint.module.css";
import TagInvalidIcon from "./TagInvalidIcon";
import DataPointTooltip from "./wrapper/DataPointTooltip";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { RootState } from "../app/Store";
import { selectSiteTag, selectSiteTagDataStr } from "../app/store/MultisiteTagsStore";
import { invalidTagMetadata } from "../app/TagsMetadataUtils";
import { useLoaderTimeout } from "../../common/Hooks/useLoaderTimeout";

interface ITableDataPointProps {
  tagName: string;
  titleOverride?: string;
  nameOverride?: string;
  siteId: string;
}

const TableDataPoint: FC<ITableDataPointProps> = ({
  tagName,
  titleOverride = null,
  nameOverride,
  siteId,
}) => {
  const tagValuesStr = useSelector((state: RootState) => selectSiteTagDataStr(state, siteId, tagName));
  const tag = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName));
  const tagMetadata = useSelector(
    (state: RootState) => state.multiSitesMetadata.sites[siteId]?.tags[tagName] ?? invalidTagMetadata
  );
  const title = titleOverride ?? tagMetadata.displayName;
  const unit = tagMetadata.unitName;
  const isTagValid = tag.timeValidity != TagTimeValidity.Invalid;

  const isSkeletonVisible = useLoaderTimeout(tag.valueExist ?? false);

  const renderDataPointBody = () => {
    if (tag.valueExist && isTagValid) {
      return (
        <>
          <span className={`${classes["value"]} ${TextEnum.h1}`}>{tagValuesStr.value}</span>
          <span className={`${classes["unit"]} ${TextEnum.h7}`}>{unit}</span>
        </>
      );
    }
    if (isSkeletonVisible) {
      return (
        <>
          <Skeleton className={`${TextEnum.h1}`} />
          <Skeleton />
        </>
      );
    }
    return (
      <>
        {!isTagValid && <span className={`${TextEnum.h1}`}>{NO_VALUE_PLACEHOLDER}</span>}
        {!tag.valueExist && !isTagValid && <span>{NO_VALUE_PLACEHOLDER}</span>}
      </>
    );
  };

  return (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      <div className={classes["table-data-point-container"]}>
        <div className={`${TextEnum.h4} ${classes.header}`}>
          <TagInvalidIcon tagName={tagName} siteId={siteId} />
          <div className={`${classes["title"]} ${TextEnum.h5}`}>{title}</div>
        </div>
        <div className={classes["table-data-point-body"]}>{renderDataPointBody()}</div>
      </div>
    </DataPointTooltip>
  );
};

export default TableDataPoint;
