import axios from "axios";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { WeatherOverrideResultsCodeDto } from "./SafeOverrideModels";

export async function activateWeatherOverride(siteId: string, expirationTimeUtc: number) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/weatherSafetyOverride/${siteId}/activate`;
  const response = await axios.post<WeatherOverrideResultsCodeDto>(url, expirationTimeUtc, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function deactivateWeatherOverride(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/weatherSafetyOverride/${siteId}/deactivate`;
  const response = await axios.delete<WeatherOverrideResultsCodeDto>(url);
  return response.data;
}

export async function getWeatherOverrideRemainingTime(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/weatherSafetyOverride/${siteId}/remainingTime`;
  const response = await axios.get<number>(url);
  return response.data;
}

export async function getIsWeatherOverrideActive(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/weatherSafetyOverride/${siteId}/isActive`;
  const response = await axios.get<boolean>(url);
  return response.data;
}
