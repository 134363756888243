import { CSSProperties, FC, useRef, useState } from "react";
import LightTooltip, { LightTooltipProps } from "./LightTooltip";

type LightTooltipOnEllipsisProps = Omit<LightTooltipProps, "onOpen" | "onClose" | "open"> & {
  wrapperStyle?: CSSProperties;
};

const LightTooltipOnEllipsis: FC<LightTooltipOnEllipsisProps> = ({
  children,
  wrapperStyle,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  wrapperStyle ??= { width: "100%", display: "flex", justifyContent: "center" };
  return (
    <LightTooltip
      {...props}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(isEllipsisActive(wrapperRef.current))}
    >
      <div style={wrapperStyle} ref={wrapperRef}>
        {children}
      </div>
    </LightTooltip>
  );
};

function isEllipsisActive(parentElement: HTMLElement | null): boolean {
  if (!parentElement) {
    return false;
  }
  const parentWidth = parentElement.offsetWidth;
  for (let i = 0; i < parentElement.childNodes.length; i++) {
    const el = parentElement.childNodes[i];
    if (el instanceof HTMLElement) {
      if (el.scrollWidth > parentWidth) {
        return true;
      }
      if (el.childNodes.length > 0) {
        if (isEllipsisActive(el)) {
          return true;
        }
      }
    }
  }
  return false;
}

export default LightTooltipOnEllipsis;
