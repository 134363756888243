export interface IServiceUrls {
  apiHostUrl: string;
  siteAppsConfigUrlPrefix: string;
  userRecordsUrlPrefix: string;
  siteGatewayUrlPrefix: string;
  historianTagsSystemBaseUrl: string;
  dualUsageUrlPrefix: string;
  pixelsUrlPrefix: string;
}

export interface IAppConfig extends IAppConfigGeneral, IAppConfigPerEnvironment {}

export interface IAuthConfig {
  tenantName: string;
  clientId: string;
  authorityDomain: string;
  webApiScope: string;
  signInAuth: string;
}

export interface IAppConfigGeneral {
  portalVersion: string;

  onHoverUpdateIntervalInMilliseconds: number;
  chartDefaultSamplingInterval: string;
  delayFactorOnNoneFocus: number;
  configFileMaxSize: number;

  minRangeSamplingInterval: string; // APP_CONFIG.chartDefaultSamplingInterval
  mediumRangeSamplingInterval: string;
  longRangeSamplingInterval: string;

  defaultExportIntervalInSeconds: number;
  trackersExportIntervalInSeconds: number;
  tagStatusOldSecondsThreshold: number;
  tagStatusInvalidSecondsThreshold: number;

  isAnalysisActive: boolean;
  isAnalysisApiActive: boolean;

  milliSecBetweenFailedRequests: number;
  milliSecBetweenFailedInitRequests: number;
  millisecondsBetweenChartHistoryFailedRequest: number;
  millisecondsBetweenChartHistory: number;
  milliSecBetweenWeatherGif: number;
  milliSecLoaderTimeOut: number;
  milliSecBetweenGetTags: number;
  milliSecBetweenGetHistory: number;
  milliSecBetweenGetTrackers: number;
  milliSecBetweenGetIssues: number;
  milliSecBetweenGetDiffuseOptimizationStatus: number;
  milliSecBetweenCamerasSnapshot: number;
  milliSecBetweenCamerasHealthCheck: number;
}

export interface IAppConfigPerEnvironment {
  serviceUrls: IServiceUrls;
  authConfig: IAuthConfig;
  clarityProjectId?: string;
}

const SecondMs = 1000;
const MinuteMs = 60 * SecondMs;

export const GENERAL_APP_CONFIG: IAppConfigGeneral = {
  isAnalysisActive: false,
  isAnalysisApiActive: false,
  tagStatusOldSecondsThreshold: 20 * 60,
  tagStatusInvalidSecondsThreshold: 7200, // 2 hours
  milliSecBetweenGetTags: 5 * SecondMs,
  milliSecBetweenGetHistory: 15 * MinuteMs,
  milliSecBetweenGetTrackers: 30 * SecondMs,
  milliSecBetweenGetIssues: 30 * SecondMs,
  milliSecBetweenCamerasSnapshot: 3 * SecondMs,
  milliSecBetweenCamerasHealthCheck: 5 * SecondMs,

  portalVersion: process.env.REACT_APP_SG_VERSION || "unknown version",
  configFileMaxSize: 10000000,
  chartDefaultSamplingInterval: "00:15:00",
  delayFactorOnNoneFocus: 5,
  onHoverUpdateIntervalInMilliseconds: SecondMs,

  minRangeSamplingInterval: "00:10:00", // APP_CONFIG.chartDefaultSamplingInterval
  mediumRangeSamplingInterval: "01:00:00",
  longRangeSamplingInterval: "12:00:00",

  defaultExportIntervalInSeconds: 30,
  trackersExportIntervalInSeconds: 60,

  milliSecBetweenFailedRequests: 10 * SecondMs,
  milliSecBetweenFailedInitRequests: 3 * SecondMs,
  millisecondsBetweenChartHistoryFailedRequest: 10 * SecondMs,
  millisecondsBetweenChartHistory: 600 * SecondMs,
  milliSecBetweenWeatherGif: 60 * SecondMs,
  milliSecLoaderTimeOut: 30 * SecondMs,
  milliSecBetweenGetDiffuseOptimizationStatus: 1 * MinuteMs,
};
