import { useDispatch, useSelector } from "react-redux";
import { UserAccessType, userSlice } from "./UserStore";
import { RootState } from "../app/Store";
import { useKeyStrokeDetector } from "../../common/useKeyStrokeDetector";

const SudoModeListener = () => {
  const sudoMode = useSelector((state: RootState) => state.user.sudoMode);
  const originalUserType = useSelector((state: RootState) => state.user.originalUserType);
  const dispatch = useDispatch();
  useKeyStrokeDetector("iddqd", () => {
    console.log(`Sudo mode ${sudoMode ? "de" : ""}activated!`);
    dispatch(userSlice.actions.setSudoMode(!sudoMode));
  });
  useKeyStrokeDetector("viewermode", () => {
    console.log(`Viewer mode ${originalUserType ? "de" : ""}activated!`);
    dispatch(userSlice.actions.impersonateAs(originalUserType || UserAccessType.Viewer));
  });
  return <></>;
};

export default SudoModeListener;
