import React from "react";

interface IProgressBarSvgProps {
  width: number;
  progress: number;
  overFlow: number;
}

const ProgressBarSvg: React.FC<IProgressBarSvgProps> = ({ width, progress, overFlow }) => {
  return (
    <svg width={width} height="12" viewBox="0 0 80 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height="12" rx="4.5" fill="#D9D9D9" />
      <rect width={overFlow} height="12" rx="4.5" fill="#87E9BA" x={56} />
      <rect width={progress} height="12" rx="4.5" fill="#5AA0DC" />
    </svg>
  );
};

export default ProgressBarSvg;
