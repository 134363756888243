export const AlertStyle = {
  width: "fit-content",
  margin: "auto",
  color: "var(--white)",
  fontFamily: "var(--font-family)",
  fontWeight: "400",
  borderRadius: "8px",
  "&.MuiAlert-standardError": {
    backgroundColor: "var(--error-color)",
  },
  "&.MuiAlert-standardSuccess": {
    backgroundColor: "var(--ok-color)",
  },
  "&.MuiAlert-standardInfo": {
    backgroundColor: "var(--blue-button-background-color)",
  },
  "& .MuiAlert-message": {
    padding: "7px 0px",
    textAlign: "start",
  },
  "& .MuiAlert-icon": {
    margin: "0",
    padding: "0 0 0 0",
  },
  "& .MuiAlert-action": {
    padding: "4.5px 10px 0px 0px",
  },
  "& .MuiAlert-action .MuiSvgIcon-root": {
    fontSize: "medium",
  },
  "@media screen and (max-width: 1600px)": {
    padding: "0",
    fontSize: "8px",

    "& .MuiAlert-action .MuiSvgIcon-root": {
      fontSize: "small",
    },
  },
};
