import { FC, useState } from "react";

import { InputProps as StandardInputProps } from "@mui/material/Input";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField/TextField";

import { inputStyle } from "./InputStyle";
import { TextEnum } from "../TextStyles";

interface SolarGikInputProps {
  label: string;
  value: number | string | null | undefined;
  onChange: StandardTextFieldProps["onChange"];
  disabled?: boolean;
  type: string;
  placeholder: string;
  textSize?: TextEnum | string;
  size?: "small" | "medium";
  forceLabel?: boolean;
  inputPropsExtras?: Partial<StandardInputProps>;
  rootClassName?: string;
}
const SolarGikInput: FC<SolarGikInputProps> = ({
  label,
  value,
  onChange,
  disabled,
  type,
  placeholder,
  textSize,
  size = "medium",
  forceLabel,
  inputPropsExtras = {},
  rootClassName,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <TextField
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      label={isFocused || forceLabel ? label : ""}
      placeholder={!isFocused ? placeholder : ""}
      value={value?.toString() || ""}
      onChange={onChange}
      disabled={disabled}
      type={type}
      sx={inputStyle}
      InputProps={{ className: textSize, ...inputPropsExtras }}
      InputLabelProps={{ className: textSize }}
      size={size}
      classes={{ root: rootClassName }}
    />
  );
};
export default SolarGikInput;
