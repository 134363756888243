import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { humanizeEnumValue } from "../../common/EnumUtils";
import { FieldState, McsState, Status } from "../data_point/models/TagsModels";
import TagsNames from "../data_point/TagsNames";

export const convertValueToMcsState = (value: number) =>
  humanizeEnumValue(McsState[value]) ?? NO_VALUE_PLACEHOLDER;

export const convertValueToFieldState = (value: number) => {
  switch (value) {
    case FieldState.WindSafe:
    case FieldState.UpsEmergency:
    case FieldState.EngineerEmergency:
    case FieldState.CloudEmergency:
      return "Safe";
    case FieldState.Sleep:
      return "Night";
    case FieldState.Tracking:
      return "Operational";
    case FieldState.Undefined:
      return NO_VALUE_PLACEHOLDER;
    default:
      return humanizeEnumValue(FieldState[value]) ?? NO_VALUE_PLACEHOLDER;
  }
};

export const isFieldStateBlinking = (valueAsString: string) => {
  if (valueAsString === "Disconnected") {
    return true;
  }
  return false;
};

export const convertValueToStatus = (value: number) =>
  humanizeEnumValue(Status[value]) ?? NO_VALUE_PLACEHOLDER;

export const defaultConvertToString = (value: number) => {
  return parseFloat(value.toFixed(1)).toString();
};

export function getConverterFunction(tag: string): (v: number) => string {
  switch (tag) {
    case TagsNames.AGGR_INVERTERS_STATUS:
    case TagsNames.AGGR_WEATHER_STATUS:
    case TagsNames.AGGR_TRACKERS_STATUS:
    case TagsNames.AGGR_POWER_METER_STATUS:
      return convertValueToStatus;
    case TagsNames.MCS_STATE:
      return convertValueToMcsState;
    case TagsNames.TRACKING_STATE:
      return convertValueToMcsState;
    case TagsNames.MCS_FIELD_STATE:
      return convertValueToFieldState;
    default:
      return defaultConvertToString;
  }
}
