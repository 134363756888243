import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { selectCurrentResults } from "../../app/store/TrackersCommandsStore";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowOutlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";

const lastCommandButtonStyle = {
  ...narrowOutlinedWhiteButton,
  margin: "0px",
  width: "100%",
  padding: "6px 0px",

  "@media screen and (max-width: 1600px)": {
    display: "inline-flex",
    margin: "0px",
    fontSize: "10px",
  },
};
interface IOpenLastCommandProps {
  setLastCommandDialogOpen: (value: boolean) => void;
}

const OpenLastCommand: FC<IOpenLastCommandProps> = ({ setLastCommandDialogOpen }) => {
  const trackersCommands = useSelector((state: RootState) => selectCurrentResults(state));
  const isCommandResultNotEmpty = Object.keys(trackersCommands).length > 0;

  return (
    <SolarGikButton
      style={lastCommandButtonStyle}
      onClickFunc={() => setLastCommandDialogOpen(true)}
      isDisabled={!isCommandResultNotEmpty}
      text="Open last command"
    />
  );
};
export default OpenLastCommand;
