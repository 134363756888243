import { GridColDef } from "@mui/x-data-grid";
import { IAggregateAlertHistory, ISingleDeviceAlertHistory } from "../DTOs";
import { AlertUnion, IAlertMetadataMap } from "../Models";
import getActiveAlertTableColumns from "./ActiveAlertTableColumns";
import { TrackerNamesMap } from "../../sites/SiteModels";
import DateTimeFormatter from "../../app/DateTimeFormatter";
import HeaderWithIcon from "./tableComponent/HeaderWithIcon";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { createTimeCell } from "../../../common/table/CellFactory";

export const getHistoryAlertTableColumns = (
  alertsMetadata: IAlertMetadataMap | undefined,
  trackerNamesMap: TrackerNamesMap,
  dtFormatter: DateTimeFormatter,
  is150PercentScreen: boolean
): GridColDef<AlertUnion>[] => [
  ...getActiveAlertTableColumns(alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen),
  {
    ...createTimeCell(
      (row) => (row as ISingleDeviceAlertHistory | IAggregateAlertHistory).endTime ?? 0,
      dtFormatter,
      is150PercentScreen
    ),
    field: "endTime",
    renderHeader: () => (
      <HeaderWithIcon label={"Closed"} icon={getIcons(FieldIcons.Calender, IconCategory.Fields)} />
    ),
  },
];
