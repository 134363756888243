import { IMaintenanceListitem, MaintenanceReason } from "./TrackersModels";
import getIcons from "../../SolarGikLib/icons/Icons";
import { ReasonIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { UserAccessType } from "../user/UserStore";

const maintenanceOptionListConfig: IMaintenanceListitem[] = [
  {
    title: "Wind Safe",
    icon: getIcons(ReasonIcons.Wind, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: MaintenanceReason.WeatherProjectionWind,
  },
  {
    title: "Snow Safe",
    icon: getIcons(ReasonIcons.Snow, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: MaintenanceReason.WeatherProjectionSnow,
  },
  {
    title: "Panel Washing",
    icon: getIcons(ReasonIcons.Sprinklers, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: MaintenanceReason.PanelWashing,
  },
  {
    title: "Tracker Maintenance",
    icon: getIcons(ReasonIcons.Tracker, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: MaintenanceReason.TrackerMaintenance,
  },
  {
    title: "User Maintenance",
    icon: getIcons(ReasonIcons.Maintenance, IconCategory.Reason),
    accessType: UserAccessType.Operator,
    id: MaintenanceReason.UserMaintenance,
  },
  {
    title: "Software Update",
    icon: getIcons(ReasonIcons.Software, IconCategory.Reason),
    accessType: UserAccessType.Engineer,
    id: MaintenanceReason.SoftwareUpdate,
  },
];
export const getMaintenanceOptionListConfig = (userAccess: UserAccessType) => {
  return maintenanceOptionListConfig.filter((item) => item.accessType <= userAccess);
};
