import classes from "./TableCellItem.module.css";
import { NO_VALUE_PLACEHOLDER } from "../ConstantValues";
import { capitalizeFirstLetter } from "../StringUtils";

const TableCellItemBool = (props: { value: boolean }) => {
  const text =
    !props || props.value == null ? NO_VALUE_PLACEHOLDER : capitalizeFirstLetter(props.value.toString());

  return <div className={classes.center}>{text}</div>;
};
export default TableCellItemBool;
