import { FC, useState } from "react";
import classes from "./SystemStatus.module.css";
import { convertValueToFieldState, isFieldStateBlinking } from "../../app/TagsToEnumTable";
import FieldStateDataPoint from "../../banner/FieldStateDataPoint";
import SystemStatusIcon from "../../banner/SystemStatusIcon";
import TagsNames from "../../data_point/TagsNames";
import { AuxiliariesStatusIcons } from "../../../SolarGikLib/icons/IconsModels";
import OverrideTooltip from "./SafeOverrideTooltip";
import { useSystemStatusDescription } from "../../system_status/useSystemStatusDescription";

interface ISystemStatusProps {
  siteId: string;
}
const SystemStatus: FC<ISystemStatusProps> = ({ siteId }) => {
  const { invertersIssusDescription, trackersIssuesDescription } = useSystemStatusDescription(siteId);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <div className={classes.row}>
      <div className={classes["system-status-points-container"]}>
        <div className={classes["system-status-points-content"]}>
          <FieldStateDataPoint
            tagName={TagsNames.MCS_FIELD_STATE}
            siteId={siteId}
            titleOverride={"Field State:"}
            valueToStringFuncOverride={convertValueToFieldState}
            isBlinkingFunc={isFieldStateBlinking}
          />
          <SystemStatusIcon
            systemItems={[
              {
                tagName: TagsNames.AGGR_INVERTERS_STATUS,
                invalidIcon: AuxiliariesStatusIcons.InverterInvalid,
                validIcon: AuxiliariesStatusIcons.Inverter,
                description: invertersIssusDescription,
              },
              {
                tagName: TagsNames.AGGR_TRACKERS_STATUS,
                invalidIcon: AuxiliariesStatusIcons.TrackerInvalid,
                validIcon: AuxiliariesStatusIcons.Tracker,
                description: trackersIssuesDescription,
              },
            ]}
          />
        </div>
      </div>

      <OverrideTooltip isOpen={isTooltipOpen} setIsOpen={setIsTooltipOpen} siteId={siteId} />
    </div>
  );
};
export default SystemStatus;
