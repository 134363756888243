import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { GridRowHeightParams } from "@mui/x-data-grid";
import { createUserRecordsTablesColumns } from "./UserRecordsColumnConfig";
import classes from "./UserRecordsTableUi.module.css";
import Table from "../../../common/table/Table";
import { RootState } from "../../app/Store";
import { UserAccessType } from "../../user/UserStore";
import { IUserRecord } from "../UserRecordsModels";
import { selectSiteDateTimeFormatter } from "../../sites/SiteStore";
import { useIs150PercentScreen } from "../../../common/WindowUtils";

interface UserRecordsTableProps {
  data: IUserRecord[];
  isLoading: boolean;
}

const getRowId = (row: IUserRecord) => row.title + row.eventDate;

const UserRecordsTableUi: FC<UserRecordsTableProps> = ({ data, isLoading }) => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const is150PercentScreen = useIs150PercentScreen();
  const dtFormatter = useSelector(selectSiteDateTimeFormatter);
  const isEngineer = userType >= UserAccessType.Engineer;

  const tableColumns = createUserRecordsTablesColumns(isEngineer, is150PercentScreen, dtFormatter);
  const dataToShow = useMemo(() => [...data].sort((a, b) => b.eventDate - a.eventDate), [data]);
  const tableKey = `data-${data.length}`;
  const rowHeightThreshold = 35;
  const fixedRowHeight = 50;
  return (
    <div className={classes.table}>
      <Table
        loading={isLoading}
        key={tableKey}
        rows={dataToShow}
        columns={tableColumns}
        disableRowSelectionOnClick={true}
        getRowHeight={(params: GridRowHeightParams) =>
          (params.model as IUserRecord).description.length > rowHeightThreshold ? "auto" : fixedRowHeight
        }
        getRowId={(row) => getRowId(row as IUserRecord)}
      />
    </div>
  );
};

export default UserRecordsTableUi;
