import { FC, ReactNode } from "react";

import { useSelector } from "react-redux";
import Header from "./header/Header";
import classes from "./MultiSiteContent.module.css";
import { NavItemsConfig } from "./NavigationDrawer/DrawerConfiguration";
import NavigationDrawer from "../navigation/NavigationDrawer/NavigationDrawer";
import { RootState } from "../app/Store";

interface MultisiteContentProps {
  children: ReactNode;
}
const MultisiteContent: FC<MultisiteContentProps> = ({ children }) => {
  const userLevel = useSelector((state: RootState) => state.user.userType);
  const filteredDrawerItems = NavItemsConfig.filter((item) =>
    item.visiblityFilter({ userAccessType: userLevel })
  );
  return (
    <>
      <Header />
      <NavigationDrawer navigationItems={filteredDrawerItems} />
      <div className={classes.children}>{children}</div>
    </>
  );
};

export default MultisiteContent;
