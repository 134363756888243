import React, { FC, ReactElement, useState } from "react";
import { IconButton, Menu, MenuItem, SxProps } from "@mui/material";
import classes from "./Dropdown.module.scss";
import { dropdownStyle } from "./DropdownStyle";
import { TextEnum } from "../../SolarGikLib/TextStyles";

interface ISolarGikDropdownProps {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title?: string | ReactElement;
  menuItems: MenuItem[];
  menuCustomUi?: SxProps;
  horizontal?: "left" | "center" | "right";
  SelectedIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  selectedDefaultItemLabel?: string | ReactElement;
}

export type MenuItem = {
  label: string | ReactElement;
  onClick: () => void;
  disabled?: boolean;
};

const SolarGikDropdown: FC<ISolarGikDropdownProps> = ({
  icon: Icon,
  menuItems,
  title,
  menuCustomUi,
  horizontal = "left",
  SelectedIcon,
  selectedDefaultItemLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string | ReactElement>(
    selectedDefaultItemLabel || menuItems[0].label
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (item: MenuItem) => {
    setSelectedItemLabel(item.label);
    item.onClick();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} className={`${classes.dropdown} ${TextEnum.h4}`}>
        {title}
        {Icon && <Icon />}
      </IconButton>
      <Menu
        sx={{ ...dropdownStyle, ...(menuCustomUi && { ...menuCustomUi }) }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal }}
        transformOrigin={{ vertical: "top", horizontal }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            className={`${classes["menu-item"]} ${TextEnum.h5}`}
            key={index}
            onClick={() => handleMenuItemClick(item)}
          >
            {item.label}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {SelectedIcon && selectedItemLabel === item.label && <SelectedIcon />}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SolarGikDropdown;
