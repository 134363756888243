import { createElement, FC } from "react";
import classes from "./FieldStateTooltipContent.module.css";

export interface ISafteyReasonTooltipItem {
  text: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface FieldStateTooltipContentProps {
  name?: string;
  info: ISafteyReasonTooltipItem[];
  timestamp: string;
}

export const FieldStateTooltipContent: FC<FieldStateTooltipContentProps> = ({
  name,
  info,
  timestamp,
}) => {
  let safteyReasonContent = undefined;
  if (info.length == 1) {
    safteyReasonContent = (
      <div className={`${classes["container-title"]} ${classes["inline-row"]}`}>
        {`${name}: ${info[0].text}`}
        <span className={classes["inline-icon"]}>{info[0].icon && createElement(info[0].icon)}</span>
      </div>
    );
  } else if (info.length > 1) {
    safteyReasonContent = (
      <>
        <div className={classes["container-title"]}>{name + ":"}</div>
        <ul className={classes["list"]}>
          {info.map((item) => (
            <li key={item.text} className={classes["row"]}>
              <div className={classes["text-column"]}>{item.text}</div>
              {item.icon && (
                <div className={classes["icon-column"]}>
                  <item.icon />
                </div>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <div className={classes["container"]}>
      {safteyReasonContent}
      <div className={classes["timestamp"]}>{timestamp}</div>
    </div>
  );
};
