import React, { useEffect, useState } from "react";
import { Skeleton, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./CamerasControlDeploy.module.css";
import useAsyncOperationWithErrorHandling from "../../../common/Hooks/useAsyncOperationWithErrorHandling";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import { outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import * as api from "../SiteControlAppsDeployApi";
import * as camerasApi from "../../cameras/camerasApi";
import { selectSiteId } from "../../sites/SiteStore";
import { ModuleRuntimeStatus, SiteAppModule } from "../AppConfigModels";

const CAMERAS_CONTROL_VERSION_TAG = "cameras_control_version";

const CamerasControlDeploy = () => {
  const [version, setVersion] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [moduleStatus, setModuleStatus] = useState<ModuleRuntimeStatus>();
  const [deviceIdList, setDeviceIdList] = useState<string[]>([]);
  const [versionsList, setVersionsList] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<string>("");
  const [agentId, setAgentId] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const siteId = useSelector(selectSiteId);
  const handleError = (message: string) => {
    setAlertMessage({
      text: message,
      severity: "error",
    });
  };

  const runAsyncFuncWithErrorHandling = useAsyncOperationWithErrorHandling(handleError);

  const getIotDeviceIds = async () => {
    const deviceList = await runAsyncFuncWithErrorHandling(
      api.getIotDevicesIdList,
      "Failed to get Iot Device Id"
    );
    if (deviceList.length === 0) {
      setAlertMessage({
        text: "No Iot Device Id found",
        severity: "error",
      });
    } else {
      setDeviceIdList(deviceList);
    }
  };

  const getDeploymentVersions = async () => {
    const versions = await runAsyncFuncWithErrorHandling(
      () => api.getDeploymentVersions(CAMERAS_CONTROL_VERSION_TAG),
      "Failed to get versions"
    );
    if (versions.length === 0) {
      setAlertMessage({
        text: "No IoT versions found",
        severity: "error",
      });
    } else {
      setVersionsList(versions);
    }
  };

  useEffect(() => {
    const initAsync = async () => {
      await Promise.all([getIotDeviceIds(), getDeploymentVersions()]);
      setIsLoading(false);
    };
    initAsync();
  }, [siteId]);

  const onSelectDeviceId = (value: string | null) => {
    if (value === null) {
      return;
    }
    if (value.startsWith(siteIdPrefix)) {
      setDeviceId(value);
    } else if (value.length > 0) {
      if (
        window.confirm(`The device id is ${value} and the site is ${siteId}, are you sure to continue?`)
      ) {
        setDeviceId(value);
      }
    }
  };

  const getModuleStatus = async () => {
    try {
      setIsLoading(true);
      const moduleStatus = await runAsyncFuncWithErrorHandling(
        () => api.getModuleStatusOnDevice(siteId, deviceId, SiteAppModule.CamerasControl),
        "Failed to get module status on device"
      );
      setModuleStatus(moduleStatus);
      if (moduleStatus === ModuleRuntimeStatus.NotExist) {
        setAlertMessage({
          text: "CamerasControl module doesn't exist, Try again in a few minutes",
          severity: "info",
        });
        return;
      }
      const fetchedAgentId = await runAsyncFuncWithErrorHandling(
        () => camerasApi.getAgentIdFromIotHubDeviceId(deviceId),
        "Failed to get module twin"
      );
      setAgentId(fetchedAgentId ?? undefined);
      if (moduleStatus !== ModuleRuntimeStatus.Running) {
        setAlertMessage({
          text: "CamerasControl is deployed but not " + (fetchedAgentId ? "running" : "configured"),
          severity: "info",
        });
        return;
      }
      setAlertMessage({
        text: "CamerasControl Deployed & Running",
        severity: "success",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const startDeploy = async () => {
    try {
      setIsLoading(true);
      await runAsyncFuncWithErrorHandling(
        () => api.ensureSiteModuleTypeExists(siteId, SiteAppModule.CamerasControl, deviceId),
        "Failed to ensure site module type CamerasControl exist"
      );

      await runAsyncFuncWithErrorHandling(
        () => api.addOrUpdateTagInIotDevice(deviceId, CAMERAS_CONTROL_VERSION_TAG, version),
        "Failed to add or update tag In Iot device"
      );

      setAlertMessage({
        text: "successfully started deployment of CamerasControl",
        severity: "success",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const configureAgent = async () => {
    try {
      setIsLoading(true);
      const newAgentId = await runAsyncFuncWithErrorHandling(
        () => camerasApi.addNewAgent(siteId, deviceId),
        "Failed to add new agent"
      );
      setAgentId(newAgentId);
      setAlertMessage({
        text: "Agent configuration applied successfully",
        severity: "success",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const siteIdPrefix = siteId.split("-")[0];
  const filterDeviceIdList: string[] = deviceIdList.filter((x) => x.startsWith(siteIdPrefix));
  const noDevicesFoundForSite = filterDeviceIdList.length === 0 && deviceIdList.length > 0;
  const canConfigureAgent =
    moduleStatus !== undefined && moduleStatus !== ModuleRuntimeStatus.NotExist && !agentId;

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isLoading && <Skeleton variant="rounded" width="100%" height="300px" />}
      {!isLoading && (
        <>
          <div className={classes["row"]}>
            {noDevicesFoundForSite ? (
              <SolarGikInput
                type="text"
                label="Device Id"
                placeholder="Device Id"
                value={deviceId}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setDeviceId(e.target.value)
                }
              />
            ) : (
              <SolarGikAutocomplete
                size="small"
                options={filterDeviceIdList}
                renderInputFunc={(params) => <TextField {...params} label="Device Id" sx={inputStyle} />}
                onChange={(_event, value) => onSelectDeviceId(value)}
                value={deviceId}
                key={"device Id"}
                id={"device Id"}
              />
            )}
          </div>

          <div className={classes["row"]}>
            <SolarGikAutocomplete
              size="small"
              options={versionsList}
              renderInputFunc={(params) => <TextField {...params} label="Version" sx={inputStyle} />}
              onChange={(_event, value) => setVersion(value ?? "")}
              value={version}
              key={"version"}
              id={"version"}
            />
            <SolarGikButton
              rootClassName={classes["button"]}
              text="Deploy"
              style={outlinedWhiteButton}
              onClickFunc={startDeploy}
              isDisabled={version === ""}
            />
          </div>

          <div className={classes["row"]}>
            <SolarGikButton
              rootClassName={classes["button"]}
              text={"Check deployment status"}
              isDisabled={deviceId === ""}
              onClickFunc={getModuleStatus}
              style={outlinedWhiteButton}
            />
            <SolarGikButton
              rootClassName={classes["button"]}
              text={agentId ? `(already configured - Agent #${agentId})` : "Configure agent"}
              style={outlinedWhiteButton}
              onClickFunc={configureAgent}
              isDisabled={!canConfigureAgent}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default CamerasControlDeploy;
