import { UnknownError } from "./UnknownError";

export const getErrorMessage = (errorType: number) => {
  switch (errorType) {
    case 400:
      return "Bad request";
    case 401:
      return "Unauthorised";
    case 404:
      return "Not found";
    case 500:
      return "Internal error";
    default:
      "Unknown error";
      break;
  }
};
export const errorHandlerFunc = (error: UnknownError, sourceFunc: string) => {
  const status = error.response ? error.response.status : 0;
  const message = getErrorMessage(status);
  return `${sourceFunc} failed due to: ${message}. ${"status code: " + status}`;
};
