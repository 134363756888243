import dayjs from "dayjs";
import { DAYJS_YEAR_TO_DAY_SHORT_FORMAT, DAYJS_HOUR_TO_MINUTE_FORMAT } from "../../app/DayjsUtils";
import { DayOfWeek, dayOfWeekValues, IScheduledMaintenance } from "./Models";

export const formatMaintenanceTime = (value: IScheduledMaintenance, siteTimezone: string) => {
  const startTime = dayjs(value.startTimeUtc).tz(siteTimezone);
  const endTime = dayjs(value.endTimeUtc).tz(siteTimezone);
  if (value.recurrence) {
    return (
      `${startTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)} - ` +
      `${endTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)} ` +
      `${value.recurrence.startTime}-` +
      `${value.recurrence.endTime}`
    );
  }
  return (
    `${startTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)} ` +
    `${startTime.format(DAYJS_HOUR_TO_MINUTE_FORMAT)} - ` +
    `${endTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)} - ` +
    `${endTime.format(DAYJS_HOUR_TO_MINUTE_FORMAT)}`
  );
};

export const bitFlagToDayOfWeekEnum = (bitflag: number): DayOfWeek[] => {
  return dayOfWeekValues.filter((day) => (bitflag & (1 << day)) !== 0);
};

export const dayOfWeekToNumber = (days: DayOfWeek[]): number[] => {
  return days.map((day) => Object.values(DayOfWeek).indexOf(day));
};

export const numbersToDayOfWeek = (days: number[]): DayOfWeek[] => {
  return days.map((day) => Object.values(DayOfWeek)[day] as DayOfWeek);
};
