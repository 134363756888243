import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ISitesMetadataDictionary } from "../../sites/SiteModels";
import { getSitesMetadataAsync } from "../../sites/SiteAPI";
import { ITagMetadata } from "../../data_point/models/TagsModels";
import { addAliasedTags, getSiteTagsMetadata } from "../TagsMetadataUtils";
import { LoadingState } from "../LoadingState";

interface ISitesMetadataStore {
  loading: LoadingState;
  sites: ISitesMetadataDictionary;
  error?: unknown;
}

export const defaultProperties: ISitesMetadataStore = {
  loading: LoadingState.None,
  sites: {},
};

export const sitesMetadataSlice = createSlice({
  name: "multiSitesMetadata",
  initialState: defaultProperties,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchSitesMetadata.pending, (state) => {
        state.loading = LoadingState.Pending;
        state.sites = {};
      })
      .addCase(fetchSitesMetadata.fulfilled, (state, action) => {
        state.sites = action.payload;
        for (const [siteId, siteMetadata] of Object.entries(state.sites)) {
          const metadata = getSiteTagsMetadata(siteMetadata);
          state.sites[siteId].tags = metadata.reduce(
            (acc, tag) => {
              acc[tag.uniqueName] = tag;
              return acc;
            },
            {} as { [key: string]: ITagMetadata }
          );
        }
        addAliasedTags(state.sites);
        state.loading = LoadingState.Complete;
      })
      .addCase(fetchSitesMetadata.rejected, (state, action) => {
        state.error = action.error;
        state.loading = LoadingState.Error;
      }),
});

export const fetchSitesMetadata = createAsyncThunk(
  "multiSitesMetadata/fetchSitesMetadata",
  (siteIds: string[]) => getSitesMetadataAsync(siteIds)
);

export const sitesMetadataReducer = sitesMetadataSlice.reducer;
