import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddingNewUserRecordUi from "./AddingNewUserRecordUi";
import { addNewUserRecord } from "./UserRecordsAPI";
import { IUserRecord, IUserRecordDto } from "./UserRecordsModels";
import { multiSitesUserRecordsSlice } from "../app/store/UserRecordsStore";
import { selectSiteId } from "../sites/SiteStore";

interface IAddingNewUserRecordProps {
  onAddedNewRecord: () => void;
}

const AddingNewUserRecord: FC<IAddingNewUserRecordProps> = ({ onAddedNewRecord }) => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteId);
  const addRecord = async (values: IUserRecord) => {
    const newRecord: IUserRecordDto = {
      ComponentType: values.componentType,
      EventDate: values.eventDate,
      ComponentName: values.componentName,
      Description: values.description,
      RecordType: values.recordType,
      Reporter: values.reporter,
      Title: values.title,
      IsEngineerOnly: values.isEngineerOnly,
    };
    const addedRecord = await addNewUserRecord(siteId, newRecord);
    dispatch(
      multiSitesUserRecordsSlice.actions.addRecord({
        site: siteId,
        record: addedRecord,
      })
    );
    onAddedNewRecord();
  };
  return <AddingNewUserRecordUi addRecord={addRecord} />;
};
export default AddingNewUserRecord;
