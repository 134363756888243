import { TrackersCommandType } from "../TrackersModels";

interface ITrackersCommandResult {
  commandType: TrackersCommandType;
  elevation?: number;
}
type TGetTitleMessageFn = (commandResult: ITrackersCommandResult) => string;
const commandToTitleMessage: Record<TrackersCommandType, TGetTitleMessageFn> = {
  [TrackersCommandType.SendTrackersToMaintenance]: (commandResult) =>
    `Manual ${commandResult.elevation}`,
  [TrackersCommandType.ReleaseTrackersFromMaintenance]: () => "Released",
  [TrackersCommandType.SendTrackersToFreeze]: () => "Freeze",
  [TrackersCommandType.ResetTrackers]: () => "Reset",
  [TrackersCommandType.SendTrackersToHoming]: () => "Homing",
  [TrackersCommandType.ForceTrackersElevation]: (commandResult) =>
    `Set Elevation ${commandResult.elevation}`,
};
export const getResultTitleMessage = (trackersCommands: ITrackersCommandResult): string => {
  const getTitleMessage = commandToTitleMessage[trackersCommands.commandType];
  if (getTitleMessage) {
    return getTitleMessage(trackersCommands);
  } else {
    return "Unknown Command";
  }
};
