import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button, CSSObject, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FaultType, IFaultsSubscriber } from "../../faults/DTOs";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { AppDispatch, RootState } from "../../app/Store";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { removeFaultsSubscriber, updateFaultsSubscriber } from "../../app/store/FaultsSubscribersStore";
import classes from "./FaultSubscribersGrid.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";

interface IFaultsSubscriberWithValidation extends IFaultsSubscriber {
  isValid: boolean;
}

const defaultFaultSubscriber: IFaultsSubscriberWithValidation = {
  siteId: "",
  name: "",
  endpointDetails: { emailAddress: "" },
  method: 0,
  faultType: 2,
  faultId: -1,
  isValid: false,
};

const TemporaryNewEntryId = -1;
interface IEditToolbarProps {
  setRows: Dispatch<SetStateAction<GridRowsProp>>;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
}

const EditToolbar: FC<IEditToolbarProps> = ({ setRows, setRowModesModel }) => {
  const handleClick = () => {
    const id = TemporaryNewEntryId;
    setRows((oldRows) => [...oldRows, { id, ...defaultFaultSubscriber }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <div className={classes["toolbar"]}>
      <SolarGikButton
        style={{ ...narrowBlueButton, width: "180px" }}
        onClickFunc={handleClick}
        text="Add Subscriber"
      />
    </div>
  );
};

const humanizedFaultTypeMap: { [key: string]: FaultType } = {
  Alert: FaultType.Alert,
  Issue: FaultType.Issue,
  All: FaultType.All,
  "All Issues": FaultType.AllIssues,
  "All Alerts": FaultType.AllAlerts,
};

interface IFaultsSubscriberGridProps {
  subscribers: IFaultsSubscriber[];
}

const FaultSubscribersGrid: FC<IFaultsSubscriberGridProps> = ({ subscribers }) => {
  const [rows, setRows] = useState<IFaultsSubscriberWithValidation[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [alertMessage, setAlertMessage] = useState<AlertMessage>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const faultsMetadata = useSelector((state: RootState) => state.faults.multiSitesFaults.metadata);
  const sitesIds = useSelector((state: RootState) => state.user.siteIds);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    setRows(subscribers.map((subscriber) => ({ ...subscriber, isValid: true })));
  }, [subscribers]);

  const extractFaultName = (faultType: FaultType, metadataId: number): string => {
    if (metadataId === 0 && (faultType === FaultType.Alert || faultType === FaultType.Issue)) {
      return "Unassigned";
    }
    if (faultType === FaultType.Issue) {
      return faultsMetadata.issues?.[metadataId]?.name ?? "Unknown";
    } else if (faultType === FaultType.Alert) {
      return faultsMetadata.alerts?.[metadataId]?.name ?? "Unknown";
    }

    return "";
  };

  const getMetadataValues = (faultType: FaultType): string[] => {
    if (faultType === FaultType.Issue) {
      return Object.values(faultsMetadata.issues ?? []).map((issue) => issue.name);
    } else if (faultType === FaultType.Alert) {
      return Object.values(faultsMetadata.alerts ?? []).map((alert) => alert.name);
    }

    return [];
  };

  const getFaultId = (faultType: FaultType, name: string): number => {
    if (name === "Unassigned") {
      return 0;
    }
    switch (faultType) {
      case FaultType.AllIssues:
        return -3;
      case FaultType.AllAlerts:
        return -2;
      case FaultType.All:
        return -1;
      case FaultType.Issue:
        return Object.values(faultsMetadata.issues ?? []).find((issue) => issue.name === name)
          ?.issueType;
      case FaultType.Alert:
        return Object.values(faultsMetadata.alerts ?? []).find((alert) => alert.name === name)
          ?.alertType;

      default:
        return 0;
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
    setRows((currentRows) =>
      currentRows.map((row) => (row.id === params.id ? { ...row, ...params.row } : { ...row }))
    );
  };

  const handleEditClick = (id: GridRowId) => () => {
    setAlertMessage(undefined);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const row = rows.find((row) => row.id === id);
    if (!row) {
      return;
    }
    if (!row.isValid && row.id === TemporaryNewEntryId) {
      setRows(rows.filter((row) => row.id !== id));
      return;
    }
    setSelectedRowId(id as number);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedRowId !== undefined) {
      setSelectedRowId(undefined);
      dispatch(removeFaultsSubscriber(selectedRowId));
    }
    setDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setSelectedRowId(undefined);
    setDialogOpen(false);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    if (id === TemporaryNewEntryId) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const validateSubscriberRow = (
    row: IFaultsSubscriberWithValidation
  ): { isValid: boolean; errorMessage?: string } => {
    const isValidEmail = validateEmail(row.endpointDetails.emailAddress ?? "");
    const hasName = row.name.trim() !== "";
    const hasSiteId = row.siteId.trim() !== "";
    const hasValidFaultId =
      row.faultId || (row.faultType !== FaultType.Alert && row.faultType !== FaultType.Issue);

    if (!isValidEmail) {
      return { isValid: false, errorMessage: "Invalid email address." };
    }

    if (!hasName) {
      return { isValid: false, errorMessage: "Name is required." };
    }

    if (!hasSiteId) {
      return { isValid: false, errorMessage: "Site ID is required." };
    }

    if (!hasValidFaultId) {
      return { isValid: false, errorMessage: "Fault ID must be provided for the selected fault type." };
    }
    return { isValid: true };
  };

  const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow = newRow as IFaultsSubscriberWithValidation;
    const rowValidity = validateSubscriberRow(updatedRow);
    if (!rowValidity.isValid) {
      oldRow.isValid = false;
      setAlertMessage({
        text: rowValidity.errorMessage ?? "Something went wrong",
        severity: "error",
      });
      return updatedRow.id == TemporaryNewEntryId
        ? updatedRow
        : (oldRow as IFaultsSubscriberWithValidation);
    }
    updatedRow.isValid = true;
    // Determine action type (create or update)
    const isNewRow = !updatedRow.id || updatedRow.id === TemporaryNewEntryId;
    const rowData = isNewRow ? { ...updatedRow, id: undefined } : updatedRow;
    const action = isNewRow ? "create" : "update";
    const actionResult = await dispatch(updateFaultsSubscriber(rowData));

    if (updateFaultsSubscriber.rejected.match(actionResult)) {
      setAlertMessage({
        text: `Failed to ${action} subscriber`,
        severity: "error",
      });
      return updatedRow;
    }

    setAlertMessage({
      text: `Subscriber ${action}d successfully`,
      severity: "success",
    });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const tableColumns: GridColDef<IFaultsSubscriberWithValidation>[] = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      width: 200,
      flex: 1,
      valueGetter: (params) => params.row.endpointDetails.emailAddress,
      valueSetter: (params) => {
        const { value, row } = params;
        return { ...row, endpointDetails: { emailAddress: value } };
      },
      editable: true,
    },
    {
      field: "siteId",
      headerName: "Site",
      headerAlign: "center",
      flex: 0.75,
      width: 100,
      valueGetter: (params) => (params.row.siteId === "*" ? "All" : params.row.siteId),
      valueSetter: (params) => {
        const { value, row } = params;
        const rowsSiteId = value === "All" ? "*" : value;
        return { ...row, siteId: rowsSiteId };
      },
      editable: true,
      type: "singleSelect",
      valueOptions: ["All", ...sitesIds],
    },
    {
      field: "faultType",
      headerName: "Fault Type",
      headerAlign: "center",
      flex: 0.5,
      width: 100,
      valueGetter: (params) => humanizeEnumValue(FaultType[params.row.faultType]),
      valueSetter: (params) => {
        const { value, row } = params;
        return { ...row, faultType: humanizedFaultTypeMap[value] };
      },
      editable: true,
      type: "singleSelect",
      valueOptions: Object.keys(humanizedFaultTypeMap),
    },
    {
      field: "faultName",
      headerName: "Fault Name",
      headerAlign: "center",
      flex: 1,
      width: 200,
      valueGetter: (params) => extractFaultName(params.row.faultType, params.row.faultId),
      valueSetter: (params) => {
        const { value, row } = params;
        const faultId: number = getFaultId(row.faultType, value);
        return { ...row, faultId: faultId };
      },
      type: "singleSelect",
      editable: true,
      valueOptions: (params) =>
        params.row?.faultType === FaultType.Alert || params.row?.faultType === FaultType.Issue
          ? ["Unassigned", ...getMetadataValues(params.row?.faultType as FaultType)]
          : [""],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const gridBoxStyle: CSSObject = {
    height: 500,
    width: "70%",
    marginTop: "10px",
    "& .actions": {
      color: "text.secondary",
    },
    "& .textPrimary": {
      color: "text.primary",
    },
  };

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <Box sx={gridBoxStyle}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              textAlign: "center",
              justifyContent: "center",
            },
          }}
          rows={rows}
          columns={tableColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          slots={{ toolbar: EditToolbar }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          localeText={{
            noRowsLabel: "No subscribers found",
          }}
        />
        <Dialog open={dialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>Are you sure you want to delete this subscriber?</DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="error">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default FaultSubscribersGrid;
