import { FC } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import classes from "./ConfigItem.module.scss";
import { btnStyling } from "../../common/Mui/MuiStyling";

interface ConfigItemProps {
  isDisable: boolean;
  isActive: boolean;
  configName: string;
  downloadHandler: React.MouseEventHandler<SVGSVGElement>;
  activateHandler: React.MouseEventHandler<HTMLButtonElement>;
  deleteHandler: React.MouseEventHandler<SVGSVGElement>;
}

const ConfigItem: FC<ConfigItemProps> = ({
  isDisable,
  isActive,
  configName,
  downloadHandler,
  activateHandler,
  deleteHandler,
}) => {
  return (
    <div className={classes.row}>
      {configName}
      <DownloadIcon
        className={
          isDisable ? `${classes.disable} ${classes["download-icon"]}` : classes["download-icon"]
        }
        sx={{ fontSize: 30 }}
        onClick={(e) => downloadHandler(e)}
      />

      <div
        className={
          isDisable ? `${classes.disable} ${classes["activate-button"]}` : classes["activate-button"]
        }
      >
        <Button size="small" disabled={isDisable} sx={btnStyling} onClick={(e) => activateHandler(e)}>
          Activate
        </Button>
      </div>
      {!isActive && <DeleteIcon onClick={deleteHandler} className={classes.icon} />}
      {isActive && <TurnedInNotIcon sx={{ color: "var(--warning-color)" }}></TurnedInNotIcon>}
    </div>
  );
};
export default ConfigItem;
