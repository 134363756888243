import axios from "axios";
import { IAddScheduledMaintenanceDto, IScheduledMaintenanceDto } from "./Models";
import APP_CONFIG from "../../app/configuration/AppConfig";

const scheduledMaintenanceApiUrl = APP_CONFIG.serviceUrls.apiHostUrl + "/ScheduleCommand/Maintenance";

export const getExistingScheduledMaintenance = async (
  siteId: string
): Promise<IScheduledMaintenanceDto[]> => {
  const url = `${scheduledMaintenanceApiUrl}/${siteId}`;
  const httpResponse = await axios.request<IScheduledMaintenanceDto[]>({
    url: url,
  });
  return httpResponse.data;
};

export const deleteScheduledMaintenance = async (
  siteId: string,
  scheduledId: string
): Promise<boolean> => {
  const url = `${scheduledMaintenanceApiUrl}/${siteId}/${scheduledId}`;
  await axios.request({
    url: url,
    method: "DELETE",
  });

  return true;
};

export const addScheduledMaintenance = async (
  ianaTimeZone: string,
  scheduledMaintenance: IAddScheduledMaintenanceDto
): Promise<IScheduledMaintenanceDto> => {
  const response = await axios.post(`${scheduledMaintenanceApiUrl}/${scheduledMaintenance.siteId}`, {
    ianaTimeZone,
    scheduledMaintenance,
  });

  return response.data;
};
