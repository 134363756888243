import { useState, useEffect } from "react";

const useNumberOfRowsToDisplay = (rowsSurroundingHeight: number, rowHeight: number) => {
  const [numberOfRowsToDisplay, setNumberOfRowsToDisplay] = useState(0);
  useEffect(() => {
    const updateNumberOfRows = () => {
      const numberOfRows = Math.floor((window.innerHeight - rowsSurroundingHeight) / rowHeight);
      setNumberOfRowsToDisplay(numberOfRows);
    };
    updateNumberOfRows();
    window.addEventListener("resize", updateNumberOfRows);
    return () => window.removeEventListener("resize", updateNumberOfRows);
  }, [rowsSurroundingHeight, rowHeight]);
  return numberOfRowsToDisplay;
};

export default useNumberOfRowsToDisplay;
