import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControlLabel, FormGroup, Skeleton, Switch } from "@mui/material";
import classes from "./FeatureFlags.module.css";
import { AppDispatch, RootState } from "../../app/Store";
import { selectSiteId } from "../../sites/SiteStore";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";
import * as api from "../../featureFlags/FeatureFlagsApi";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { fetchFeatureFlags } from "../../featureFlags/SitesFeatureFlagsStore";
import { humanizeEnumValue } from "../../../common/EnumUtils";

const FeatureFlagsEditor = () => {
  const siteId = useSelector(selectSiteId);
  const featureFlagEnabledInStore = useSelector(
    (state: RootState) => state.featureFlags.sitesFeatureFlags[siteId]
  );
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector((state: RootState) => state.user);
  const siteIds = userData.siteIds;

  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState<FeatureFlag[]>([]);

  useEffect(() => {
    setEnabledFeatureFlags(featureFlagEnabledInStore);
    setIsLoading(false);
  }, [siteId]);

  const allFeatureFlags = Object.values(FeatureFlag);

  const toggleFeatureFlag = (flag: FeatureFlag) => {
    setEnabledFeatureFlags(
      enabledFeatureFlags.includes(flag)
        ? enabledFeatureFlags.filter((f) => f !== flag)
        : enabledFeatureFlags.concat(flag)
    );
  };

  const saveChanges = async () => {
    const ffMap = allFeatureFlags.reduce(
      (accumulator, flag) => {
        accumulator[flag] = enabledFeatureFlags.includes(flag);
        return accumulator;
      },
      {} as { [flag: string]: boolean }
    );
    setIsLoading(true);
    try {
      await api.updateSiteFeatureFlagsAsync(siteId, ffMap);
      setAlertMessage({
        text: "Changes saved successfully",
        severity: "success",
      });
    } catch (error) {
      setAlertMessage({
        text: "Failed to save changes",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
    await dispatch(fetchFeatureFlags(siteIds));
  };

  return (
    <div className={classes["container"]}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {isLoading && <Skeleton variant="rounded" width="100%" height="300px" />}
      {!isLoading && (
        <>
          <FormGroup>
            {allFeatureFlags.map((flag) => (
              <FormControlLabel
                key={flag}
                control={
                  <Switch
                    checked={enabledFeatureFlags.includes(flag)}
                    onChange={() => toggleFeatureFlag(flag)}
                  />
                }
                label={formatFeatureFlagName(flag)}
              />
            ))}
          </FormGroup>
          <br />
          <Button variant="contained" onClick={saveChanges}>
            Save Changes
          </Button>
        </>
      )}
    </div>
  );
};

export default FeatureFlagsEditor;

function formatFeatureFlagName(flag: FeatureFlag): string {
  const text = humanizeEnumValue(flag) ?? flag;
  return text[0] + text.slice(1).toLowerCase();
}
