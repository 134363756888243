import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../features/app/Store";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { fetchActiveIssues } from "../features/app/store/FaultsStore";
import { selectSiteId } from "../features/sites/SiteStore";

const IssuesPoller = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siteId = useSelector(selectSiteId);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    let dispatched = dispatch(fetchActiveIssues(siteId));
    const interval = setInterval(() => {
      dispatched.abort();
      dispatched = dispatch(fetchActiveIssues(siteId));
    }, APP_CONFIG.milliSecBetweenGetIssues);
    return () => {
      dispatched.abort();
      clearInterval(interval);
    };
  }, [siteId]);
  return <></>;
};

export default IssuesPoller;
