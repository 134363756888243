
// Implementation of the ConfigurableTrackerStateSettings interface
export class TrackerStateConfig {
    stateId: string;
    dataQueryId: string;
    transformFunctionExpression: string | null;
    isEngineerOnly: boolean;
    enumTable: NumberToName[] | null;
    range: SgRange | null;
    pollingIntervalInSec: number;

    constructor(
        stateId: string,
        dataQueryId: string,
        range: SgRange | null,
        enumTable: NumberToName[] | null = null,
        transformFunctionExpression: string | null = null,
        isEngineerOnly: boolean = false,
        pollingIntervalInSec: number = 60,

    ) {
        this.stateId = stateId;
        this.dataQueryId = dataQueryId;
        this.transformFunctionExpression = transformFunctionExpression;
        this.isEngineerOnly = isEngineerOnly;
        this.pollingIntervalInSec = pollingIntervalInSec;
        this.enumTable = enumTable;
        this.range = range;
    }

    validate(): void {
        if (!this.stateId || this.stateId.trim() === '') {
            throw new Error("StateId is empty");
        }

        if (!this.dataQueryId || this.dataQueryId.trim() === '') {
            throw new Error("DataQueryId is empty");
        }

        if ((!this.enumTable || this.enumTable.length === 0) && !this.range) {
            throw new Error("EnumTable and Range are empty");
        }
    }
}

export class NumberToName{
    number: number;
    name: string;

    constructor(number: number, name: string) {
        this.number = number;
        this.name = name;
    }

    toString(): string {
        return `${this.number}:${this.name}`;
    }
}

export class SgRange {
    min: number;
    max: number;

    constructor(min: number, max: number) {
        this.min = min;
        this.max = max;
    }
}