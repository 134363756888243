export function repetitivePolling(
  methodName: string,
  method: (signal: AbortSignal) => Promise<void>,
  intervalInMilliseconds: number,
  delayOnFailure: number,
  signal: AbortSignal
) {
  let timer: NodeJS.Timeout;
  let lastPollingTime = new Date().getTime() - intervalInMilliseconds; // to start polling immediately
  async function toRepeat() {
    try {
      if (signal.aborted) {
        console.debug(`${methodName} stop signal`);
        return;
      }
      const nowMs = new Date().getTime();
      const shouldPoll =
        nowMs - lastPollingTime >= intervalInMilliseconds && document.visibilityState === "visible";
      if (shouldPoll) {
        console.debug(`Polling on ${methodName}`);
        await method(signal);
        lastPollingTime = nowMs;
        timer = setTimeout(toRepeat, intervalInMilliseconds);
      } else {
        timer = setTimeout(toRepeat, 100);
      }
    } catch (error) {
      if (signal.aborted) {
        console.debug(`${methodName} stop signal`);
        return;
      }

      timer = setTimeout(toRepeat, delayOnFailure);
      console.debug(`${methodName} next timer due to failure ${delayOnFailure}\n err ${error}`);
    }
  }

  signal.addEventListener("abort", () => clearTimeout(timer));
  toRepeat();
}

//use const controller = new AbortController();
//Pass controller.signal
export function failSafeInit(
  methodName: string,
  method: (signal: AbortSignal) => Promise<void>,
  delayOnFailure: number,
  signal: AbortSignal
) {
  let timer: NodeJS.Timeout;
  async function toRepeatOnFailure() {
    try {
      if (signal.aborted) {
        console.debug(methodName + " stop signal");
        return;
      }
      await method(signal);
    } catch (error) {
      if (signal.aborted) {
        console.debug(methodName + " stop signal");
        return;
      }

      console.info(
        methodName + " init next timer due to failure " + delayOnFailure + "\n error: " + error
      );
      timer = setTimeout(toRepeatOnFailure, delayOnFailure);
    }
  }

  console.debug("Polling on " + methodName);
  signal.addEventListener("abort", () => clearTimeout(timer));
  toRepeatOnFailure();
}
