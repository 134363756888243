import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridInputRowSelectionModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { setSelectedTrackers } from "../app/store/TrackersStore";
import { AppDispatch, RootState } from "../app/Store";
import { trackersTableColumns } from "./TrackersStatusTableConfig";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import Table from "../../common/table/Table";
import { SiteTrackerType } from "../sites/SiteModels";
import classes from "./TrackersStatusTable.module.css";
import { selectSiteId } from "../sites/SiteStore";
import { selectActiveTrackerIssues } from "../app/store/FaultsStore";

interface TrackersStatusTableProps {
  isLoading?: boolean;
}

const TrackersStatusTable: React.FC<TrackersStatusTableProps> = ({ isLoading }) => {
  const dispatch = useDispatch<AppDispatch>();
  const siteId = useSelector(selectSiteId);
  const trackersStatuses = useSelector(
    (state: RootState) => state.multiSitesTrackers.trackersStatus[siteId] || []
  );
  const selectedTrackersIds = useSelector(
    (state: RootState) => state.multiSitesTrackers.selectedTrackersIds
  );

  const trackerIssues =
    useSelector((state: RootState) => selectActiveTrackerIssues(state, siteId)) ?? [];

  const isAgriSite = useSelector(
    (state: RootState) => state.multiSitesMetadata.sites[siteId].visualInfo.isAgriPageVisible
  );
  const isSptSite = useSelector((state: RootState) => state.site.trackerType) === SiteTrackerType.SPT;

  const tableData = trackersStatuses.map((status) => {
    return {
      ...status,
      name: {
        name: status.name,
        activeIssues: trackerIssues.filter((issue) => issue.deviceId == status.id),
      },
      shadingRatio: status.shadingRatio != null ? status.shadingRatio.toFixed(2) : NO_VALUE_PLACEHOLDER,
      currentElevation:
        status.currentElevation != null
          ? Math.round(status.currentElevation).toString()
          : NO_VALUE_PLACEHOLDER,
      currentState: {
        current: status.currentState,
        target: status.targetState,
      },
      batteryStatus: {
        chargingState: status.chargingState,
        chargePercentage: status.chargePercentage,
      },
    };
  });
  const getHiddenColumns = () => {
    const res = [];
    if (!isAgriSite) {
      res.push("shadingRatio");
    }
    if (!isSptSite) {
      res.push("batteryStatus");
    }
    return res;
  };
  const hiddenColumns = getHiddenColumns();
  const selectedRows = selectedTrackersIds as GridInputRowSelectionModel;
  const setSelectedRows = (selectedRows: GridRowSelectionModel) =>
    dispatch(
      setSelectedTrackers({
        selectedTrackersIds: [...selectedRows].map(Number),
      })
    );

  return (
    <Table
      getCellClassName={(params) =>
        params.field === "batteryStatus" ? classes["battery-status-cell"] : ""
      }
      loading={isLoading}
      rows={tableData}
      columns={trackersTableColumns}
      hiddenColumns={hiddenColumns}
      checkboxSelection
      hideToolbar
      hideFooter
      hideBorder
      externalRowsSelectionProps={{ selectedRows, setSelectedRows }}
    />
  );
};
export default TrackersStatusTable;
