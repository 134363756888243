import { InstallDesktop, Settings } from "@mui/icons-material";
import { SimpleSgTabs } from "../../../SolarGikLib/tabs/SgTabs";
import { ITab } from "../../../SolarGikLib/tabs/TabsModels";
import DasDeploy from "./DasDeploy";
import DasConfiguration from "./DasConfiguration";
import Card from "../../../SolarGikLib/cards/Card";

const DasConfigTab = () => {
  const tabs: ITab[] = [
    {
      label: "Deployment",
      key: "DasDeploy",
      icon: <InstallDesktop />,
      iconPosition: "start",
      content: (
        <Card>
          <DasDeploy />
        </Card>
      ),
      contentLayout: "half-width",
    },
    {
      label: "Configuration",
      key: "DasConfiguration",
      icon: <Settings />,
      iconPosition: "start",
      content: <DasConfiguration />,
      contentLayout: "max-size",
    },
  ];

  return <SimpleSgTabs tabs={tabs} />;
};

export default DasConfigTab;
