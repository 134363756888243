import classes from "./MaintenanceReasonRow.module.css";

export interface IMaintenanceReasonRowProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
}
export const MaintenanceReasonRow: React.FC<IMaintenanceReasonRowProps> = (
  props: IMaintenanceReasonRowProps
) => {
  const { Icon, title } = props;
  return (
    <div className={classes["reason-row"]}>
      <Icon />
      <span>{title}</span>
    </div>
  );
};

export default MaintenanceReasonRow;
