import { FC, useState } from "react";
import { useSelector } from "react-redux";
import LightTooltip from "../../../SolarGikLib/tooltip/LightTooltip";
import { RootState } from "../../app/Store";
import { selectFieldState, selectSafetyReason } from "../../app/store/MultisiteTagsStore";
import { formatTimeForTooltip, NO_DATA_FOR_TIME } from "../DataPointTooltipContent";
import { SafetyReason } from "../../banner/emergency/EmergencyModel";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory, ReasonIcons } from "../../../SolarGikLib/icons/IconsModels";
import {
  FieldStateTooltipContent,
  ISafteyReasonTooltipItem,
} from "../../../SolarGikLib/tooltip/FieldStateTooltipContent";
import { useInterval } from "../../../common/Hooks/useInterval";

export interface FieldStateTooltipProps {
  siteId: string;
  children?: React.ReactNode;
  onOpen?: () => void;
}

const FieldStateTooltip: FC<FieldStateTooltipProps> = ({ onOpen, children, siteId }) => {
  return (
    <LightTooltip onOpen={onOpen} title={<FieldStateTooltipData siteId={siteId} />}>
      <div>{children}</div>
    </LightTooltip>
  );
};

const FieldStateTooltipData: FC<{ siteId: string }> = ({ siteId }) => {
  const safetyReason = useSelector((state: RootState) => selectSafetyReason(state, siteId));

  const fieldState = useSelector((state: RootState) => selectFieldState(state, siteId));

  const updateFormattedTime = () => formatTimeForTooltip(fieldState.time, false);

  const [formattedTime, setFormattedTime] = useState(updateFormattedTime);
  useInterval(() => setFormattedTime(updateFormattedTime()), 1000);
  const timestampMessage = formattedTime !== NO_DATA_FOR_TIME ? `Last Updated: ${formattedTime}` : "";
  const tooltipInfo: ISafteyReasonTooltipItem[] = [];
  const safteyReasonsList = getSafetyReasonInfo(safetyReason);
  if (safteyReasonsList.length == 0) {
    return <FieldStateTooltipContent name={""} info={tooltipInfo} timestamp={timestampMessage} />;
  }
  tooltipInfo.push(...safteyReasonsList);
  return (
    <FieldStateTooltipContent name={"Field State"} info={tooltipInfo} timestamp={timestampMessage} />
  );
};

const windIcon = getIcons(ReasonIcons.Wind, IconCategory.SafetyReason);
const snowIcon = getIcons(ReasonIcons.Snow, IconCategory.SafetyReason);
const upsIcon = getIcons(ReasonIcons.Ups, IconCategory.SafetyReason);
const userIcon = getIcons(ReasonIcons.User, IconCategory.SafetyReason);
const engineerIcon = getIcons(ReasonIcons.Engineer, IconCategory.SafetyReason);

const reasons: { [key in SafetyReason]?: ISafteyReasonTooltipItem } = {
  [SafetyReason.WindSafe]: { text: "System Wind Safe", icon: windIcon },
  [SafetyReason.UserSafe]: { text: "User Safe", icon: userIcon },
  [SafetyReason.EngineerSafe]: { text: "Engineer Safe", icon: engineerIcon },
  [SafetyReason.UpsSafe]: { text: "System UPS Safe", icon: upsIcon },
  [SafetyReason.SnowSafe]: { text: "System Snow Safe", icon: snowIcon },
};

export const getSafetyReasonInfo = (flags: number): ISafteyReasonTooltipItem[] => {
  if (flags <= 0) {
    return [];
  }
  const texts: ISafteyReasonTooltipItem[] = [];
  for (const [key, item] of Object.entries(reasons)) {
    const reasonFlag = parseInt(key, 10);
    if ((flags & reasonFlag) !== 0 && item) {
      texts.push(item);
    }
  }

  return texts;
};

export default FieldStateTooltip;
