import { FC } from "react";

import LightTooltip from "../../../SolarGikLib/tooltip/LightTooltip";
import DataPointTooltipContent, { TooltipContentProps } from "../DataPointTooltipContent";

export interface TooltipProps extends TooltipContentProps {
  children?: React.ReactNode;
  onOpen?: () => void;
}

const DataPointTooltip: FC<TooltipProps> = ({ onOpen, children, ...rest }) => {
  return (
    <LightTooltip onOpen={onOpen} title={<DataPointTooltipContent {...rest} />}>
      <div>{children}</div>
    </LightTooltip>
  );
};

export default DataPointTooltip;
