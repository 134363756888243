import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchFaultsSubscribers } from "../../app/store/FaultsSubscribersStore";
import FaultSubscribersGrid from "./FaultSubscribersGrid";

const FaultsSubscribers = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchFaultsSubscribers());
  }, []);

  const subscribers = useSelector((state: RootState) => state.faults.subscribers.subscribers) ?? [];

  return (
    <>
      <h1>Fault Notification Subscribers</h1>
      <FaultSubscribersGrid subscribers={subscribers} />
    </>
  );
};
export default FaultsSubscribers;
