import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { ICameraId, ICameraMetadataWithParameters, IUpdateCameraParametersModel } from "./camerasModels";

export async function fetchSnapshots(
  siteId: string,
  camera: ICameraId,
  signal: AbortSignal
): Promise<string> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/snapshot`;
  const httpResponse = await axios.get(url, {
    signal,
    responseType: "blob",
  });
  const reader = new window.FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.readAsDataURL(httpResponse.data);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error("Failed to load image"));
  });
}

export async function moveCamera(
  siteId: string,
  camera: ICameraId,
  x: number,
  y: number,
  milliseconds: number,
  signal: AbortSignal
): Promise<void> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/move`;
  const args = { x, y, milliseconds };
  await axios.post<void>(url, args, { signal });
}

export async function healthCheck(
  siteId: string,
  camera: ICameraId,
  signal: AbortSignal
): Promise<{ error?: string }> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/health`;
  const response = await axios.get<{ detail?: string }>(url, {
    signal,
    validateStatus: () => true,
  });
  return {
    error: response.status == 200 ? undefined : (response.data?.detail ?? "unknown error"),
  };
}

export async function getAgentIdFromIotHubDeviceId(deviceId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/agentId/${deviceId}`;
  const res = (await axios.get<number | "">(url)).data;
  if (!res) {
    return null;
  }
  return res;
}

export async function addNewAgent(siteId: string, iotHubDeviceId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/agent?iotHubDeviceId=${iotHubDeviceId}`;
  return (await axios.post<number>(url)).data;
}

export async function getSiteAgentsIds(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/agents`;
  return (await axios.get<number[]>(url)).data;
}

export async function getSiteCamerasParameters(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/cameras/parameters`;
  return (await axios.get<ICameraMetadataWithParameters[]>(url)).data;
}

export async function setCameraParameters(siteId: string, parameters: IUpdateCameraParametersModel) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/cameras/parameters`;
  await axios.post(url, parameters);
}
