import { TextField } from "@mui/material";
import SolarGikAutocomplete from "../../SolarGikLib/fields/Autocomplete";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import classes from "./SprinklerDurationSelector.module.css";
import { inputStyle } from "../../SolarGikLib/fields/InputStyle";
import { is150PercentScreen } from "../../common/WindowUtils";

interface ISprinklerDurationSelectorProps {
  onDurationSelection: (value: number) => void;
}

export const SprinklerDurationSelector = ({ onDurationSelection }: ISprinklerDurationSelectorProps) => {
  return (
    <>
      <div className={`${TextEnum.h5} ${classes["header"]}`}> For How Long? </div>
      <div className={classes["duration-selector-container"]}>
        <SolarGikAutocomplete
          size="small"
          textSize={TextEnum.h6}
          options={[...Array(10).keys()].map((i) => i + 1)}
          renderInputFunc={(params) => (
            <TextField
              {...params}
              size="medium"
              label="Duration"
              placeholder="Minutes"
              {...params}
              sx={inputStyle}
              InputLabelProps={{ classes: { root: TextEnum.h6 } }}
            />
          )}
          onChange={(_event, value) => {
            if (value) {
              onDurationSelection(value);
            }
          }}
          key={"duration"}
          id={"duration"}
          width={is150PercentScreen() ? "110px" : "120px"}
          listboxProps={{
            style: {
              maxHeight: is150PercentScreen() ? "150px" : "200px",
              overflow: "auto",
            },
          }}
        />
      </div>
    </>
  );
};
