// src/UnityManager.ts

import {IProgressHandler} from "../global";

export interface IUnityLoadingProvider {
    onLoadProgress: IProgressHandler | null;
    solarSightUnityPrefixPath: string;
    getSolarSightCanvas: () => HTMLCanvasElement;
}

export interface UnityInstance {
    canvas: HTMLCanvasElement;
    SendMessage: (gameObject: string, methodName: string, parameter: string|undefined) => void;
    Quit: () => void;
}

export type OnCleanUpDelegate = () => void;

export default class UnityManager {
    #unityLoadingHandler: IUnityLoadingProvider;
    #unityInstance: UnityInstance | null = null;
    #onCleanUp: OnCleanUpDelegate[] = [];
    #mutationObserver: MutationObserver | null = null;

    constructor(unityLoadingHandler: IUnityLoadingProvider) {
        this.#unityLoadingHandler = unityLoadingHandler;
    }

    public async initUnityAsync(config: any): Promise<UnityInstance> {
        const onLoadProgress: IProgressHandler = this.#unityLoadingHandler.onLoadProgress || ((fraction) => {
            console.log(`Loading progress: ${(fraction * 100).toFixed(2)}%`);
        });
        
        const canvas = this.#unityLoadingHandler.getSolarSightCanvas();
        if (!canvas) {
            const errorMsg = "Unity canvas element not found.";
            console.error(errorMsg);
            throw new Error(errorMsg);
        }

        this.#unityInstance = await createUnityInstance(canvas, config, onLoadProgress);
        if(!this.#unityInstance) {
            throw new Error("Failed to create Unity instance.");
        }

        this.#unityInstance.canvas = canvas;

        this.setupCleanupObserver();

        console.debug("Unity instance created successfully.");
        return this.#unityInstance;
    }

    public addCleanUpDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp.push(delegate);
    }
    public removeCleanupDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp = this.#onCleanUp.filter(l => l !== delegate);
    }

    private cleanup(): void {
        if (this.#unityInstance) {
            this.#unityInstance.Quit();
            this.#unityInstance = null;
        }

        if (this.#mutationObserver) {
            this.#mutationObserver.disconnect();
            this.#mutationObserver = null;
        }

        this.#onCleanUp.forEach(delegate => delegate());
        this.#onCleanUp = [];
    }

    private setupCleanupObserver(): void {
        if (!this.#unityInstance) return;

        const parent = this.#unityInstance.canvas.parentNode;
        if (!parent) {
            console.warn("Canvas has no parent node to observe for removal.");
            return;
        }

        this.#mutationObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                for (const removedNode of mutation.removedNodes) {
                    if (removedNode === this.#unityInstance?.canvas) {
                        console.log(`Canvas '${this.#unityInstance.canvas.id}' removed, performing cleanup.`);
                        this.cleanup();
                        return;
                    }
                }
            }
        });

        this.#mutationObserver.observe(parent, { childList: true });
    }
}