import React from "react";
import styles from "./SgBadge.module.css";
import { TextEnum } from "../TextStyles";

interface SgBadgeProps {
  children: React.ReactNode;
  className?: string;
}

export const SgBadge: React.FC<SgBadgeProps> = ({ children, className }) => {
  return <span className={`${styles.badge} ${TextEnum.h6} ${className}`}>{children}</span>;
};
