import { FC } from "react";
import { formatDistanceToNow, subMinutes } from "date-fns";
import { TrackerNamesMap } from "../../sites/SiteModels";
import { TrackerNameById, tryGetTrackerName } from "../../trackers/TrackerName";
import { IAlertMetadata, ComponentType, SafeReason } from "../DTOs";
import { AlertUnion } from "../Models";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { TokenTransformersMap, transformDescription, ValueTransformer } from "../FaultDescriptionUtils";
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import classes from "./AlertDescription.module.css";

interface IAlertDescriptionProps {
  alert: AlertUnion;
  metadata: IAlertMetadata;
}

export const AlertDescription: FC<IAlertDescriptionProps> = ({ alert, metadata }) => {
  const transformers = createAlertDescriptionTokenTransformers(alert, (index, token, value) => {
    return token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker ? (
      <TrackerNameById id={value as number} />
    ) : (
      <span key={index}>{value}</span>
    );
  });
  const elements = transformDescription(metadata.openStateDescription, transformers);
  return (
    <LightTooltipOnEllipsis title={elements} customTooltipSx={{ padding: "1.2em", fontSize: 14 }}>
      <div className={classes.description}>{elements}</div>
    </LightTooltipOnEllipsis>
  );
};

export function formatAlertDescription(
  alert: AlertUnion,
  metadata: IAlertMetadata,
  trackerNamesMap: TrackerNamesMap
): string {
  const transformers = createAlertDescriptionTokenTransformers(alert, (_index, token, value) =>
    token === "ComponentNumber" && metadata.componentType === ComponentType.Tracker
      ? tryGetTrackerName(value as number, trackerNamesMap)
      : (value?.toString() ?? "")
  );
  return transformDescription(metadata.openStateDescription, transformers).join("");
}

function createAlertDescriptionTokenTransformers<T>(
  alert: AlertUnion,
  transformValue: ValueTransformer<T>
): TokenTransformersMap<T> {
  if (alert.kind === "single-device") {
    return {
      ComponentNumber: (index) => transformValue(index, "ComponentNumber", alert.deviceId),
      AdditionalData_Minutes: (index) =>
        transformValue(
          index,
          "AdditionalData_Minutes",
          alert.additionalInfo && formatMinutesAgo(alert.additionalInfo)
        ),
      AdditionalData_Percent: (index) =>
        transformValue(
          index,
          "AdditionalData_Percent",
          alert.additionalInfo && fractionToPercentage(alert.additionalInfo)
        ),
      AdditionalData_SafeSource: (index) =>
        transformValue(
          index,
          "AdditionalData_SafeSource",
          alert.additionalInfo && humanizeEnumValue(SafeReason[alert.additionalInfo])
        ),
      RawText: (index, text) => transformValue(index, "RawText", text),
    };
  }
  if (alert.kind === "aggregated") {
    return {
      Percent: (index) => transformValue(index, "Percent", fractionToPercentage(alert.errorFraction)),
      Total: (index) => transformValue(index, "Total", alert.deviceIds.length),
      RawText: (index, text) => transformValue(index, "RawText", text),
    };
  }
  return {
    RawText: (index, text) => transformValue(index, "RawText", text),
  };
}

const formatMinutesAgo = (minutes: number) =>
  formatDistanceToNow(subMinutes(new Date(), minutes), { addSuffix: false });

const fractionToPercentage = (fraction: number) => Math.round(fraction * 100);
