import React, { FC } from "react";

interface HeaderWithIconProps {
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  sx?: React.CSSProperties;
}

const HeaderWithIcon: FC<HeaderWithIconProps> = ({ label, icon: Icon, sx }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon style={sx || { marginTop: "-0.1rem", marginLeft: "-1rem" }} />
      {label}
    </div>
  );
};

export default HeaderWithIcon;
