import { useSelector } from "react-redux";
import classes from "./Dashboard.module.css";
import { RootState } from "../features/app/Store";
import CardSiteStyledLineChartWrapper from "../features/charts_ui/CardSiteStyledLineChartWrapper";
import {
  createWeatherChartConfig,
  createDailyProductionChartConfig,
  createCurrentProductionChartConfig,
  createPerformanceChartConfig,
  POWER_GENERATION_TITLE,
  WEATHER_TITLE,
  CURRENT_PRODUCTION_TITLE,
  PERFORMANCE_TITLE,
  CHART_DEFAULT_HEIGHT,
} from "../features/common_chart_config/ChartConfig";
import TableDataPoint from "../features/data_point/TableDataPoint";
import TagsNames from "../features/data_point/TagsNames";
import Card from "../SolarGikLib/cards/Card";
import TableStaticDataPoint from "../features/data_point/TableStaticDataPoint";
import { selectInverterIds, selectTrackerIds } from "../features/sites/SiteStore";

const Dashboard = () => {
  const inverterIds = useSelector(selectInverterIds);
  const siteMetadata = useSelector((state: RootState) => state.site);
  const currentProductionChartConfig = createCurrentProductionChartConfig(inverterIds, siteMetadata);
  const performanceChartConfig = createPerformanceChartConfig(siteMetadata);
  const weatherChartConfig = createWeatherChartConfig(siteMetadata);
  const dailyProductionChartConfig = createDailyProductionChartConfig(siteMetadata);
  const trackersCount = useSelector(selectTrackerIds).length;
  return (
    <div className={classes["grid-container"]}>
      <div className={classes["cards-row1"]}>
        <Card title={POWER_GENERATION_TITLE}>
          <div className={classes["data-point-wrapper"]}>
            <TableDataPoint tagName={TagsNames.SITE_AC_CURRENT_POWER} siteId={siteMetadata.siteId} />
            <TableDataPoint tagName={TagsNames.SITE_DC_CURRENT_POWER} siteId={siteMetadata.siteId} />
            <TableDataPoint
              tagName={TagsNames.SITE_DAILY_AC_POWER}
              titleOverride="Daily AC"
              nameOverride="Daily AC Power"
              siteId={siteMetadata.siteId}
            />
          </div>
        </Card>
      </div>
      <div className={classes["cards-row2"]}>
        <Card title={PERFORMANCE_TITLE}>
          <div className={classes["data-point-wrapper"]}>
            <TableDataPoint
              tagName={TagsNames.DAILY_SP_EFFICIENCY}
              nameOverride="Performance Index"
              siteId={siteMetadata.siteId}
            />
            <TableDataPoint tagName={TagsNames.DAILY_SP_PRODUCTION} siteId={siteMetadata.siteId} />
            <TableDataPoint
              tagName={TagsNames.MOMENT_POTENTIAL_POWER}
              nameOverride="Potential Production"
              siteId={siteMetadata.siteId}
            />
          </div>
        </Card>
      </div>
      <div className={` ${classes["cards-row3"]}`}>
        <Card title={WEATHER_TITLE}>
          <div className={classes["data-point-wrapper"]}>
            <TableDataPoint tagName={TagsNames.WIND} siteId={siteMetadata.siteId} />
            <TableDataPoint tagName={TagsNames.GHI} siteId={siteMetadata.siteId} />
            <TableDataPoint tagName={TagsNames.TEMP} siteId={siteMetadata.siteId} />
          </div>
        </Card>
      </div>
      <div className={` ${classes["cards-row4"]}`}>
        <Card title="Trackers">
          <div className={classes["data-point-wrapper"]}>
            <TableStaticDataPoint
              title={"Total"}
              value={trackersCount}
              unit={"#"}
            ></TableStaticDataPoint>
            <TableDataPoint
              tagName={TagsNames.TRACKERS_TRACKING_COUNT}
              titleOverride="Tracking"
              siteId={siteMetadata.siteId}
            />
            <TableDataPoint
              tagName={TagsNames.TRACKERS_MANUAL_COUNT}
              titleOverride="Manual"
              siteId={siteMetadata.siteId}
            />
          </div>
        </Card>
      </div>
      <div className={classes["daily-production-chart"]}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={currentProductionChartConfig.siteId}
          title={POWER_GENERATION_TITLE}
          tagInfo={currentProductionChartConfig.tags}
          samplingInterval={currentProductionChartConfig.samplingInterval}
          timeRange={{
            start: currentProductionChartConfig.fromDate,
            end: currentProductionChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
      <div className={classes["weather-chart"]}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={weatherChartConfig.siteId}
          title={WEATHER_TITLE}
          tagInfo={weatherChartConfig.tags}
          samplingInterval={weatherChartConfig.samplingInterval}
          timeRange={{
            start: weatherChartConfig.fromDate,
            end: weatherChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
      <div className={classes["production-chart"]}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={dailyProductionChartConfig.siteId}
          title={CURRENT_PRODUCTION_TITLE}
          tagInfo={dailyProductionChartConfig.tags}
          samplingInterval={dailyProductionChartConfig.samplingInterval}
          timeRange={{
            start: dailyProductionChartConfig.fromDate,
            end: dailyProductionChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
      <div className={classes["performance-chart"]}>
        <CardSiteStyledLineChartWrapper
          showChartTitle={false}
          siteId={performanceChartConfig.siteId}
          title={PERFORMANCE_TITLE}
          tagInfo={performanceChartConfig.tags}
          samplingInterval={performanceChartConfig.samplingInterval}
          timeRange={{
            start: currentProductionChartConfig.fromDate,
            end: currentProductionChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
    </div>
  );
};
export default Dashboard;
