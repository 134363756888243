import { FC, useEffect, useState } from "react";

import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridDensity,
  GridEventListener,
  GridExperimentalFeatures,
  GridFilterModel,
  GridSortModel,
  GridState,
  GridValueGetterParams,
  getGridStringOperators,
  gridClasses,
} from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import CellItem from "./cellItems/CellItem";
import CellItemEfficiency from "./cellItems/CellItemEfficiency";
import CellItemErrorsCount from "./cellItems/CellItemErrorsCount";
import IconCellItem from "./cellItems/IconCellItem";
import SystemStatusCellItem from "./cellItems/SystemStatusCellItem";
import { HeaderGroupItem } from "./HeaderGroupItem";
import {
  ErrorType,
  IIssuesSeverityCounts,
  ISiteUIRowData,
  ITagDataWithMetaUnit,
} from "./MultiSiteModel";
import classes from "./MultiSiteTableUi.module.css";
import TagHeaderTooltip from "./TagHeaderTooltip";
import Table from "../../common/table/Table";
import getIcons from "../../SolarGikLib/icons/Icons";
import { AuxiliariesIcons, ErrorIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { convertValueToFieldState } from "../app/TagsToEnumTable";
import TagsNames from "../data_point/TagsNames";
import LightTooltip from "../../SolarGikLib/tooltip/LightTooltip";
import LightTooltipContent from "../../SolarGikLib/tooltip/LightTooltipContent";
import { TagTimeValidity } from "../data_point/models/TagsModels";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import SiteNameComponent, {
  useSiteNamesFormatter,
  SiteNameFormatterFunc,
} from "../siteName/SiteNameComponent";
import { PageNames } from "../../pages/PageNames";
import useNumberOfRowsToDisplay from "../../common/Hooks/useNumberOfRowsToDisplay";
import { FaultsTabOptions, setActiveTab } from "../app/store/FaultsPageStore";
import { createTableTheme } from "../../common/Mui/StyledDataGridTable";
import MultiSiteSiteNameHeader, { IMultiSiteSiteNameHeaderProps } from "./MultiSiteSiteNameHeader";

interface MultiSiteTableUiProps {
  data: ISiteUIRowData[];
}

function nameof<T>(key: keyof T & string): string {
  return key;
}
const rowHeightsPerDensity = {
  compact: 33,
  standard: 53,
  comfortable: 72,
};
const rowsSurroundingHeight = 276;

const multiSiteStyledTable = styled(DataGrid)((theme) => {
  const newTheme = createTableTheme(theme);
  (newTheme[`& .${gridClasses.columnHeader}`] as { paddingLeft: string }).paddingLeft = "10px";
  return newTheme;
});

const MultiSiteTableUi: FC<MultiSiteTableUiProps> = ({ data }) => {
  const [gridDensity, setGridDensity] = useState<GridDensity>("standard");
  const [rowHeight, setRowHeight] = useState(rowHeightsPerDensity[gridDensity]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "siteId", sort: "asc" }]);
  const [rowsFilteredByName, setRowsFilteredByName] = useState(data);
  //sortModel:
  const dispatch = useDispatch();
  useEffect(() => setRowHeight(rowHeightsPerDensity[gridDensity]), [gridDensity]);

  const pageSize = useNumberOfRowsToDisplay(rowsSurroundingHeight, rowHeight);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const siteNameFormatter = useSiteNamesFormatter();
  const handlePaginationChange = (params: { page: number; pageSize: number }) => {
    setCurrentPage(params.page + 1);
  };

  const navigate = useNavigate();

  const onCellClick: GridEventListener<"cellClick"> = (params) => {
    if (
      params.field == nameof<IIssuesSeverityCounts>("amountOfIssuesLowSeverity") ||
      params.field == nameof<IIssuesSeverityCounts>("amountOfIssuesMildSeverity") ||
      params.field == nameof<IIssuesSeverityCounts>("amountOfIssuesHighSeverity")
    ) {
      dispatch(setActiveTab(FaultsTabOptions.Issues));
      navigate(`/${params.row.siteId}/${PageNames.Alerts}`);
    } else {
      navigate(`/${params.row.siteId}/${PageNames.Dashboard}`);
    }
  };
  useEffect(() => {
    setRowsFilteredByName(
      data.filter((row) =>
        siteNameFormatter(row.siteId).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data]);

  return (
    <div className={classes.table}>
      <Table
        customTableComponent={multiSiteStyledTable}
        getRowId={(row) => row.siteId}
        paginationMode="client"
        onPaginationModelChange={handlePaginationChange}
        autoHeight
        paginationModel={{
          page: currentPage - 1,
          pageSize: pageSize,
        }}
        onStateChange={(state: GridState) => setGridDensity(state.density.value)}
        hideToolbar={false}
        hideFooterPagination={data.length <= pageSize}
        rows={rowsFilteredByName}
        columns={multiSitesTableColumns(siteNameFormatter, {
          searchTerm,
          setSearchTerm,
          isSearchMode,
          setIsSearchMode,
        })}
        onCellClick={onCellClick}
        experimentalFeatures={{ columnGrouping: true } as GridExperimentalFeatures}
        columnGroupingModel={columnGroupingModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        rowCount={data.length}
        customUI={{
          "& .MuiDataGrid-iconButtonContainer": {
            display: "none",
          },
        }}
      />
    </div>
  );
};

export default MultiSiteTableUi;

const InverterIcon = getIcons(AuxiliariesIcons.Inverter, IconCategory.Auxiliaries);
const TrackerIcon = getIcons(AuxiliariesIcons.Tracker, IconCategory.Auxiliaries);
const SeverityHighIcon = getIcons(ErrorIcons.MultisiteHighSeverityError, IconCategory.Error);
const SeverityMildIcon = getIcons(ErrorIcons.MultisiteMildSeverityError, IconCategory.Error);

const SeverityLowIcon = getIcons(ErrorIcons.MultisiteLowSeverityError, IconCategory.Error);

export const defaultValueGetter = ({
  value,
}: GridValueGetterParams<ISiteUIRowData, ITagDataWithMetaUnit>) => {
  if (value?.value == null || value?.timeValidity === TagTimeValidity.Invalid) {
    return NO_VALUE_PLACEHOLDER;
  }
  return typeof value.value === "number"
    ? Math.round(value.value * 10) / 10 // round to one decimal point
    : value.value;
};

const filterOperators = getGridStringOperators().filter(
  (operator) => operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
);

const GridColPrototype: Partial<GridColDef<ISiteUIRowData>> = {
  filterOperators: filterOperators,
  renderCell: CellItem,
  valueGetter: defaultValueGetter,
  headerClassName: "main-header",
};

const multiSitesTableColumns = (
  siteNameFormatter: SiteNameFormatterFunc,
  searchBoxProps: IMultiSiteSiteNameHeaderProps
): GridColDef<ISiteUIRowData>[] => [
  {
    field: "siteId",
    headerName: "Name",
    flex: 1,
    renderHeader() {
      return <MultiSiteSiteNameHeader {...searchBoxProps} />;
    },
    renderCell: (params) => <SiteNameComponent siteId={params.row.siteId} />,
    valueGetter: (params) => siteNameFormatter(params.value),
    cellClassName: "right-border " + classes["site-name"],
    headerClassName:
      "right-border-and-bottom" +
      (searchBoxProps.isSearchMode
        ? ` ${classes["site-name-header"]}  ${classes["in-search-mode"]}`
        : ""),
    filterable: false,
    disableColumnMenu: searchBoxProps.isSearchMode,
    hideable: false,
  },
  {
    ...GridColPrototype,
    field: TagsNames.AGGR_INVERTERS_STATUS,
    headerName: "Inverters' Status",
    flex: 0.4,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.AGGR_INVERTERS_STATUS}>
          <div className={classes["inverter-icon"]}>
            <IconCellItem Icon={InverterIcon} />
          </div>
        </TagHeaderTooltip>
      );
    },
    renderCell: SystemStatusCellItem,
  },
  {
    ...GridColPrototype,
    field: TagsNames.AGGR_TRACKERS_STATUS,
    headerName: "Trackers' Status",
    flex: 0.4,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.AGGR_TRACKERS_STATUS}>
          <div className={classes["tracker-icon"]}>
            <IconCellItem Icon={TrackerIcon} />
          </div>
        </TagHeaderTooltip>
      );
    },
    renderCell: SystemStatusCellItem,
    cellClassName: "right-border",
    headerClassName: "right-border-and-bottom",
  },
  {
    ...GridColPrototype,
    field: TagsNames.MCS_FIELD_STATE,
    headerName: "Field State",
    renderHeader() {
      return <TagHeaderTooltip tagName={TagsNames.MCS_FIELD_STATE}>{"Field State"}</TagHeaderTooltip>;
    },
    flex: 0.7,
    valueGetter: ({ value }) => convertValueToFieldState(value?.value),
    cellClassName: "right-border",
    headerClassName: "right-border-and-bottom",
  },
  {
    ...GridColPrototype,
    field: TagsNames.SITE_AC_CURRENT_POWER,
    headerName: "AC Power",
    flex: 0.7,
    renderHeader() {
      return <TagHeaderTooltip tagName={TagsNames.SITE_AC_CURRENT_POWER}>{"AC Power"}</TagHeaderTooltip>;
    },
  },
  {
    ...GridColPrototype,
    field: TagsNames.MOMENT_POTENTIAL_POWER,
    headerName: "Potential AC",
    flex: 0.7,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.MOMENT_POTENTIAL_POWER} displayName="Potential Production">
          {"Potential AC"}
        </TagHeaderTooltip>
      );
    },
  },
  {
    ...GridColPrototype,
    field: TagsNames.DAILY_SP_EFFICIENCY,
    headerName: "Perf' Index",
    flex: 0.7,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.DAILY_SP_EFFICIENCY} displayName="Performance Index">
          {"Perf' Index"}
        </TagHeaderTooltip>
      );
    },
    renderCell: CellItemEfficiency,
  },
  {
    ...GridColPrototype,
    field: TagsNames.DAILY_SP_PRODUCTION,
    headerName: "Specific Prod'",
    flex: 0.7,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.DAILY_SP_PRODUCTION}>{"Specific Prod'"}</TagHeaderTooltip>
      );
    },
    cellClassName: "right-border",
    headerClassName: "right-border-and-bottom",
  },
  {
    ...GridColPrototype,
    field: TagsNames.GHI,
    headerName: "GHI",
    flex: 0.55,
    renderHeader() {
      return (
        <TagHeaderTooltip tagName={TagsNames.GHI} displayName="Global Horizontal Irradiance">
          {"GHI"}
        </TagHeaderTooltip>
      );
    },
  },
  {
    ...GridColPrototype,
    field: TagsNames.WIND,
    headerName: "Wind",
    flex: 0.55,
    renderHeader() {
      return <TagHeaderTooltip tagName={TagsNames.WIND}>{"Wind"}</TagHeaderTooltip>;
    },
    cellClassName: "right-border",
    headerClassName: "right-border-and-bottom",
  },
  {
    field: "amountOfIssuesHighSeverity",
    headerName: "High severity issues",
    renderHeader() {
      return (
        <LightTooltip title={<LightTooltipContent name="High Severity" info={[]} />}>
          <div className={classes["header-icon"]}>{<IconCellItem Icon={SeverityHighIcon} />}</div>
        </LightTooltip>
      );
    },
    flex: 0.4,
    renderCell: (params) => {
      return <CellItemErrorsCount params={params} errorType={ErrorType.High} />;
    },
    filterOperators: filterOperators,
  },
  {
    field: "amountOfIssuesMildSeverity",
    headerName: "Medium severity issues",
    renderHeader() {
      return (
        <LightTooltip title={<LightTooltipContent name="Medium Severity" info={[]} />}>
          <div className={classes["header-icon"]}>{<IconCellItem Icon={SeverityMildIcon} />}</div>
        </LightTooltip>
      );
    },
    flex: 0.4,
    renderCell: (params) => {
      return <CellItemErrorsCount params={params} errorType={ErrorType.Mild} />;
    },
    filterOperators: filterOperators,
  },
  {
    field: "amountOfIssuesLowSeverity",
    headerName: "Low severity issues",
    renderHeader() {
      return (
        <LightTooltip title={<LightTooltipContent name="Low Severity" info={[]} />}>
          <div className={classes["header-icon"]}>{<IconCellItem Icon={SeverityLowIcon} />}</div>
        </LightTooltip>
      );
    },
    flex: 0.4,
    renderCell: (params) => {
      return <CellItemErrorsCount params={params} errorType={ErrorType.Low} />;
    },
    filterOperators: filterOperators,
  },
];

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "Site-Group",
    headerName: "Site",
    headerClassName: "right-border",
    renderHeaderGroup: HeaderGroupItem,
    children: [
      {
        field: "siteId",
        headerName: "Name",
      },
    ],
  },
  {
    groupId: "Site-Status-Group",
    headerName: "Site Status",
    headerClassName: "right-border",
    renderHeaderGroup: HeaderGroupItem,
    children: [
      {
        field: TagsNames.AGGR_INVERTERS_STATUS,
      },
      {
        field: TagsNames.AGGR_TRACKERS_STATUS,
      },
    ],
  },
  {
    groupId: "Field-Status-Group",
    headerName: "Field State",
    renderHeaderGroup: HeaderGroupItem,
    headerClassName: "right-border",
    children: [
      {
        field: TagsNames.MCS_FIELD_STATE,
      },
    ],
  },
  {
    groupId: "Energy-Group",
    headerName: "Energy",
    renderHeaderGroup: HeaderGroupItem,
    headerClassName: "right-border",
    children: [
      {
        field: TagsNames.SITE_AC_CURRENT_POWER,
      },

      {
        field: TagsNames.MOMENT_POTENTIAL_POWER,
      },
      {
        field: TagsNames.DAILY_SP_EFFICIENCY,
      },
      {
        field: TagsNames.DAILY_SP_PRODUCTION,
      },
    ],
  },
  {
    groupId: "Weather-Group",
    headerName: "Weather",
    renderHeaderGroup: HeaderGroupItem,
    headerClassName: "right-border",
    children: [
      {
        field: TagsNames.GHI,
      },
      {
        field: TagsNames.WIND,
      },
    ],
  },
  {
    groupId: "Issues-Group",
    headerName: "Issues",
    renderHeaderGroup: HeaderGroupItem,
    headerClassName: "right-border",
    children: [
      { field: "amountOfIssuesMildSeverity" },
      { field: "amountOfIssuesLowSeverity" },
      { field: "amountOfIssuesHighSeverity" },
    ],
  },
];
