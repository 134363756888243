import Card from "../../SolarGikLib/cards/Card";
import TrackersStatusTable from "./TrackersStatusTable";
import TrackersCommand from "./TrackersCommand/TrackersCommand";
import classes from "./TrackersTableView.module.css";

interface ITrackersTableViewProps {
  isLoading: boolean;
}

export const TrackersTableView = ({ isLoading }: ITrackersTableViewProps) => {
  return (
    <div className={classes["grid-container"]}>
      <div className={`${classes["table-card"]}`}>
        <Card contentStyle={{ marginBottom: 0 }}>
          <TrackersStatusTable isLoading={isLoading} />
        </Card>
      </div>
      <div className={`${classes["side-card"]}`}>
        <Card
          title="Command"
          contentStyle={{
            marginBottom: 0,
            alignItems: "flex-start",
            marginTop: "5px",
          }}
        >
          <TrackersCommand />
        </Card>
      </div>
    </div>
  );
};
