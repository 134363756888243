import { FC } from "react";
import { useSelector } from "react-redux";
import classes from "./SiteNameComponent.module.css";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/UserStore";
import { TextEnum } from "../../SolarGikLib/TextStyles";

interface SiteNameProps {
  siteId: string;
}

const SiteNameComponent: FC<SiteNameProps> = ({ siteId }) => {
  const siteDisplayName = useSelector(
    (state: RootState) => state.multiSitesMetadata.sites[siteId]?.visualInfo.displayName
  );
  const isEngineer = useSelector((state: RootState) => state.user.userType >= UserAccessType.Engineer);
  if (!shouldDisplayFormatted(siteDisplayName, isEngineer)) {
    return <span>{siteDisplayName || siteId}</span>;
  }
  return (
    <div>
      <div className={`${TextEnum.h7} ${classes["soround-with-parenthesis"]}`}>{siteId}</div>
      <div>{siteDisplayName}</div>
    </div>
  );
};

export type SiteNameFormatterFunc = (siteId: string) => string;

export function useSiteNamesFormatter(): SiteNameFormatterFunc {
  const multiSitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata);
  const isEngineer = useSelector((state: RootState) => state.user.userType >= UserAccessType.Engineer);
  return (siteId: string) => {
    if (!siteId) {
      return "";
    }
    const siteDisplayName = multiSitesMetadata.sites[siteId]?.visualInfo.displayName;
    if (shouldDisplayFormatted(siteDisplayName, isEngineer)) {
      return `${siteId} (${siteDisplayName})`;
    }
    return siteDisplayName || siteId;
  };
}

function shouldDisplayFormatted(siteDisplayName?: string, isEngineer?: boolean) {
  return isEngineer && siteDisplayName;
}

export default SiteNameComponent;
