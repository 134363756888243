import axios, { AxiosResponse } from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import {
  CloseFaucetCommandResultCode,
  IFaucetCommandResponse,
  IFaucetCommandResultsMap,
  OpenFaucetCommandResultCode,
} from "./sprinklersModels";

export async function uploadNewSprinklersPlanAsync(siteId: string, fileData: Uint8Array) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/SprinklerPlan/${siteId}`;
  const params = { file: fileData };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await axios.post<AxiosResponse>(url, params, headers);
  return res;
}

export async function deleteSprinklersPlanAsync(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/SprinklerPlan/${siteId}`;
  const res = await axios.delete<void>(url);
  return res.status == 204;
}

export async function getPlanAsFileAsync(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/SprinklerPlan/${siteId}`;
  const res = await axios.get<string>(url);
  return res.data;
}

export async function getSprinklersPlanTemplateAsync(templateVersionNumber: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/SprinklerPlan/GetTemplateFile/${templateVersionNumber}`;
  const res = await axios.get<string>(url);
  return res.data;
}

export async function getIsPlanExist(siteId: string) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/SprinklerPlan/${siteId}/exists`;
  const res = await axios.get<boolean>(url);
  return res.data;
}

export async function openFaucetsAsync(
  siteId: string,
  durationMs: number,
  faucetsIds: number[]
): Promise<IFaucetCommandResultsMap> {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/Faucets/open/${siteId}`;
  const params = { DurationMs: durationMs, FaucetsIds: faucetsIds };
  try {
    const httpResponse = await axios.post<IFaucetCommandResponse>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(faucetsIds.map((id) => [id, OpenFaucetCommandResultCode.UnexpectedError]));
  }
}

export async function closeFaucetsAsync(siteId: string, faucetsIds: number[]) {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/sprinklers/Faucets/close/${siteId}`;
  try {
    const httpResponse = await axios.post<IFaucetCommandResponse>(url, faucetsIds);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      faucetsIds.map((id) => [id, CloseFaucetCommandResultCode.UnexpectedError])
    );
  }
}
