import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISiteConfigurationPageState {
  activeTab: string;
}

const initialState: ISiteConfigurationPageState = {
  activeTab: "mcsConfig",
};

const siteConfigurationPageSlice = createSlice({
  name: "siteConfigurationPage",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = siteConfigurationPageSlice.actions;
export const siteConfigurationPageReducer = siteConfigurationPageSlice.reducer;
