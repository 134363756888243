import React from "react";
import { SgBadge } from "../../../SolarGikLib/badge/SgBadge";
import classes from "./TrackerBadges.module.css";
import LightTooltip from "../../../SolarGikLib/tooltip/LightTooltip";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { TrackerNameById } from "../TrackerName";

interface TrackerBadgesProps {
  trackerIds: number[];
  maxDisplay?: number;
}

export const TrackerBadges: React.FC<TrackerBadgesProps> = ({ trackerIds, maxDisplay = 3 }) => {
  if (trackerIds.length <= maxDisplay) {
    return (
      <div className={classes["badge-container"]}>
        {trackerIds.map((id) => (
          <SgBadge key={id} className={classes["badge-text"]}>
            <TrackerNameById id={id} />
          </SgBadge>
        ))}
      </div>
    );
  }

  const tooltipContent = (
    <div className={classes["tooltip-content"]}>
      <div className={`${classes["tooltip-header"]} ${TextEnum.h6}`}>{trackerIds.length} Trackers</div>
      <div className={classes["tooltip-badges"]}>
        {trackerIds.map((id) => (
          <SgBadge key={id} className={classes["badge-text"]}>
            <TrackerNameById id={id} />
          </SgBadge>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classes["badge-container"]}>
      <LightTooltip title={tooltipContent} placement="right">
        <div>
          <SgBadge className={`${classes["badge-text-summary"]} ${TextEnum.h6}`}>
            {trackerIds.length} Trackers
          </SgBadge>
        </div>
      </LightTooltip>
    </div>
  );
};
