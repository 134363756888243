import { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Sprinklers.module.css";
import SprinklersCommands from "../features/sprinklers/SprinklersCommands";
import Card from "../SolarGikLib/cards/Card";
import SprinklerControl from "../features/sprinklers/SprinklerControl";
import { AlertMessage } from "../SolarGikLib/alerts/AlertModels";
import SolarGikAlert from "../SolarGikLib/alerts/Alert";
import { RootState } from "../features/app/Store";

const Sprinklers = () => {
  const siteMetadata = useSelector((state: RootState) => state.site);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  return (
    <Card>
      <div className={classes.wrapper}>
        <SprinklersCommands siteId={siteMetadata.siteId} />
        <div className={classes["sprinklers-control"]}>
          {siteMetadata.sprinklersIds.map((id) => (
            <SprinklerControl
              key={id}
              sprinklerId={id}
              siteId={siteMetadata.siteId}
              setAlertMessage={setAlertMessage}
            />
          ))}
        </div>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      </div>
    </Card>
  );
};
export default Sprinklers;
