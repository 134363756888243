import { parse } from "@messageformat/parser";

export type FaultDescriptionToken =
  | "RawText"
  | "ComponentNumber"
  | "ScreeningTimeMinutes"
  | "AdditionalData_Minutes"
  | "AdditionalData_Percent"
  | "AdditionalData_SafeSource"
  | "Percent"
  | "ValidPercentage"
  | "Total";

export type TokenTransformer<T> = (index: number, rawText: string) => T;

export type TokenTransformersMap<T> = {
  [token in FaultDescriptionToken]?: TokenTransformer<T>;
} & { RawText: TokenTransformer<T> };

export type ValueTransformer<T> = (
  index: number,
  token: FaultDescriptionToken,
  value: string | number | undefined
) => T;

export function transformDescription<T>(
  rawDescription: string,
  transformers: TokenTransformersMap<T>
): T[] {
  // the parser doesn't like the colon in the AdditionalData field, so we replace it with an underscore:
  const parserNormalizedDescription = rawDescription.replace("AdditionalData:", "AdditionalData_");
  const descriptionTokens = parse(parserNormalizedDescription);
  return descriptionTokens.map((token, index) => {
    if (token.type === "argument") {
      const transformer = transformers[token.arg as FaultDescriptionToken];
      if (transformer) {
        return transformer(index, token.ctx.text);
      }
    }
    return transformers.RawText(index, token.ctx.text);
  });
}
