import { FC } from "react";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./AgriCommands.module.css";

import { deleteAgriPlanAsync, uploadNewPlanAsync } from "./AgriApi";
import DownloadPlan from "./agriCommands/DownloadPlans";
import { IButtonVariant } from "../../../common/Mui/StyledButton";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { UserAccessType } from "../../user/UserStore";
import Delete from "../crudCommands/Delete";
import Upload from "../crudCommands/Upload";
import { RootState } from "../Store";
import { UnknownError } from "../../../common/UnknownError";

interface AgriActionsProps {
  siteId: string;
  getTodaysPlan: () => Promise<void>;
  handleDeleted: () => void;
  isPlanExist: boolean;
  alertMessage?: AlertMessage;
  setAlertMessage: (messageObj?: AlertMessage) => void;
  handleUploadFailed?: (error: UnknownError) => void;
}

const buttonVariant: IButtonVariant = { variant: "outlined" };
const AgriActions: FC<AgriActionsProps> = ({
  siteId,
  getTodaysPlan,
  handleDeleted,
  isPlanExist,
  alertMessage,
  setAlertMessage,
  handleUploadFailed,
}) => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const isDisabled = userType < UserAccessType.Operator;
  return (
    <div style={{ width: "100%" }}>
      <div className={classes.commands}>
        <Upload
          setErrorMessageFunc={setAlertMessage}
          postUploadFunc={getTodaysPlan}
          siteId={siteId}
          uploadApiFuncAsync={uploadNewPlanAsync}
          buttonVariant={buttonVariant}
          isDisabled={isDisabled}
          isTextLessButton={true}
          onFailFunc={handleUploadFailed}
        />

        {isPlanExist && (
          <Delete
            setAlertMessage={setAlertMessage}
            siteId={siteId}
            postDeleteFunc={handleDeleted}
            deleteApi={deleteAgriPlanAsync}
            buttonVariant={buttonVariant}
            buttonText="Delete Plan"
            isDisabled={isDisabled}
            isTextLessButton={true}
          />
        )}

        <DownloadPlan
          setErrorFunc={setAlertMessage}
          siteId={siteId}
          buttonVariant={buttonVariant}
          isPlanExist={isPlanExist}
        />

        <Box style={{ flex: 0.3 }} />
      </div>
      <div className={classes["alert-container"]}>
        <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} isCloseIconVisible={false} />
      </div>
    </div>
  );
};
export default AgriActions;
